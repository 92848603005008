import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, HomeService} from '../_services';
//import AOS from 'aos';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit , AfterViewInit{
  registerForm: FormGroup;
    loading = false;
    submitted = false;
    $country: any;
    $state: any;
$states: any;
$stateId: any;
   constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private home: HomeService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {

        this.home.getCountry().subscribe(data=>{
            this.$country = data.data.country;
            //console.log(this.$country)
        })

        this.home.getState('').subscribe(data=>{
            this.$state = data.data.state;
            //console.log(this.$state)
        })

        this.registerForm = this.formBuilder.group({
            name: ['', [Validators.required,Validators.maxLength(50)]],
            email: ['', [Validators.required,Validators.email]],
            password: ['', Validators.required, Validators.maxLength(8)],
            cpassword: ['', Validators.required, Validators.maxLength(8)],
            phone: ['', Validators.required, Validators.maxLength(15), Validators.pattern(/^[0-9]+$/)],
        	company: ['', Validators.required, Validators.maxLength(200)],
        	address1: ['', Validators.required, Validators.maxLength(500)],
        	address2: ['', Validators.required, Validators.maxLength(500)],
        	country: ['', Validators.required],
        	state: ['', Validators.required],
        	city: ['', Validators.required],
            phoneNumber: [''],
        	refrral: ['']
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }
    onSubmit() {
       var phoneNumber = $("#phone").intlTelInput("getSelectedCountryData").dialCode
     
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            var that = this;
              that.toastr.error('Please enter all fields')
            return;
        }
          document.body.classList.add('jw-modal-open');
        this.loading = true;

        this.authenticationService.register(
            this.f.name.value,
            this.f.email.value,
            this.f.password.value,
            this.f.cpassword.value,
            phoneNumber,
        this.f.phone.value,
        this.f.company.value,
         this.f.address1.value,
          this.f.address2.value,
          this.f.country.value,
          this.f.state.value,
          this.f.city.value,
        this.f.refrral.value)        
            .subscribe(
                data => {
var that = this;
                    if (data.status.code == 0) {
                            that.submitted = false;
                            document.body.classList.remove('jw-modal-open');
                            that.loading = false;
                            that.toastr.success(data.status.message);
                          //  location.href = this.returnUrl;

                        } else {
                            document.body.classList.remove('jw-modal-open');
                            that.loading = false;
                            that.toastr.error(data.status.message)
                        }
                      },
                error => {

                    this.toastr.error(error)
                    document.body.classList.remove('jw-modal-open');
                    this.loading = false;
                });

    }


    handleCountry(val){
   /* this.stateid=val;
    //console.log(val)
    this.homeService.getCities(val).subscribe(data=>{
      this.cities = data.data.cities.cities;
      })*/

      //console.log(val)
      this.home.getState(val).subscribe(data=>{
          /* this.$stateId = data.data.state.countryId;*/
            this.$states = data.data.state.states;
            //console.log(this.$states)
        })
       //console.log(val)
      this.home.getCountry().subscribe(data=>{
            this.$country = data.data.country;
            //console.log(this.$state)
        })
    }



    ngAfterViewInit(){
    	 $('.menuH ul li a').click(function() {
            $('html, body').animate({
                scrollTop: $(this.hash).offset().top
            }, 1000)
        });
setTimeout(()=>{
         $("#phone").intlTelInput({
            autoPlaceholder: false,
            geoIpLookup: function(callback) {
                //console.log(callback)
                var countryCode = "in";
                callback(countryCode);
            },

            initialCountry: "auto",
            separateDialCode: true,

        });
     },200);
    }
}
