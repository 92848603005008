<site-header></site-header>

<div class="page-container">


<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class=" pull-left">
                    <div class="page-title">Add Rate Type Details</div>
                </div>
                <ol class="breadcrumb page-breadcrumb pull-right">
                    <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item"
                            href="/home">Home</a>&nbsp;<i class="fa fa-angle-right"></i>
                    </li>
                    <li>&nbsp;<a class="parent-item"
                            >Rate</a>&nbsp;<i class="fa fa-angle-right"></i>
                    </li>
                    <li class="active">Add Rate Type Details</li>
                </ol>
            </div>
        </div>
    
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <div class="card  card-box">
                    <div class="card-head">
                        <header>Add Rate Type</header>
                        <div class="tools">
                            <!-- <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                            <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                            <a class="t-close btn-color fa fa-times" href="javascript:;"></a> -->
                        </div>
                    </div>
                    <form class="flt " role="form" [formGroup]="RateForm" #myForm="ngForm"
						(ngSubmit)="onSubmit(RateForm, myForm)">
                    <div class="card-body row">
                            <div class="col-lg-6 p-t-20">
                                <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input" type="text" formControlName="name" id="txtRateType" autocomplete="off" [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }">
                                    <label class="mdl-textfield__label">Rate Type<span style="color:red">*</span></label>
                                </div>
                                <div class="invalid">
                                    <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f.name.errors?.required">Rate type is required</div>
                                </div>
                                <div *ngIf="(f.name.touched || submitted) && f.name.errors?.maxlength"
                                    class="invalid-feedback">
                                    <div *ngIf="f.name.errors?.maxlength">Maximum 20 characters are allowed
                                    </div>
                                </div>
                                <div *ngIf="(f.name.touched || submitted) && f.name.errors?.pattern"
                                    class="invalid-feedback">
                                    <div *ngIf="f.name.errors?.pattern">Empty space is not allowed
                                    </div>
                                </div>
                                </div>
                            </div>
                        
                            
                                
                        </div>	
                        <!-- <div class="col-lg-12 p-t-20 text-center submit_cls">
                                <button type="submit"
                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-info">Submit</button>
                                <button type="button" (click)="cancel()"
                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">Cancel</button>
                            </div> -->
                            <div class="col-lg-12 p-t-20 text-center submit_cls">
                                <button type="submit" 
                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-info">
                                    <div *ngIf="cancelButton;then update else add">here is ignored</div>
                                    <ng-template #add>Submit</ng-template>
                                    <ng-template #update>Update</ng-template>
                                </button>
    
                                    
                                <button type="button" *ngIf="cancelButton" (click)="cancelSubmit($event)"
                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">Cancel</button>
                            </div>
                            </form>

                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="card  card-box">
                    <div class="card-head">
                        <header>All Rate Type</header>
                        <div class="tools">
                            <!-- <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a> -->
                            <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                            <!-- <a class="t-close btn-color fa fa-times" href="javascript:;"></a> -->
                        </div>
                    </div>
                    <div class="card-body tb-wrapper ">
                        <div class="table-wrap">
                            <div class="table-responsive tblHeightSet ">
                                <table class="table display product-overview mb-30" id="support_table5" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Rate Type</th>
                                        
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let user of rate$; let i = index">
                                            <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                            <td *ngIf="user.name;else  name" > {{ user.name}}</td>
                                            <ng-template #name>
                                                <td>-</td>
                                            </ng-template>
                                         
                                            <td>
                                                <a (click)="userEdit($event, user, i)" href="javascript:void(0);" class="btn btn-tbl-edit btn-xs">
                                                    <i class="fa fa-pencil"></i>
                                                </a>
                                                <button class="btn btn-tbl-delete btn-xs" (click)="userDelete($event, user, i)" >

                                                    <i class="fa fa-trash-o "></i>
                                                </button>
                                            </td>
                                        </tr>

                                        

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>


 
</div>

<div class="page-container"></div>





<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>
