import { Component, OnInit,ViewChild,AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray,FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {FloorService} from '../_services/floor.service';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var checked:any;

@Component({
  selector: 'app-add-room-type',
  templateUrl: './add-room-type.component.html',
  styleUrls: ['./add-room-type.component.css']
})
export class AddRoomTypeComponent implements OnInit {
  RoomForm: FormGroup;
	loading = false;
  submitted = false;
  radioCheck=null;
  rate$:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  curPage = 1;
  perPage = 10;
  status$ =true;
  cancelButton = false;
  rate_type=null;
  RoomType$:any;
  stateConfig = {

    displayKey: 'name', // if objects array passed which key to be displayed defaults to description
    search: false, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Rate type ', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No Rate type found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search State', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  constructor( private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private FloorService:FloorService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
  	this.RoomForm = this.formBuilder.group({
      index: null,
      _id: null,
  		room_type: ['',[Validators.required,Validators.maxLength(50),Validators.pattern(nonWhitespaceRegExp)]],
      price: ['',[Validators.required,Validators.maxLength(30),Validators.pattern(nonWhitespaceRegExp)]],
      rate_type: ['',[Validators.required]]
    })

    this.FloorService.getRate().subscribe(data => {
      //console.log(data);
      this.rate$=data.data.rateTypes;
      // this.dtTrigger.next();
     
     
  });

  this.FloorService.getRoomType().subscribe(data => {
    //console.log(data);
    this.RoomType$=data.data.roomTypes;
    this.dtTrigger.next();
   
   
});
  }

  get f() { return this.RoomForm.controls; }
 

  onSubmit(form, formWrap) {
    
 
   
    this.submitted = true

  	let id = form.getRawValue()._id;
    let index = form.getRawValue().index;
    const that = this;
    //console.log(this.RoomForm)
  
    if(this.RoomForm.invalid ){
      this.toastr.error('Please Enter All Fields');
      return false;
    }   

     
    if (id != null) {
      alertify.confirm('Update Room type','Are you sure want to update this record', function () {     
        // document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
        that.loading = true;
    
         that.FloorService.updateRoomType(
             id,
             that.f.room_type.value,
             that.f.price.value,
             that.f.rate_type.value,
            
           
            )
          .subscribe(
            data => {
    
              if (data.status.code == 0) {
               
                that.toastr.success('Room updated successfully');
              
                that.submitted = false;
                document.body.classList.remove('jw-modal-open');
          
    
              
                that.loading = false;
                that.cancelButton = false;
                document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');

                that.RoomForm.setValue({
                  index: null,
                  _id: null,
                  room_type:'',
                  price:'',
                 rate_type:'',
             
                })
               
                formWrap.reset();
                $("#support_table5").dataTable().fnDestroy();
                that.FloorService.getRoomType().subscribe(data => {
                  //console.log(data);
                  that.RoomType$=data.data.roomTypes;
                  that.dtTrigger.next();
                 
                 
              });
           
           
              } else {
                // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                // document.body.classList.remove('jw-modal-open');
                that.loading = false;
               that.toastr.error(data.status.message)
               
              }
            },
            error => {
              that.toastr.error(error)
              
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
            });
          }, function () { //alertify.error('Delete action cancelled')
          that.toastr.error('Create action cancelled')
        });
      
    } else {
      alertify.confirm('Create Room type','Are you sure want to create this record', function () {     
        // document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
        that.loading = true;
    
         that.FloorService.saveRoomType(
            that.f.room_type.value,
            that.f.price.value,
            that.f.rate_type.value,
          
            
           
            )
          .subscribe(
            data => {
    
              if (data.status.code == 0) {
               
                that.toastr.success('Room type added successfully');
              
                that.submitted = false;
                document.body.classList.remove('jw-modal-open');
          
    
              
                that.loading = false;
                // location.reload();
             
                that.RoomForm.setValue({
                  index: null,
                  _id: null,
                  room_type:'',
                  price:'',
                 rate_type:'',
             
                })
               
                formWrap.reset();
                $("#support_table5").dataTable().fnDestroy();
                that.FloorService.getRoomType().subscribe(data => {
                  //console.log(data);
                  that.RoomType$=data.data.roomTypes;
                  that.dtTrigger.next();
                 
                 
              });
           
           
              } else {
                // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                // document.body.classList.remove('jw-modal-open');
                that.loading = false;
               that.toastr.error(data.status.message)
               
              }
            },
            error => {
              that.toastr.error(error)
              
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
            });
          }, function () { //alertify.error('Delete action cancelled')
          that.toastr.error('Create action cancelled')
        });
 
 	 
  }
  }

  userEdit(event, user, i) {
    window.scroll(0, 0);
    //console.log(user.status)
   
    $('.mdl-textfield').addClass('is-dirty');

    this.status$= true
    this.cancelButton = true;
    //this.table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
    this.RoomForm.setValue({
      index: i,
      _id: user._id,
      room_type: user.room_type,
     
     price: user.price,
     rate_type:user.rate_type
    })

   
   
    // this.imageExist =user.image;
    // this.imageExist1=user.cover;
  }

  userDelete(event, user, i) {
    var that = this;
    alertify.confirm('Delete Room Type','Are you sure want to delete this record', function () {
      that.FloorService.DeleteRoomtype(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success('Deleted Successfully')
              // alertify.success('Deleted Successfully')
              setTimeout(function () {
                $("#support_table5").dataTable().fnDestroy();
                that.FloorService.getRoomType().subscribe(data => {
                  //console.log(data);
                  that.RoomType$=data.data.roomTypes;
                  that.dtTrigger.next();
                 
                 
              });
              }, 1000);
              //that.users$.splice(i, 1);
            } else {
              that.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            that.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
          });
    }
      , function () { //alertify.error('Delete action cancelled')
       // that.toastr.error('Delete action cancelled')

      });

  }

  cancelSubmit(event) {
    $('.mdl-textfield').removeClass('is-focused');
    $('.mdl-textfield').removeClass('is-dirty');
    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.RoomForm.setValue({
      index: null,
      _id: null,
      room_type:'',
      price:'',
      rate_type:''
    })

    this.submitted = false;
  }
  ngAfterViewInit(){
    $(document).on('focus','input,select,textarea',function(e){ 
      var cur = $(this);
      $('input,select,textarea').parent().removeClass('is-focused');
      cur.parent()[0].classList.remove('is-focused');
      
      // cur.parent()[0].removeClass('is-focused')
      
        cur.parent()[0].classList.add('is-focused')
       })
      }
}
