import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray,FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {FloorService} from '../_services/floor.service';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';


declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var checked:any;
@Component({
  selector: 'app-add-rate-type',
  templateUrl: './add-rate-type.component.html',
  styleUrls: ['./add-rate-type.component.css']
})
export class AddRateTypeComponent implements OnInit {
  RateForm: FormGroup;
	loading = false;
  submitted = false;
  radioCheck=null;
  floors$:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  curPage = 1;
  perPage = 10;
  status$ =true;
  cancelButton = false;
  rate$:any;
  constructor(    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private FloorService:FloorService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
  	this.RateForm = this.formBuilder.group({
      index: null,
      _id: null,
  		name: ['',[Validators.required,Validators.maxLength(20),Validators.pattern(nonWhitespaceRegExp)]],
  	
    })
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: [2],
        
      }],
      // order: [[ 2, 'asc' ]]
    };
    this.FloorService.getRate().subscribe(data => {
      //console.log(data);
      this.rate$=data.data.rateTypes;
      this.dtTrigger.next();
     
     
  });
  }

  get f() { return this.RateForm.controls; }
  onSubmit(form, formWrap) {
    
    this.submitted = true

  	let id = form.getRawValue()._id;
    let index = form.getRawValue().index;
    const that = this;
    //console.log(this.RateForm)
  
    if(this.RateForm.invalid ){
      this.toastr.error('Please Enter Rate Type');
      return false;
    }   

    // if(this.radioCheck == null ){
    //   this.toastr.error('Please Select Status Active/Deactive');
    //   return false;
    // }   
    if (id != null) {
      alertify.confirm('Update Rate type','Are you sure want to update this record', function () {     
        // document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
        that.loading = true;
    
         that.FloorService.updateRate(
             id,
             that.f.name.value,
            
            
           
            )
          .subscribe(
            data => {
    
              if (data.status.code == 0) {
               
                that.toastr.success('Rate updated successfully');
                that.cancelButton = false;
                that.submitted = false;
                document.body.classList.remove('jw-modal-open');
                document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable')
                $('.mdl-textfield').removeClass('is-focused');
                $('.mdl-textfield').removeClass('is-dirty');
              
                that.loading = false;
                that.RateForm.setValue({
                  index: null,
                  _id: null,
                  name:'',
             
                })
               
                formWrap.reset();
                setTimeout(function () {
                  $("#support_table5").dataTable().fnDestroy();
                  that.FloorService.getRate().subscribe(data => {
                    //console.log(data);
                    that.rate$=data.data.rateTypes;
                    that.dtTrigger.next();
                   
                   
                });
                }, 1000);
           
              } else {
                // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                // document.body.classList.remove('jw-modal-open');
                that.loading = false;
               that.toastr.error(data.status.message)
               
              }
            },
            error => {
              that.toastr.error(error)
              
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
            });
          }, function () { //alertify.error('Delete action cancelled')
          that.toastr.error('Create action cancelled')
        });
      
    } else {
 
 	 alertify.confirm('Create Rate Type','Are you sure want to create this record', function () {     
    // document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
    that.loading = true;

     that.FloorService.saveRate(
        that.f.name.value,
      
      	
       
        )
      .subscribe(
        data => {

          if (data.status.code == 0) {
           
            that.toastr.success('Rate type added successfully');
          
            that.submitted = false;
            document.body.classList.remove('jw-modal-open');
      
            $('.mdl-textfield').removeClass('is-focused');
            $('.mdl-textfield').removeClass('is-dirty');
          
            that.loading = false;
           
         
            that.RateForm.setValue({
              index: null,
              _id: null,
              name:'',
         
            })
           
            formWrap.reset();
            setTimeout(function () {
              $("#support_table5").dataTable().fnDestroy();
              that.FloorService.getRate().subscribe(data => {
                //console.log(data);
                that.rate$=data.data.rateTypes;
                that.dtTrigger.next();
               
               
            });
            }, 1000);
       
          } else {
            // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
            // document.body.classList.remove('jw-modal-open');
            that.loading = false;
           that.toastr.error(data.status.message)
           
          }
        },
        error => {
          that.toastr.error(error)
          
          document.body.classList.remove('jw-modal-open');
          that.loading = false;
        });
      }, function () { //alertify.error('Delete action cancelled')
      that.toastr.error('Create action cancelled')
    });
  }
  
  }
cancel(){
  this.router.navigateByUrl("/");
}
userEdit(event, user, i) {
  window.scroll(0, 0);
  
  $("input:text:visible:first").focus();

  $('.mdl-textfield').addClass(' is-dirty');

  

  this.status$= true
  this.cancelButton = true;
  //this.table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
  document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
  this.RateForm.patchValue({
    index: i,
    _id: user._id,
    name:user.name,
  })
 
 
  // this.imageExist =user.image;
  // this.imageExist1=user.cover;
}

userDelete(event, user, i) {
  var that = this;
  alertify.confirm('Delete Rate','Are you sure want to delete this record', function () {
    that.FloorService.DeleteRate(user._id)
      .subscribe(
        data => {
          if (data.status.code == 0) {
            that.toastr.success('Deleted Successfully')
            // alertify.success('Deleted Successfully')
            setTimeout(function () {
              $("#support_table5").dataTable().fnDestroy();
              that.FloorService.getRate().subscribe(data => {
                //console.log(data);
                that.rate$=data.data.rateTypes;
                that.dtTrigger.next();
               
               
            });
            }, 1000);
            //that.users$.splice(i, 1);
          } else {
            that.toastr.error(data.status.message)
            // alertify.error(data.status.message)
            //this.alertService.error(data.status.message);
          }
        },
        error => {
          that.toastr.error(error)
          // alertify.error(error)
          //this.alertService.error(error);
        });
  }
    , function () { //alertify.error('Delete action cancelled')
     // that.toastr.error('Delete action cancelled')

    });

}

cancelSubmit(event) {
  $('.mdl-textfield').removeClass('is-focused');
  $('.mdl-textfield').removeClass('is-dirty');
  this.cancelButton = false;
  document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
  this.RateForm.setValue({
    index: null,
    _id: null,
    name:'',
  })

  this.submitted = false;
}
ngAfterViewInit(){
  $(document).on('focus','input,select,textarea',function(e){ 
    var cur = $(this);
    $('input,select,textarea').parent().removeClass('is-focused');
    cur.parent()[0].classList.remove('is-focused');
    
    // cur.parent()[0].removeClass('is-focused')
    
      cur.parent()[0].classList.add('is-focused')
     })
    }
}
