<site-header></site-header>
<div class="page-container">
   <div class="page-content-wrapper">
      <div class="page-content">
         <div class="page-bar">
            <div class="page-title-breadcrumb">
               <div class="card-head">
                  <div class="page-title">Reservation</div>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-sm-12">
               <div class="card-box">
                  <div class="card-body">
                     <div class="maincls">
                        <form [formGroup]="reservationForm" role="form" #myForm="ngForm"
                           (ngSubmit)="onSubmit(reservationForm,myForm)">
                           <div class="row">

                              <div class="page_cls">User Details</div>

                           </div>
                           <div class="row">
                              <div class="col-lg-3 p-t-10 ml-2">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input" type="date" id="txtRoomNo"
                                       name="reservationdate" formControlName="reservationdate"
                                       [ngClass]="{ 'is-invalid': (f.reservationdate.touched || submitted) && f.reservationdate.errors }"
                                       placeholder="Reservationd Date" autocomplete="off" >
                                    <label class="mdl-textfield__label">Reservation Date</label>
                                 </div>
                                 <div class="invalid">
                                    <div
                                       *ngIf="(f.reservationdate.touched || submitted) && f.reservationdate.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.reservationdate.errors?.required">Reservation Date is required
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-lg-3 p-t-10 ml-5">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input" type="text" id="txtRoomNo" name="phone"
                                       formControlName="phone"
                                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                       maxlength="10" (change)="valuechange($event)"
                                       [ngClass]="{ 'is-invalid': (f.phone.touched || submitted) && f.phone.errors }"
                                       placeholder="Phone" autocomplete="off" [readonly]="isReadonly">
                                    <label class="mdl-textfield__label">Phone <span style="color:red">*</span></label>
                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.phone.touched || submitted) && f.phone.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.phone.errors?.required">Phone is required</div>
                                    </div>
                                    <div *ngIf="(f.phone.touched || submitted) && f.phone.errors?.pattern"
                                       class="invalid-feedback">
                                       <div *ngIf="f.phone.errors?.pattern">Invalid number</div>
                                    </div>
                                    <div *ngIf="(f.phone.touched || submitted) && f.phone.errors?.maxlength"
                                       class="invalid-feedback">
                                       <div *ngIf="f.phone.errors?.maxlength">Maximum 10 digits are allowed</div>
                                    </div>

                                    <div *ngIf="(f.phone.touched || submitted) && f.phone.errors?.minlength"
                                       class="invalid-feedback">
                                       <div *ngIf="f.phone.errors?.minlength">Minimum 10 digits required</div>
                                    </div>
                                 </div>

                              </div>
                              <div class="col-lg-2 p-t-10 ml-5">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input" type="text" id="txtRoomNo" name="name"
                                       formControlName="name"
                                       [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }"
                                       placeholder="Name" autocomplete="off">
                                    <label class="mdl-textfield__label">Name <span style="color:red">*</span></label>

                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.name.errors?.required">Name is required</div>
                                    </div>
                                    <div *ngIf="(f.name.touched || submitted) && f.name.errors?.pattern"
                                       class="invalid-feedback">
                                       <div *ngIf="f.name.errors?.pattern">Enter only letters</div>
                                    </div>
                                    <div *ngIf="(f.name.touched || submitted) && f.name.errors?.maxlength"
                                       class="invalid-feedback">
                                       <div *ngIf="f.name.errors?.maxlength">Maximum 50 digits are allowed</div>
                                    </div>
                                 </div>

                              </div>
                              <div class="col-lg-2 p-t-10 ml-5 gender_cls">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <select class="mdl-textfield__input" id="txtRoomNo" name="gender"
                                       formControlName="gender"
                                       [ngClass]="{ 'is-invalid': (f.gender.touched || submitted) && f.gender.errors }"
                                       placeholder="Gender" autocomplete="off">
                                       <option value="male">Male</option>
                                       <option value="female">Female</option>
                                       <option value="other">Other</option>
                                    </select>
                                    <label class="mdl-textfield__label">Gender</label>
                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.gender.touched || submitted) && f.gender.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.gender.errors?.required">Gender is required</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-lg-3 p-t-10 ml-2">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input" type="date" id="ardate" name="ardate"
                                       formControlName="ardate"
                                       [ngClass]="{ 'is-invalid': (f.ardate.touched || submitted) && f.ardate.errors }"
                                       placeholder="Arival Date" autocomplete="off" (change)="datechange($event)">
                                    <label class="mdl-textfield__label">Arrival Date <span style="color:red">*</span></label>
                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.ardate.touched || submitted) && f.ardate.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.ardate.errors?.required">Arrival date is required</div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-lg-2 p-t-10 ml-5">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <!-- <input class="mdl-textfield__input" type="time" id="txtRoomNo" name="artime" formControlName="artime" [ngClass]="{ 'is-invalid': (f.artime.touched || submitted) && f.artime.errors }" placeholder="Arival Time" autocomplete="off"> -->

                                    <input class="mdl-textfield__input" id="txtRoomNo" [ngxTimepicker]="picker1"
                                       formControlName="artime" name="artime"
                                       [ngClass]="{ 'is-invalid': (f.artime.touched || submitted) && f.artime.errors }">
                                    <ngx-material-timepicker #picker1 (timeChanged)="setSelectedTime1($event)">
                                       <!-- [ngClass]="{'is-invalid':(f.start_time.touched || submitted) && f.start_time.errors?.required}" -->
                                    </ngx-material-timepicker>
                                    <label class="mdl-textfield__label">Arrival Time <span style="color:red">*</span></label>
                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.artime.touched || submitted) && f.artime.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.artime.errors?.required">Arrival time is required</div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-lg-3 p-t-10 ml-5">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input" type="date" id="drdate" name="drdate"
                                       formControlName="drdate"
                                       [ngClass]="{ 'is-invalid': (f.drdate.touched || submitted) && f.drdate.errors }"
                                       placeholder="Departure Date" autocomplete="off">
                                    <label class="mdl-textfield__label">Departure Date <span style="color:red">*</span></label>
                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.drdate.touched || submitted) && f.drdate.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.drdate.errors?.required">Departure date is required</div>
                                    </div>
                                 </div>
                              </div>

                              <div class="col-lg-2 p-t-10 ml-5">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <!--  <input class="mdl-textfield__input" type="time" id="txtRoomNo" name="drtime" formControlName="drtime" [ngClass]="{ 'is-invalid': (f.drtime.touched || submitted) && f.drtime.errors }" placeholder="Departure Time" autocomplete="off"> -->
                                    <input class="mdl-textfield__input" id="txtRoomNo" [ngxTimepicker]="picker2"
                                       formControlName="drtime" name="drtime"
                                       [ngClass]="{ 'is-invalid': (f.drtime.touched || submitted) && f.drtime.errors }">
                                    <ngx-material-timepicker #picker2 (timeChanged)="setSelectedTime($event)">
                                       <!-- [ngClass]="{'is-invalid':(f.start_time.touched || submitted) && f.start_time.errors?.required}" -->
                                    </ngx-material-timepicker>
                                    <label class="mdl-textfield__label">Departure Time <span style="color:red">*</span></label>
                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.drtime.touched || submitted) && f.drtime.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.drtime.errors?.required">Departure time is required</div>
                                    </div>
                                 </div>
                              </div>


                           </div>
                           <!-- address start -->
                           <div class="row">


                              <div class="col-lg-4 p-t-2 ml-2">

                                 <div class="mdl-textfield mdl-js-textfield txt-full-width">
                                    <textarea class="mdl-textfield__input" rows="1" value="" id="education"
                                       name="address" formControlName="address"
                                       [ngClass]="{ 'is-invalid': (f.address.touched || submitted) && f.address.errors }"
                                       placeholder="Address" autocomplete="off"></textarea>
                                    <label class="mdl-textfield__label cls_label" for="text7">
                                       <p class="ptag_label">Address <span style="color:red">*</span></p>
                                    </label>
                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.address.touched || submitted) && f.address.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.address.errors?.required"> Address is required</div>
                                    </div>
                                    <div *ngIf="(f.address.touched || submitted) && f.address.errors?.maxlength"
                                       class="invalid-feedback">
                                       <div *ngIf="f.address.errors?.maxlength">Maximum 500 characters</div>
                                    </div>
                                 </div>

                              </div>

                              <div class="col-lg-2 p-t-2 ml-5">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input" type="text" value="Bangalore" id="txtRoomNo"
                                       name="city" formControlName="city"
                                       [ngClass]="{ 'is-invalid': (f.city.touched || submitted) && f.city.errors }"
                                       placeholder="City" autocomplete="off">
                                    <label class="mdl-textfield__label">city</label>
                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.city.touched || submitted) && f.city.errors?.pattern"
                                       class="invalid-feedback">
                                       <div *ngIf="f.city.errors?.pattern">Enter only letters</div>
                                    </div>
                                    <div *ngIf="(f.city.touched || submitted) && f.city.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.city.errors?.required"> City is required</div>
                                    </div>
                                 </div>

                              </div>
                              <div class="col-lg-2 p-t-2 ml-5">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input" type="text" value="Karnataka" id="txtRoomNo"
                                       name="state" formControlName="state"
                                       [ngClass]="{ 'is-invalid': (f.state.touched || submitted) && f.state.errors }"
                                       placeholder="state" autocomplete="off">
                                    <label class="mdl-textfield__label">state</label>
                                 </div>
                                 <div class="invalid">

                                    <div *ngIf="(f.state.touched || submitted) && f.state.errors?.pattern"
                                       class="invalid-feedback">
                                       <div *ngIf="f.state.errors?.pattern">Enter only letters</div>
                                    </div>
                                    <div *ngIf="(f.state.touched || submitted) && f.state.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.state.errors?.required"> State is required</div>
                                    </div>

                                 </div>
                              </div>
                              <div class="col-lg-2 p-t-2 ml-5">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input" type="text" value="India" id="txtRoomNo"
                                       name="country" formControlName="country"
                                       [ngClass]="{ 'is-invalid': (f.country.touched || submitted) && f.country.errors }"
                                       placeholder="Country" autocomplete="off">
                                    <label class="mdl-textfield__label">country</label>
                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.country.touched || submitted) && f.country.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.country.errors?.required"> Country is required</div>
                                    </div>
                                    <div *ngIf="(f.country.touched || submitted) && f.country.errors?.pattern"
                                       class="invalid-feedback">
                                       <div *ngIf="f.country.errors?.pattern">Enter only letters</div>
                                    </div>
                                 </div>

                              </div>

                           </div>
                           <!-- addres end -->
                           <div class="row">

                              <div class="col-lg-2 p-t-2 ml-2">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input" type="text" value="01" id="txtRoomNo"
                                       name="male" formControlName="male"
                                       [ngClass]="{ 'is-invalid': (f.male.touched || submitted) && f.male.errors }"
                                       placeholder="Male" autocomplete="off"
                                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                    <label class="mdl-textfield__label">Male</label>
                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.male.touched || submitted) && f.male.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.male.errors?.required"> Male count is required</div>
                                    </div>
                                    <div *ngIf="(f.male.touched || submitted) && f.male.errors?.pattern"
                                       class="invalid-feedback">
                                       <div *ngIf="f.male.errors?.pattern">Invalid number</div>
                                    </div>
                                 </div>

                              </div>

                              <div class="col-lg-2 p-t-2 ml-5">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input" type="text" value="0" id="txtRoomNo"
                                       name="female" formControlName="female"
                                       [ngClass]="{ 'is-invalid': (f.female.touched || submitted) && f.female.errors }"
                                       placeholder="Female" autocomplete="off"
                                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                    <label class="mdl-textfield__label">Female</label>
                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.female.touched || submitted) && f.female.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.female.errors?.required"> Female count is required</div>
                                    </div>
                                    <div *ngIf="(f.female.touched || submitted) && f.female.errors?.pattern"
                                       class="invalid-feedback">
                                       <div *ngIf="f.female.errors?.pattern">Invalid number</div>
                                    </div>
                                 </div>

                              </div>

                              <div class="col-lg-2 p-t-2 ml-5">
                                 <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input" type="text" id="txtRoomNo" name="children"
                                       formControlName="children"
                                       [ngClass]="{ 'is-invalid': (f.children.touched || submitted) && f.children.errors }"
                                       placeholder="Children" autocomplete="off"
                                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                    <label class="mdl-textfield__label">Children</label>
                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.children.touched || submitted) && f.children.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.children.errors?.required"> Children count is required</div>
                                    </div>
                                    <div *ngIf="(f.children.touched || submitted) && f.children.errors?.pattern"
                                       class="invalid-feedback">
                                       <div *ngIf="f.children.errors?.pattern">Invalid number</div>
                                    </div>
                                 </div>

                              </div>
                              <div class="col-lg-4 p-t-2 ml-5">
                                 <div class="mdl-textfield mdl-js-textfield txt-full-width">

                                    <textarea class="mdl-textfield__input" rows="1" value=" " id="education"
                                       name="remark" formControlName="remark"
                                       [ngClass]="{ 'is-invalid': (f.remark.touched || submitted) && f.remark.errors }"
                                       placeholder="Remark"></textarea>
                                    <label class="mdl-textfield__label cls_label" for="text7">
                                       <p class="ptag_label">Remark</p>
                                    </label>
                                 </div>
                                 <div class="invalid">
                                    <div *ngIf="(f.remark.touched || submitted) && f.remark.errors?.required"
                                       class="invalid-feedback">
                                       <div *ngIf="f.remark.errors?.required">Remark is required</div>
                                    </div>
                                 </div>

                              </div>
                           </div>
                           <div class="row">

                              <div class="page_cls">Room Details</div>

                           </div>
                           <div class="row" formArrayName="options">

                              <div class="row"
                                 *ngFor="let option of reservationForm.controls.options['controls']; let i=index"
                                 [formGroupName]="i">


                                 <div class="col-lg-2 p-t-10 ml-4">
                                    <div
                                       class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                       <select class="mdl-textfield__input" id="thing" name="thing" name="roomtype"
                                          placeholder="Room Category" autocomplete="off" formControlName="roomtype"
                                          [ngClass]="{ 'is-invalid': (reservationForm.get('options')['controls'][i].get('roomtype').touched || submitted) && reservationForm.get('options')['controls'][i].get('roomtype').errors }"
                                          (change)="roomCat($event,i,reservationForm.get('options')['controls'][i])">
                                          <option value="" selected>please select</option>
                                          <option *ngFor="let rm of RoomType$;let i=index" value="{{rm._id}}">
                                             {{rm.room_type}}</option>

                                       </select>

                                       <label class="mdl-textfield__label">Room Category <span style="color:red">*</span></label>
                                    </div>
                                    <div class="invalid">
                                       <div
                                          *ngIf="(reservationForm.get('options')['controls'][i].get('roomtype').touched || submitted) && reservationForm.get('options')['controls'][i].get('roomtype').errors?.required"
                                          class="invalid-feedback">
                                          Room Category is required
                                       </div>
                                    </div>

                                 </div>

                                 <div class="col-lg-2 p-t-10 ml-5">
                                    <div
                                       class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">

                                       <select class="mdl-textfield__input" id="thing" name="thing" name="roomnumber"
                                          placeholder="Room Number" autocomplete="off" formControlName="roomnumber"
                                          [ngClass]="{ 'is-invalid': (reservationForm.get('options')['controls'][i].get('roomnumber').touched || submitted) && reservationForm.get('options')['controls'][i].get('roomnumber').errors }">
                                          <option value="" selected>please select</option>
                                          <option *ngFor="let rm of roomDetails;let i=index" value="{{rm._id}}">
                                             {{rm.room_number}}</option>

                                       </select>



                                       <label class="mdl-textfield__label">Room Number <span style="color:red">*</span></label>
                                    </div>
                                    <div class="invalid">

                                       <div
                                          *ngIf="(reservationForm.get('options')['controls'][i].get('roomnumber').touched || submitted) && reservationForm.get('options')['controls'][i].get('roomnumber').errors?.required"
                                          class="invalid-feedback">
                                          Room number is required
                                       </div>
                                    </div>

                                 </div>
                                 <div class="col-lg-2 p-t-10 ml-5">
                                    <div
                                       class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                       <input class="mdl-textfield__input" type="text" id="txtRoomNo" name="total"
                                          placeholder="Total Advance" autocomplete="off" formControlName="total"
                                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                          [ngClass]="{ 'is-invalid': (reservationForm.get('options')['controls'][i].get('total').touched || submitted) && reservationForm.get('options')['controls'][i].get('total').errors }">

                                       <label class="mdl-textfield__label">Total Advance <span style="color:red">*</span></label>
                                    </div>
                                    <div class="invalid">
                                       <div
                                          *ngIf="(reservationForm.get('options')['controls'][i].get('total').touched || submitted) && reservationForm.get('options')['controls'][i].get('total').errors?.required"
                                          class="invalid-feedback">
                                          Total Advance is required
                                       </div>
                                       <div
                                          *ngIf="(reservationForm.get('options')['controls'][i].get('total').touched || submitted) && reservationForm.get('options')['controls'][i].get('total').errors?.pattern"
                                          class="invalid-feedback">
                                          Invalid total advance
                                       </div>
                                    </div>

                                 </div>

                                 <div class="col-lg-2 p-t-10 ml-5">
                                    <div
                                       class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                       <input class="mdl-textfield__input" type="text" id="txtRoomNo" name="roomprice"
                                          formControlName="roomprice" autocomplete="off" readonly="true">

                                       <label class="mdl-textfield__label">Room Price</label>
                                    </div>

                                 </div>

                                 <div class="col-lg-2 p-t-10 ml-5">
                                    <div class="row">
                                       <button type="button"
                                          class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
                                          *ngIf="i!=0" (click)="remove(i)">DELETE</button>

                                    </div>

                                 </div>

                              </div>

                              <div class="col-lg-2 p-t-10 ml-5">

                                 <div class="add" *ngIf="addroom">
                                    <button type="button"
                                       class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-primary"
                                       (click)="add_activity()">ADD ROOM</button>


                                 </div>
                              </div>
                           </div>


                           <div class="row">
                              <div class="col-lg-12 p-t-20 text-center">
                                 <button type="submit"
                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-primary">
                                    <span *ngIf="!id">SUBMIT</span>
                                    <span *ngIf="id">UPDATE</span>
                                 </button>
                                 <button type="submit"
                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
                                    (click)="cancel($event)">CANCEL</button>
                              </div>
                           </div>
                        </form>
                     </div>
                     <div class="hr_data">

                     </div>

                     <div class="row">
                        <div class="search" style="margin-bottom: 10px;">
                           <input type="text" class="form-control seachinput" placeholder="Search Name and Mobile"
                              (keyup)="handlePageseachChange($event)" />
                        </div>
<div class="table-responsive">
                        <table class="table display product-overview mb-30">
                           <thead style="background-color: #167CE4; color: white;">
                              <tr class="mdl-data-table__cell--non-numeric">
                                 <th>Room Category</th>
                                 <th>Room</th>
                                 <th>Name</th>
                                 <th>Phone</th>
                                 <th>Arival Date</th>
                                 <th>Arival Time</th>
                                 <th>Departure Date</th>
                                 <th>Departure Time</th>
                                 <th>No of Adults</th>
                                 <th>No of Childrens</th>
                                 <th>Action</th>
                              </tr>
                           </thead>


                           <tbody *ngIf="$reservation && $reservation.length > 0">
                              <tr *ngFor="let res of $reservation | paginate : {
                                       itemsPerPage: pageSize,
                                       currentPage: page,
                                       totalItems: count
                                     }">

                                 <ng-container *ngFor="let rm_type of res.rooms_category">
                                    <td class="mdl-data-table__cell--non-numeric">{{rm_type.room_type}}</td>
                                 </ng-container>
                                 <td class="td_room"><span
                                       *ngFor="let rm_no of res.rooms; let last = last">{{rm_no.room_number}} <span
                                          *ngIf="!last">,</span> </span></td>
                                 <td>{{res.name}}</td>
                                 <td>{{res.phone}}</td>
                                 <td>{{res.arrival_date | date:'dd/MM/y'}}</td>
                                 <td>{{res.arrival_time}}</td>
                                 <td>{{res.departure_date | date:'dd/MM/y'}}</td>
                                 <td>{{res.departure_time}}</td>

                                 <td>{{res.male + res.female}}</td>
                                 <td>{{res.children?res.children:'-'}}</td>


                                 <td> <a (click)="deleteReservation($event,res)"><span
                                          class="material-icons action_cls">
                                          delete_outline
                                       </span></a>
                                    <a (click)="editReservation($event,res)"><span class="material-icons action_cls">
                                          mode_edit
                                       </span></a>
                                 </td>

                              </tr>


                           </tbody>
                        </table>
                        <div class="no_data" *ngIf="$reservation && $reservation.length== 0">
                           No Data
                       </div>
                     </div>
                     
                        <div *ngIf="$reservation && $reservation.length > 0">
                           <p>Showing {{pagingCounter}} to {{page*10}} of {{count}} entries</p>
                        </div>

                        <pagination-controls *ngIf="$reservation && $reservation.length > 0" previousLabel="Prev"
                           nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)">
                        </pagination-controls>
                        <div>

                        </div>


                     </div>
                     <!-- main class end -->
                  </div>
                  <!-- card end -->
               </div>


            </div>
            <!-- card end -->
         </div>
      </div>
   </div>
</div>

<site-footer></site-footer>