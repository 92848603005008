import { Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { AlertService, ModalService, RoomService } from '../_services';
import {HomeService} from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { DatePipe, formatDate, getLocaleDateTimeFormat } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2'; 
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ChartsModule } from 'ng2-charts';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  view: any[] = [700, 400];

  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showXAxisLabel = true;
  showYAxisLabel = true;
  colorScheme:any;
  

  chartData: any;
  chartOptions: { xAxisLabel: string; yAxisLabel: string; showXAxis: boolean; showYAxis: boolean; gradient: boolean; showLegend: boolean; showXAxisLabel: boolean; showYAxisLabel: boolean; xAxisTickFormatting: (value: string) => string; yAxisTickFormatting: (value: number) => string; };

  currentdate: any;
  loading = false;
roomDate: any;
roomDetails: any;
latest_date: any;
checkindate: any;
room : any;
rmdate: any;
changedate: any;
room_unique_id:any;
reservationRoom$:any;
checkinRoom$:any;
roomDetails$:any;
reservename:any;
reservephone:any;
reservestatus:any;
checkinId:any;
makedate = false;
checkin = false;
submitted=false;
checkinData = [];
reservationId = [];
reserve_checkin=false;
chekinusername:any;
checkinphone:any;
checkindetails$:any;
checkinstatus:any;
checkinsid=[];
checkin_count:any;
reservation_count:any;
block_count:any;
maintanance_count:any;
dtOptions: DataTables.Settings = {};
dtTrigger: Subject<any> = new Subject();
dirty_count:any;
updated:any;
synchbutton=false;
convertfile:any;
  getdashboardRevenue: any;
  selected: { start: Date; end: Date; };
  startDate: string;
  endDate: string;
  startFormatted: string;
  endFormatted: string;
  selected1: string;
  selected2: string;
  AllCountDasboard: any;
  chartPieData: any;
  colorScheme1: any;
  maintenance_count: any;
  totalrevenuetillnow: any;
  constructor(
    private roomSer: RoomService,
    private homeService: HomeService,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private chartsModule: ChartsModule

  ) { }

  ngOnInit(): void {
    this.colorScheme = {
      domain: ['#0173C1']
     };
 this.currentdate = new Date();
this.latest_date = this.datepipe.transform(this.currentdate, 'yyyy-MM-dd');
this.changedate=this.datepipe.transform(this.currentdate, 'yyyy-MM-dd');

this.roomSer.getSync().subscribe(data => {
  this.updated=data.last_sync;
  this.synchbutton=true;

  //console.log(this.updated)
//for the chart 
    const today = new Date();
    var startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    var endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    
   var startDate1 = this.datepipe.transform(startDate, 'yyyy-MM-dd');
   var endDate1 = this.datepipe.transform(endDate, 'yyyy-MM-dd');

    this.selected1 =  startDate1;
    this.selected2 = endDate1;
    this.homeService.getDashboardData(startDate1,endDate1).subscribe(data=>{
      this.getdashboardRevenue=data.status;
      this.totalrevenuetillnow = this.getdashboardRevenue.totalrevenutillnow;
      this.chartData=this.getdashboardRevenue.Daily_Revenue_Count.map((item:any)=>({
        name: item._id,
        value: item.totalPaidAmount
      }))
    })


});

// this.roomSer.getbokingsss().subscribe(data => {

// this.convertfile=data.data.Items;



 
// });
//all count of dashboard
this.roomSer.getDashobardCount(this.latest_date).subscribe(data =>{
  this.AllCountDasboard= data

  this.checkin_count=this.AllCountDasboard.data.checkin_count,
  this.reservation_count=this.AllCountDasboard.data.reservation_count,
  this.block_count=this.AllCountDasboard.data.block_count,
  this.maintanance_count =this.AllCountDasboard.data.maintenance_count ,


this.chartPieData = [
  {
    name: 'checkin count',
    value: this.AllCountDasboard && this.AllCountDasboard.data.checkin_count ? this.AllCountDasboard.data.checkin_count : '0',
    color: '#640eff'
   },
   {
    name: 'reservation count',
    value: this.AllCountDasboard && this.AllCountDasboard.data.reservation_count ? this.AllCountDasboard.data.reservation_count : '0',
    color: '#49b7fe' 
   },
   {
    name: 'block count',
    value: this.AllCountDasboard && this.AllCountDasboard.data.block_count ? this.AllCountDasboard.data.block_count : '0',
    color: '#866220' 
   },
   {
    name: 'maintenance count',
    value: this.AllCountDasboard && this.AllCountDasboard.data.maintenance_count ? this.AllCountDasboard.data.maintenance_count : '0',
    color: '#f7e903'
  }
];

  this.colorScheme1 = {
    domain: this.chartPieData.map((item:any) => item.color)
  };

})









// this.roomSer.getDashboardchk(this.latest_date).subscribe(data => {

//   this.checkin_count=data.data.checkin_count;


// });
// this.roomSer.getDashboardres(this.latest_date).subscribe(data => {

// this.reservation_count=data.data.reservation_count;


// });
// this.roomSer.getDashboardblk(this.latest_date).subscribe(data => {


// this.block_count=data.data.block_count;


// });
// this.roomSer.getDashboardmain(this.latest_date).subscribe(data => {


// this.maintanance_count=data.data.maintanance_count;

// });
// this.roomSer.getDashboarddirty(this.latest_date).subscribe(data => {


//   this.dirty_count=data.data.dirty_count;
  
//   });
  }
  onChartSelect(event) {
    console.log(event);
  }


  refresh(){
    const today = new Date();
    var startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    var endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    
   var startDate1 = this.datepipe.transform(startDate, 'yyyy-MM-dd');
   var endDate1 = this.datepipe.transform(endDate, 'yyyy-MM-dd');

    this.selected1 =  startDate1;
    this.selected2 = endDate1;
    this.homeService.getDashboardData(startDate1,endDate1).subscribe(data=>{
      this.getdashboardRevenue=data.status;
      this.totalrevenuetillnow = this.getdashboardRevenue.totalrevenutillnow;
      this.chartData=this.getdashboardRevenue.Daily_Revenue_Count.map((item:any)=>({
        name: item._id,
        value: item.totalPaidAmount
      }))
    })
  }


  selectedDatechange(event:any){
    console.log("drtgr1",this.selected1)
    console.log("drtgr2",this.selected2)
    this.homeService.getDashboardData(this.selected1,this.selected2).subscribe(data=>{
       this.getdashboardRevenue=data.status;
       console.log(this.getdashboardRevenue)
      this.chartData=this.getdashboardRevenue.Daily_Revenue_Count.map((item:any)=>({
        name: item._id,
        value: item.totalPaidAmount
      }))
    })
  }

  dateChange(e){
    this.changedate = '';
    this.changedate = e.target.value;
     this.roomSer.getDashboardchk(this.changedate).subscribe(data => {

            this.checkin_count=data.data.checkin_count;
        
  
        });
        this.roomSer.getDashboardres(this.changedate).subscribe(data => {

          this.reservation_count=data.data.reservation_count;
         

      });
      this.roomSer.getDashboardblk(this.changedate).subscribe(data => {

      
        this.block_count=data.data.block_count;
       

    });
    this.roomSer.getDashboardmain(this.changedate).subscribe(data => {

     
      this.maintanance_count=data.data.maintanance_count;

  });
  this.roomSer.getDashboarddirty(this.changedate).subscribe(data => {


    this.dirty_count=data.data.dirty_count;
    
    });
    }
    upload(e){
      this.convertfile.forEach(element => {
        // //console.log(element.photo_image);
        var base64 = element.idimage3;
        var imageName = 'name.jpeg';
        //console.log(base64)
        if(base64){
          var id=element._id;

        var imageBlob = this.dataURItoBlob(base64);
 
        var imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
//console.log(imageFile);
this.roomSer.updatebookingss(id,imageFile).subscribe(
      data => {
         if (data.status.code == 0) {
         this.toastr.success("success")
              } else {
                
              }
      },
      error => {

         
      });

        }
      });


    }
    dataURItoBlob(dataURI) {
      var binary = atob(dataURI.split(',')[1]);
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {
        type: 'image/jpeg'
      });
    }
    reportpage(e)
    {
       this.router.navigateByUrl('/occupied-report?date='+this.changedate);

    }
    reportpage1(e)
    {
       this.router.navigateByUrl('/reserved-report?date='+this.changedate);

    }







    sync(e){
      Swal.fire({
        title: 'Are you sure want to sync?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
      if(result.isConfirmed){
        var that = this;
    that.loading=true;
        that.roomSer.getSyncdb().subscribe(
              data => {
                 if (data.status.code == 0) {
                  that.loading=false;
                          that.submitted = false;
                          document.body.classList.remove('jw-modal-open');
                          that.loading = false;
                          that.toastr.success(data.status.message);
                          // that.checkinForm.setValue({
                           
                          //   food_name:'',
                          //   quantity:'',
                          //   rate:'',
                          //   amount:'',
                          // })
                    
                          //formWrap.reset();
                          setTimeout(function () {
                            $("#support_table5").dataTable().fnDestroy();
                            //that.roomSer.getFoodservice(that.uniqueId).subscribe(data=>{
                  
                              //console.log(data)
                            //  that.foodservice$=data.data;
                              that.dtTrigger.next();
                              })
                         // }, 1000);
                      } else {
                          document.body.classList.remove('jw-modal-open');
                          that.loading = false;
                          that.toastr.error(data.status.message)
                      }
              },
              error => {
  
                  that.toastr.error(error)
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
              });
    
    
        }
        else if (result.isDenied) {
          Swal.fire(
            'Cancelled!',
          )
        }
       });
  
  
    }


}
