import { Component, Renderer2, isDevMode } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { environment } from '../environments/environment';
import * as $ from "jquery";
import { interval } from 'rxjs';

import { AuthenticationService, ModalService } from './_services';
@Component({
  selector: 'app',
  templateUrl: 'app.component.html'
})

export class AppComponent {
  previousUrl: string;
  sub:any;
  loadAPI: Promise<any>;
  curYear: string = (new Date()).getFullYear().toString();

  constructor(private renderer: Renderer2, private router: Router,private authenticationService:AuthenticationService) {
    if (this.curYear != '2019') {
      this.curYear = '2019 - ' + this.curYear;

    }
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.previousUrl) {
            this.renderer.removeClass(document.body, this.previousUrl);
          }
          let currentUrlSlug = event.url.slice(1);

          if (currentUrlSlug) {
            this.renderer.addClass(document.body, currentUrlSlug);
          }

          if (currentUrlSlug == 'terms') {

            this.renderer.removeClass(document.body, 'terms');
            this.renderer.removeClass(document.body, 'temple_bg');
            this.renderer.addClass(document.body, 'white_bg');

          }

          if (currentUrlSlug == 'about') {
            this.renderer.removeClass(document.body, 'temple_bg');
            this.renderer.addClass(document.body, 'about_bg');
          }

          if (currentUrlSlug == 'login' || currentUrlSlug.includes('reset-password') || currentUrlSlug.match(/login\?returnUrl/g)) {
            this.renderer.addClass(document.body, 'login-page');
            this.renderer.removeClass(document.body, 'sidebar-mini');
            this.renderer.removeClass(document.body, 'layout-fixed');
            this.renderer.removeClass(document.body, 'sidebar-collapse');
          } else {
            this.renderer.addClass(document.body, 'temple_bg');
            this.renderer.removeClass(document.body, 'about_bg');

            if (`${environment.appName}` + '_adminId') {
              var OneSignal = window['OneSignal'] || [];


              OneSignal.push(function () {
                OneSignal.init({
                 // appId: `${environment.oneSignalAppId}`,
                  autoRegister: false,
                  autoResubscribe: true,
                });
                OneSignal.setSubscription(true);
                OneSignal.setExternalUserId(localStorage.getItem(`${environment.appName}` + '_id'));
              });

              this.renderer.removeClass(document.body, 'login-page');
              OneSignal.push(function () {
                OneSignal.setExternalUserId(localStorage.getItem(`${environment.appName}` + '_adminId'));
              });
            }
          }
          this.previousUrl = currentUrlSlug;
        }
      });
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });

    this.sub = interval(50*60*1000)
    .subscribe((val) => {
       //console.log('called api'); 
       this.authenticationService.getActive().subscribe(data => {
      
         //console.log(data.status.code)
         if(data.status.code==0){
         

         }else{
          this.authenticationService.logout();
           this.router.navigate(['/login'])
        
         }
           
           
      });

      });
  }

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [
     
        // 'assets/plugins/jquery/jquery.min.js',
        'assets/plugins/popper/popper.min.js',
        'assets/plugins/jquery-blockui/jquery.blockui.min.js',
        'assets/plugins/jquery-slimscroll/jquery.slimscroll.min.js',
        'assets/plugins/bootstrap/js/bootstrap.min.js',
        'assets/plugins/sparkline/jquery.sparkline.min.js',
        'assets/js/pages/sparkline/sparkline-data.js',
        'assets/js/app.js',
        'assets/js/layout.js',
        'assets/js/theme-color.js',
        'assets/plugins/material/material.min.js',
        'assets/plugins/morris/morris.min.js',
        'assets/plugins/morris/raphael-min.js',
        'assets/js/pages/chart/morris/morris_home_data.js',
        'assets/js/pages/extra_pages/login.js',
        'assets/plugins/sweet-alert/sweetalert.min.js',
        'assets/js/pages/sweet-alert/sweet-alert-data.js'
      
      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
      }

    }


  }
}

