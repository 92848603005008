<site-header></site-header>
<app-spinner></app-spinner>
<div class="page-container">
<div class="page-content-wrapper">
<div class="page-content">
<div class="page-bar">
<!-- <div class="page-title-breadcrumb">
<div class="card-head">
<div class="page-title">Front Desk</div>
</div>
</div> -->
</div>
<div class="row">
<div class="col-md-12">
<div class="row">
<div class="col-sm-12">
<div class="card">
<div class="row">
<div class="date">
<ul>
<span class="date_cal"><input type="date" name="date"
value="{{latest_date}}" (change)="dateChange($event)">
</span>
<span class="occupied">Occupied <i class="fa fa-circle"></i></span>
<span class="occupied">Reserved <i class="fa fa-circle rsv_cls"></i></span>
<!-- <span class="occupied">Reservation Blocked <i
class="fa fa-circle rscblk_cls"></i></span> -->
<!-- <span class="occupied">Dirty <i class="fa fa-circle drt_cls"></i></span> -->
<span class="occupied">Blocked <i class="fa fa-circle blk_cls"></i></span>
<span class="occupied">Maintenance <i
class="fa fa-circle mnt_cls"></i></span> 
<span class="occupied">Double Booking <i class="fa fa-circle dbl_cls"></i></span>
</ul>
</div>

</div>
<!-- <div class="date_month">{{roomDate[0].date}} - {{roomDate[29].date}}</div> -->
<div class="card-body">
<div class="">
    <table class="table table-bordered tbl_cls" style="position: relative;" id="table-1">
        <thead class="table-head">
        <tr>
        <th>&nbsp;&nbsp;&nbsp;</th>
        <th *ngFor="let dt of roomDate">
        {{dt.date| date : 'dd'}}</th>
        
        </tr>
        </thead>
        
        <tbody *ngFor="let rmd of roomDetails;let i =index">
            
               <td class="td_cls">
                     {{rmd.room_number}}
                </td>
                <td *ngFor="let dt of rmd.book_dates; let i = index" style="position: relative;"
                [ngStyle]="{
                    'background-color': dt.room_no == rmd._id ? 
                      (dt.status == '2' ? '#866220' :
                       dt.status == '20' ? '#ff2f00' :
                       dt.status == '1' ? '#ff8d00' :
                       dt.status == '-1' ? '#ffeb00' :
                       dt.hasOtherBookingsCount > 1 ? '#f3a100' :
                       dt.status == '10'  ? '#00b8ff' :
                       dt.status == '3' ? '#6200fffa' :
                       dt.status == '0' ? '#fff' : '#fff') : '#fff'
                  }"
                    (click)="dialogOpen($event,dt,rmd,i)" id="show">

                    <span *ngIf="dt.status == 10 && dt.hasOtherBookings == false"
                    style="color: white">{{dt.checkin_name | slice:0:2 }}</span>
                    <span *ngIf="dt.status == 3 && dt.hasOtherBookings == false "
                    style="color: white">{{ dt.checkin_name | slice:0:2  }}</span>
                    <!-- <span style="color:red ;font-size: x-small;" *ngIf="dt.hasOtherBookings == true">DR</span> -->
                    <span class="badgecnt"*ngIf="(dt.status == 3 || dt.status == 10) && dt.hasOtherBookings == true">{{dt.hasOtherBookingsCount}}</span>
                    <dialog id="window{{dt.room_unique_id}}" class="dialog_cls" >
                        <div class="">
                        <div class="date-room">
                        <span
                        class="room-no">{{rmd.room_number}}</span>
                        <span class="date-cal">{{dt.date}}</span>
                        </div>
                        <div class="dialog-text">
                        <div *ngIf="checkin"><a (click)="check($event,rmd,dt)"><span
                        class="checkin">CHECK IN</span></a>
                        </div>
                        <div *ngIf="makedate"><a
                        (click)="reservationDate($event,rmd,dt)"><span
                        class="make-reservation">MAKE
                        RESERVATION</span></a></div>
                        <div><a (click)="blockPopup($event,dt,rmd)"><span
                        class="block">{{dt.status==0?'BLOCK' :'UNBLOCK'}}</span></a></div>
                        <div><a
                        (click)="maintenancePopup($event,dt,rmd)"><span
                        class="maintenance">MAINTENANCE</span></a>
                        </div>
                        </div>
                        </div>
                        </dialog>
                        <dialog id="window1{{dt.room_unique_id}}" class="dialog_cls1" >
                            <div class="">
                                <div class="date-room1">
                                    <span
                                    class="room-no">CHEKIN DETAILS</span>
                                    <!-- <span class="date-cal">{{dt.date}}</span> -->
                                    </div>
                                    <div class="date-head9">
                                        <span
                                        class="room-no">PHONE NO:<span class="resname">{{checkinphone}}</span></span>
                                        <span class="date-cal">GUEST NAME:<span class="resname">{{chekinusername}}</span></span>
                                        
                                        </div>
                                        <table class="resrve">
                                            <thead class="date-head3">
                                                <th class="roomselect">SELECT</th>
                                                <th class="date-middle">ROOM NO</th>
                                                <th class="date-middle">CHEKIN DATE</th>
                                                <th class="date-cal">CHECKOUT DATE</th>
                                                <th class="date-cal">ROOM TYPE</th>
                                                <th class="date-cal">ACTION</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let chk of checkinRoom$;let i =index" style="color: black;" [ngStyle]="{'background-color':colorroom==chk.room_no ?'rgb(245 245 169 / 96%)':'#fff' }"  (click)="checkDiv($event,chk)">
                                                    <td class="room-no"><input type="checkbox" name="" class="case{{i}}" value="{{chk._id}}" (change)="selectChange1($event,chk,i)" ></td>
                                                    <td class="date-middle" >{{chk.rooms[0].room_number}}</td>
                                                    <td class="date-middle arrival-date" >{{chk.arrival_date | date:'dd-MMM-yyyy'}}</td>
                                                    <td class="date-middle" >{{chk.checkout_date | date:'dd-MMM-yyyy'}}</td>
                                                    <td class="date-cal" >{{chk.rooms_category[0].room_type}}</td>
                                                    <td class="date-cal">
                                                        <button (click)="editcheckininfo(dt,rmd,chk)" class="btn btn-cls"><a >EDIT</a></button>
                                                        <button (click)="foodservice(dt,chk)" class="btn btn-cls"><a >FOOD SERVICE</a></button>  
                                                        <button (click)="laundryservice(dt,chk)" class="btn btn-cls"><a >LAUNDRY SERVICE</a></button> 
                                                        <button (click)="extraservice(dt,chk)" class="btn btn-cls"><a >EXTRA SERVICE</a></button> 
                            
                                                       
                                                        <button (click)="checkout(dt,chk)"  class="btn btn-cls"><a >CHECK OUT</a></button>   
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table id="header-fixed"></table>
                                                <div (click)="checkDiv($event,chk)">
                                                     <button id="exit" type="button" (click)="cancelCheckins($event)" class="cancel_reservation">CANCEL CHECKIN</button>
                                                  
                                                       
                                                    
                                        
                                                </div>
                            <!-- <div class="date-room">
                            <span
                            class="room-no">{{rm.room_number}}</span>
                            <span class="date-cal">{{dt.date}}</span>
                            </div> -->
                            <!-- <div class="dialog-text1">
                            <div><a (click)="editcheckininfo(dt,rmd)" ><span
                            class="checkin">EDIT INFO</span></a>
                            </div>
                            <div><a (click)="CanceRoompopup($event,dt,rm)"
                            ><span
                            class="make-reservation">CANCEL ROOM</span></a></div>
                            <div><a (click)="extraservice(dt)"><span
                            class="block">EXTRA SERVICE</span></a></div>
                            <div><a (click)="checkout(dt)"
                            ><span
                            class="maintenance">CHECK OUT</span></a>
                            </div>
                            </div> -->
                            </div>
                            </dialog>
                            <dialog id="window2{{dt.room_unique_id}}" class="dialog_cls2" >
                                <div class="">
                                <div class="date-room2">
                                <span
                                class="room-no">RESERVATION DETAILS</span>
                                <!-- <span class="date-cal">{{dt.date}}</span> -->
                                </div>
                                <div class="date-head2">
                               <span
                                class="room-no">PHONE NO:<span class="resname">{{reservephone}}</span></span>
                                <span class="date-cal">GUEST NAME:<span class="resname">{{reservename}}</span></span> 
                                
                                </div>
                                
                                <!-- <div class="date-head3">
                                <span
                                class="room-no">SELECT</span>
                                <span class="date-middle">ROOM NO</span>
                                <span class="date-cal">ROOM TYPE</span>
                                </div>
                                <div class="room-details" *ngFor="let res of reservationRoom$;let r =index">
                                    <span
                                    class="roomselect">SELECT</span>
                                    <span class="roomno">{{res.rooms[0].room_number}}</span>
                                    <span class="roomtype">ROOM TYPE</span>
                                </div> -->
                                <table class="resrve">
                                    <thead class="date-head3">
                                        <th class="roomselect">SELECT</th>
                                        <th class="date-middle">ROOM NO</th>
                                        <th class="date-middle">ARIVAL DATE</th>
                                        <th class="date-cal">DEPARTURE DATE</th>
                                        <th class="date-cal">ROOM TYPE</th>
                                        <th class="date-cal">ACTION</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let res of reservationRoom$;let i =index" [ngStyle]="{'background-color':colorroom==res.room_no ?'rgb(245 245 169 / 96%)':'#fff' }" (click)="checkDiv($event,res)">
                                            <td class="room-no"><input type="checkbox" name="" class="case{{i}}" value="{{res._id}}" (change)="selectChange($event,res,i)" ></td>
                                            <td class="date-middle" >{{res.rooms[0].room_number}}</td>
                                            <td class="date-middle arrival-date" >{{res.arrival_date | date:'dd-MMM-yyyy'}}</td>
                                            <td class="date-middle" >{{res.departure_date | date:'dd-MMM-yyyy'}}</td>
                                            <td class="date-cal" >{{res.rooms_category[0]?.room_type}}</td>
                                            <td class="date-cal">
                                                <button class="btn btn-cls" (click)="editReservation($event,res,dt)"><a >Edit</a></button>
                                                <button class="btn btn-cls" *ngIf="res.arrival_date==latest_date" (click)="reservationcheckin($event,res)"> <a >Checkin</a></button></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table id="header-fixed"></table>
                                        <div (click)="checkDiv($event,res)">
                                             <button id="exit1" type="button" (click)="cancelreserve($event)" class="cancel_reservation">CANCEL RESERVATION</button>
                                        
                                        </div>
                                </div>
                                </dialog>
                                <!--for more than 1 reservation on the same day-->
                                <dialog id="window3{{dt.room_unique_id}}" class="dialog_cls3" >
                                    <div *ngIf="loading" class="loading-indicator">
                                        <div class="loading-text">
                                            <img style="height:50px;width:50px;" src="assets/images/loading.png" >
                                            <p>Loading...</p></div>
                                      </div>
                                    <div class="">
                                    <div class="date-room4">
                                    <span
                                    class="room-no">DETAILS</span>
                                    <span class="date-cal">{{dt.date}}</span>
                                    </div>
                                    <table class="resrve" *ngIf="!loading">
                                        <thead class="date-head3">
                                            <th class="roomselect">SELECT</th>
                                            <th class="date-middle">ROOM NO</th>
                                            <th class="date-middle">NAME</th>
                                            <th class="date-middle">PHONE</th>
                                            <th class="date-middle">ARIVAL DATE</th>
                                            <th class="date-cal">DEPARTURE DATE</th>
                                            <th class="date-cal">ROOM TYPE</th>
                                            <th class="date-cal">OCCUPIED OR RESERVED</th>
                                            <th class="date-cal">ARRIVAL TIME</th>
                                             <th class="date-cal">DEPARTURE TIME</th>
                                            <th class="date-cal">ACTION</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let res of reservationRoom$;let i =index" [ngStyle]="{'background-color':colorroom==res.room_no ?'rgb(245 245 169 / 96%)':'#fff' }" (click)="checkDiv($event,res)">
                                                <td class="room-no"><input type="checkbox" name="" class="case1{{i}}" value="{{res._id}}" (change)="selectChange($event,res,i,res.status)" ></td>
                                                <td class="date-middle" >{{res.rooms[0].room_number}}</td>
                                              
                                                <td class="date-middle" >{{res.name}} </td>
                                                <td class="date-middle" >{{res.phone}}</td>

                                                <td class="date-middle arrival-date" >{{res.arrival_date | date:'dd-MMM-yyyy'}}</td>
                                                <td class="date-middle" >
                                                    <span  *ngIf="res.status == 10">{{res.departure_date | date:'dd-MMM-yyyy'}}</span>
                                                    <span  *ngIf="res.status == 3">{{res.checkout_date | date:'dd-MMM-yyyy'}}</span>
                                                </td>
                                                <td class="date-cal" >{{res.rooms_category[0]?.room_type}}</td>
                                                <td class="date-cal" >
                                                    <span *ngIf="res.status == 10">reserved</span>
                                                    <span *ngIf="res.status == 3">Checkin</span>
                                                </td>
                                                <td class="date-cal" >
                                                    <span *ngIf="res.status == 10">{{res.arrival_time}}</span>
                                                    <span *ngIf="res.status == 3">{{res.checkin_time}}</span>
                                                </td>
                                                <td class="date-cal" >
                                                    <span *ngIf="res.status == 10">{{res.departure_time}}</span>
                                                    <span *ngIf="res.status == 3">{{res.checkout_time}}</span>
                                                </td>

                                                <td class="date-cal">
                                                    <button *ngIf="res.status == 3 "(click)="editcheckininfo(dt,rmd,res)" class="btn btn-cls"><a >EDIT</a></button>
                                                    <button class="btn btn-cls" *ngIf="res.status == 10 "(click)="editReservation($event,res,dt)"><a >Edit</a></button>
                                                    <button class="btn btn-cls" *ngIf="(res.arrival_date === latest_date )&& res.status == 10 " (click)="reservationcheckin($event,res)"> <a >Checkin</a></button>
                                                    <button  *ngIf="res.status == 3"(click)="checkout(dt,res)"  class="btn btn-cls"><a >CHECK OUT</a></button> 
                                                    <button *ngIf="res.status == 3" (click)="foodservice(dt,res)" class="btn btn-cls"><a >FOOD SERVICE</a></button>  
                                                    <button *ngIf="res.status == 3" (click)="laundryservice(dt,res)" class="btn btn-cls"><a >LAUNDRY SERVICE</a></button> 
                                                    <button  *ngIf="res.status == 3"(click)="extraservice(dt,res)" class="btn btn-cls"><a >EXTRA SERVICE</a></button>
                                                    <button  *ngIf="res.status == 3" id="exit" type="button" (click)="cancelCheckins($event)" class="cancel_reservation">CANCEL CHECKIN</button>
                                                    <button *ngIf="res.status == 10" id="exit1" type="button" (click)="cancelreserve($event)" class="cancel_reservation">CANCEL RESERVATION</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table id="header-fixed"></table>
                                    </div>
                                </dialog>
                </td>
        </table>
        
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>


<div class="page-container">


</div>


<site-footer></site-footer>