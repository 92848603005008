import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class FloorService {

  constructor(private http: HttpClient) { }
  saveFloor(
    name: any,
    status: any,

  ) {


    var formData = {
      'name': name,
      'status': status,
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/floors/store
`, formData).pipe(map(user => {
      return user;
    }));

  }
  saveRate(
    name: any,


  ) {


    var formData = {
      'name': name,

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/rate/store
`, formData).pipe(map(user => {
      return user;
    }));

  }
  updateRate(id,
    name: any,


  ) {


    var formData = {
      'name': name,

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/rate/` + id, formData).pipe(map(user => {
      return user;
    }));

  }




  saveItem(
    name: any,
    item_type: any,
    price: any

  ) {


    var formData = {
      'name': name,
      'item_type': item_type,
      'price': price
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/item/store
`, formData).pipe(map(user => {
      return user;
    }));

  }
  updateItem(id,
    name: any,
    item_type: any,
    price: any

  ) {


    var formData = {
      'name': name,
      'item_type': item_type,
      'price': price
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/item/` + id, formData).pipe(map(user => {
      return user;
    }));

  }




  saveTax(
    tax_name: any,
    percentage: any,

  ) {


    var formData = {
      'name': tax_name,
      'percentage': percentage,

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/tax/store
`, formData).pipe(map(user => {
      return user;
    }));

  }

  UpdateTax(id,
    tax_name: any,
    percentage: any,

  ) {


    var formData = {
      'name': tax_name,
      'percentage': percentage,

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/tax/` + id, formData).pipe(map(user => {
      return user;
    }));

  }


  saveFood(
    name: any,
    price: any,
    tax,percentage,type

  ) {


    var formData = {
      'name': name,
      'price': price,
      'tax':tax,
      'percentage': percentage,
      'taxtype':type

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/food/store
`, formData).pipe(map(user => {
      return user;
    }));

  }
  UpdateFood(id,
    name: any,
    price: any,
    tax,percentage,type

  ) {


    var formData = {
      'name': name,
      'price': price,
      'tax':tax,
      'percentage': percentage,
      'taxtype':type

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/food/` + id, formData).pipe(map(user => {
      return user;
    }));

  }
  savelaundry(
    name: any,
    price: any,tax,
    percentage,type

  ) {


    var formData = {
      'name': name,
      'price': price,
      'tax':tax,
      'percentage':percentage,
      'taxtype':type

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/laundry/store
`, formData).pipe(map(user => {
      return user;
    }));

  }
  Updatelaundry(id,
    name: any,
    price: any,tax,
    percentage,type
  ) {


    var formData = {
      'name': name,
      'price': price,
      'tax':tax,
      'percentage':percentage,
      'taxtype':type

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/laundry/` + id, formData).pipe(map(user => {
      return user;
    }));

  }
  saveRoomType(
    room_type: any,
    price: any,
    rate_type: any,


  ) {


    var formData = {
      'room_type': room_type,
      'price': price,
      'rate_type': rate_type,


    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/rooms-type/store
`, formData).pipe(map(user => {
      return user;
    }));

  }

  saveRooms(
    room_number: any,
    room_type: any,
    floor_name: any,
    percentage: any,
    tax: any,
    status: any,
    type: any

  ) {


    var formData = {
      'room_number': room_number,
      'room_type': room_type,
      'floor_name': floor_name,
      'percentage': percentage,
      'tax': tax,
      'isActive': status,
      'taxtype': type

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/rooms/store
`, formData).pipe(map(user => {
      return user;
    }));

  }
  updateRooms(id: any,
    room_number: any,
    room_type: any,
    floor_name: any,
    percentage: any,
    tax: any,
    status: any,type:any
  ) {
    var formData = {
      'room_number': room_number,
      'room_type': room_type,
      'floor_name': floor_name,
      'percentage': percentage,
      'tax': tax,
      'isActive': status,
      'taxtype': type

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/rooms/` + id, formData).pipe(map(user => {
      return user;
    }));

  }


  getFloor(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/floors`).pipe(map(user => user));
  }

  getActiveFloor(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/active-floors`).pipe(map(user => user));
  }
  getRooms(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/rooms`).pipe(map(user => user));
  }
  getRate(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/rate`).pipe(map(user => user));
  }
  getTaxType(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/taxtypes/getall`).pipe(map(user => user));
  }

  getItem(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/item`).pipe(map(user => user));
  }
  getTax(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/tax`).pipe(map(user => user));
  }
  getFood(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/food`).pipe(map(user => user));
  }
  getlaundry(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/laundry`).pipe(map(user => user));
  }
  getfoods(id): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/food/` + id).pipe(map(user => user));
  }

  getLaundrys(id): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/laundry/` + id).pipe(map(user => user));
  }


  getRoomType(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/rooms-type`).pipe(map(user => user));
  }
  updateRoomType(id: any,
    room_type: any,
    price: any,
    rate_type: any,
  ) {
    var formData = {
      'room_type': room_type,
      'price': price,
      'rate_type': rate_type,
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/rooms-type/` + id, formData).pipe(map(user => {
      return user;
    }));

  }

  updateFloor(id: any,
    name: any,
    status: any,
  ) {
    var formData = {
      'name': name,
      'status': status,
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/floors/` + id, formData).pipe(map(user => {
      return user;
    }));

  }
  DeleteRoomtype(id: string) {


    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/rooms-type/` + id).pipe(map(user => {
      return user;
    }));



  }
  DeleteRooms(id: string) {


    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/rooms/` + id).pipe(map(user => {
      return user;
    }));



  }
  DeleteRate(id: string) {


    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/rate/` + id).pipe(map(user => {
      return user;
    }));




  }
  DeleteItem(id: string) {


    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/item/` + id).pipe(map(user => {
      return user;
    }));




  }

  DeleteTax(id: string) {


    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/tax/` + id).pipe(map(user => {
      return user;
    }));





  }
  DeleteFood(id: string) {


    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/food/` + id).pipe(map(user => {
      return user;
    }));





  }

  Deletelaundry(id: string) {


    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/laundry/` + id).pipe(map(user => {
      return user;
    }));





  }
  DeleteFloor(id: string) {


    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/floors/` + id).pipe(map(user => {
      return user;
    }));


  }

}
