
<site-header></site-header>
<div class="page-container">

</div>
<div class="page-content-wrapper">
<div class="page-content">

<div class="row">

    <div class="col-sm-12">
        <div class="page-bar">
    <div class="page-title-breadcrumb">
        <div class="card-head">
                <div class="page-title">Extra Services</div>			
        </div>
    </div>
</div>
<form [formGroup]="checkinForm" role="form" (ngSubmit)="onSubmit(checkinForm, myForm)"  #myForm="ngForm">
        <div class="card-box">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-2 p-t-10" >
                        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                             <label class="mdl-textfield__label">Name</label>
                               <h6>{{name}}</h6> 
                        </div>
                        </div>
                        <div class="col-lg-2 p-t-10" >
                            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                 <label class="mdl-textfield__label">Phone</label>
                                   <h6>{{phone1}}</h6> 
                            </div>
                            </div>
                            <div class="col-lg-2 p-t-10" >
                                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                     <label class="mdl-textfield__label">Room Type</label>
                                       <h6>{{room_type}}</h6> 
                                </div>
                                </div>
                                <div class="col-lg-2 p-t-10" >
                                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                         <label class="mdl-textfield__label">Room Number</label>
                                           <h6>{{room_number}}</h6> 
                                    </div>
                                    </div>

                </div>
                      <!-- <div class="row">
                      <div class="col-lg-2 p-t-10" >
                            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                <input class="mdl-textfield__input" type="text" id="txtRoomNo" formControlName="name" readonly>
                                <label class="mdl-textfield__label">Name</label>

                            </div>
                            
                        </div>
                      </div> -->

           
        
               <!-- main start -->

               <div  >
              
            
              <div class="row rrf">
                  <!-- <div class="col-sm-8"> -->

                 <div class="col-lg-4 p-t-10 ">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                        <label class="mdl-textfield__label" for="thing">Item type</label>
                        <select class="mdl-textfield__input" id="thing" name="thing" formControlName="item_name">
                         <option value="0" disabled>Please Select Item</option>
                       
                          <option *ngFor="let user of item$;let i=index" value="{{user._id}}">{{user.name}}</option>
                         
                        </select>
            </div>
            <div class="invalid">
                <div *ngIf="(f.item_name.touched || submitted) && f.item_name.errors?.required"
                class="invalid-feedback">
                <div *ngIf="f.item_name.errors?.required">Item type required</div>
            </div>
            </div>
                    
                    </div>


                <div class="col-lg-4 p-t-10">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <input class="mdl-textfield__input" type="text"  id="txtRoomNo" formControlName="quantity" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                        <label class="mdl-textfield__label">Quantity</label>
                    </div>
                    <div class="invalid">
                        <div *ngIf="(f.quantity.touched || submitted) && f.quantity.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.quantity.errors?.required">Quantity required</div>
                    </div>
                    </div>
                    
                    </div>
                    
                                        <!-- <div class="col-lg-3 p-t-10">
                                        <div
                                            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                            <input class="mdl-textfield__input" type="date" id="ardate" formControlName="fromdate" (change)="datechange($event)">
                                            <label class="mdl-textfield__label">From Date</label>
                                        </div>
                                        <div class="invalid">
                                            <div *ngIf="(f.fromdate.touched || submitted) && f.fromdate.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.fromdate.errors?.required">From Date required</div>
                                        </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 p-t-10">
                                        <div
                                            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                            <input class="mdl-textfield__input" type="date" id="rdate" formControlName="todate" (change)="datechange($event)">
                                            <label class="mdl-textfield__label">To Date</label>
                                        </div>
                                        <div class="invalid">
                                            <div *ngIf="(f.todate.touched || submitted) && f.todate.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.todate.errors?.required">To Date required</div>
                                        </div>
                                        </div>
                                    </div> -->
                
                  <div class="col-lg-4 p-t-10">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <input class="mdl-textfield__input" type="text" id="txtRoomNo" formControlName="totalday" >
                        <label class="mdl-textfield__label">No of Days</label>

                    </div>
                    <div class="invalid">
                        <div *ngIf="(f.totalday.touched || submitted) && f.totalday.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.totalday.errors?.required">No of Days required</div>
                    </div>
                    </div>
                    
                </div>

            <!-- </div> --> <!-- col 8 close -->

            </div>  <!-- row close -->

            
           </div>  <!-- main class end -->

           <div class="row">
           
                <div class="col-lg-12 p-t-20 text-left">
                    <div class="btnsr">	
                    <button type="submit"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-primary">SUBMIT</button>
                    <button type="button" (click)="cancel()"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">CANCEL</button>
                </div>
            </div>
        </div>

              

              </div> <!-- card body close -->
           </div> 
           </form> <!-- card box end -->
        </div>  <!-- sm 12 -->
    </div>  <!-- row -->


<!-- table -->
 <div class="page-bar">
    <div class="page-title-breadcrumb">
        <div class="card-head">
                            
        </div>
    </div>
</div>






         <div class="tab">

              <div class="row">
    <div class="col-sm-12">
        <div class="card-box">
            <!-- <div class="card-head">
                <header>Normal table</header>
            </div> -->
            <div class="card-body ">
                <div class="card-body tb-wrapper ">
                    <div class="table-wrap">
                        <div class="table-responsive tblHeightSet ">
                <table class="table display product-overview mb-30" id="support_table5" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
                    <thead style="background-color: #167CE4; color: white;">
                        <tr>
                            <th>Sl No</th>
                            <th>Item Name</th>
                            <th>Quantity</th>
                            <!-- <th>From Date</th>
                            <th>To Date</th> -->
                            
                            <th>No of Days</th>
                            <th >Action</th>
                            
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let user of extraservice$;let i=index" >
                            <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                            <td  
                           >{{user.item_name.name}}
                        </td>
                            <td *ngIf="user.quantity;else  quantity">{{user.quantity}} </td>
                            <ng-template #quantity>
                                <td>-</td>
                            </ng-template>
                            <!-- <td *ngIf="user.fromdate;else fromdate">{{user.fromdate | date : 'dd-MM-yyyy'}}</td>
                            <ng-template #fromdate>
                                <td>-</td>
                            </ng-template>
                            <td *ngIf="user.todate;else todate">{{user.todate | date : 'dd-MM-yyyy'}}</td>
                            <ng-template #todate>
                                <td>-</td>
                            </ng-template> -->
                            <td *ngIf="user.totalday;else totalday">{{user.totalday}}</td>
                            <ng-template #totalday>
                                <td>-</td>
                            </ng-template>
                            <!-- <td>
                                <a (click)="userEdit($event, user, i)" href="javascript:void(0);" class="btn btn-tbl-edit btn-xs">
                                    <i class="fa fa-pencil"></i>
                                </a>
                                <button class="btn btn-tbl-delete btn-xs" (click)="deleteExtraService($event, user, i)" >

                                    <i class="fa fa-trash-o "></i>
                                </button>
                              
                            </td> -->
                            <td>                  <button class="btn btn-tbl-delete btn-xs" (click)="deleteExtraService($event, user, i)" >

                                <i class="fa fa-trash-o "></i>
                            </button>
                                </td>
                        </tr>
                    
                    </tbody>
                    <!-- <tbody *ngIf="extraservice$.">
                      <span>  <h3 style="text-align: center;
    padding-top: 15px;
    padding-left: 485px;">No Extra service Found!</h3></span>
                    </tbody> -->
                </table>
             </div>

            <!-- BUTTONS -->
<!-- 
            <div class="row ml-2" >	
                <div class="col-lg-12 p-t-20 text-right">
                    <button type="button"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-primary">SUBMIT</button>
                    
                    

                    <button type="button"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">CANCEL</button>
                </div>
            </div> -->
            
            <!--  BTN END -->

                    </div>
                </div>
                </div>
            </div>
        </div>

    </div>

  

   </div>






</div> <!-- containers -->
</div>
