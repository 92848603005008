import { Component, ViewChild, ElementRef,OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertService, ModalService, RoomService } from '../_services';
import { DatePipe, formatDate, getLocaleDateTimeFormat } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Injectable } from '@angular/core';
import htmlToPdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

declare var html2pdf: any;
declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var checked: any;


@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {
  @ViewChild('invoice') invoice:ElementRef;
  @ViewChild('closepasswordmodel') closepasswordmodel:any;
dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  invoice$ : any;
  curPage = 1;
  perPage = 10;
  invoice_details: any;
  details$: any;
  currentdate : any;
  latest_date: any;
  print: any;
  invoicebutton=false;
  password:any;
  userId:any;

  tutorials: any;
  currentTutorial = null;
  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 10;
  pagingCounter=0;

  constructor(
    private roomSer: RoomService,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit(){

    this.currentdate = new Date();
this.latest_date = this.datepipe.transform(this.currentdate, 'dd/MM/yyyy');

    const that = this;

     this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Email"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

    //   this.roomSer.getInvoice('').subscribe(data => {
    //   this.invoice$ = data.data.checkout.docs;
    // //console.log(this.invoice$);
    //   // this.dtTrigger.next();
    // },error=>{
    //   //console.error(error);
    // });

    this.retrieveTutorials();

/*this.print = '';
      this.roomSer.getSingleInvoice(this.print).subscribe(data=>{
  this.details$ = data.data.checkout;
  //console.log(this.details$)
})*/
  }

  handlePageChange(event) {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageseachChange(event) {
    this.title = event.target.value;
    this.page=1;
    this.retrieveTutorials();
  }


  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  getRequestParams(searchTitle, page, pageSize) {
    // tslint:disable-next-line:prefer-const
    let params = {};

    params[`search`]='';
    if (searchTitle) {
      params[`search`] = searchTitle;
    }
    if (page) {
      params[`page`] = page;
    }
    if (pageSize) {
      params[`size`] = pageSize;
    }
    return params;
  }

  retrieveTutorials() {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    this.roomSer.getInvoice(params)
      .subscribe(
        response => {
          // const { tutorials, totalItems } = response.data.checkout.docs;
          this.invoice$ = response.data.checkout.docs;
          console.log( this.invoice$)
          this.count = response.data.checkout.totalDocs;
          this.pagingCounter=response.data.checkout.pagingCounter;
          //console.log(response);
        },
        error => {
          //console.log(error);
        });
  }


openPDF(user) {
  this.print = user._id;
this.invoicebutton=true;
this.roomSer.getSingleInvoice(user._id).subscribe(data=>{
  this.details$ = data.data.checkout;
  //console.log(this.details$)
})


    var element = JSON.parse(user.pdf);


  $('#invoice').html(element);
 
  $('#invoice').show();

  window.scrollTo(0, 1000);
  // var ele="<html><head><style>body{margin:0px:}</style></head><body>"+element+"</body></html>";

  // //console.log(ele);
  // const div = document.getElementById("invoice");
//     html2pdf().from(div).toPdf().get('pdf').then(function (pdf) {
 
//   window.open(pdf.output('bloburl'), '_blank');




  
// }); 
  }
  delete1(user){
    // console.log(user)
    this.userId=user._id
  }
  deleteInvoice(){
   if(!this.password){
    this.toastr.error("please enter password")
    return false
   }
   console.log(this.password)
   this.roomSer.deleteInvoice(this.userId,this.password, 1).subscribe(data=>{
 
      if(data.status.code === 0){
        this.toastr.success(data.status.message)
       this.password=""
        // $("#myModal").model("hide")
        this.closepasswordmodel.nativeElement.click()
        const params = this.getRequestParams(this.title, this.page, this.pageSize);
        this.roomSer.getInvoice(params)
          .subscribe(
            response => {
              // const { tutorials, totalItems } = response.data.checkout.docs;
              this.invoice$ = response.data.checkout.docs;
              console.log( this.invoice$)
              this.count = response.data.checkout.totalDocs;
              this.pagingCounter=response.data.checkout.pagingCounter;
              //console.log(response);
            },
            error => {
              //console.log(error);
            });
      }else{
        this.toastr.error(data.status.message)
        this.password=""
      }
      this.password=""
      
  })
}
}
