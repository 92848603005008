// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appName: "hotel",
  production: true,

  apiUrl: 'https://coral-api.invoicemaster.in/',


  // apiUrl: 'http://localhost:4002/',  
  // apiUrl: 'http://65.1.213.186:4002/',







  oneSignalAppId: 'c382baff-142f-4e16-b7c4-f6133d400ea4', //dev
  // media_domain: 'http://3.111.243.2:4000/',//dev

  media_domain: 'https://d3dx5e2ie92ks1.cloudfront.net/production',
  apiPrefix: 'api/v1',
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
