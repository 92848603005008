import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FloorService } from '../_services/floor.service';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var checked: any;

@Component({
  selector: 'app-add-room-master',
  templateUrl: './add-room-master.component.html',
  styleUrls: ['./add-room-master.component.css']
})
export class AddRoomMasterComponent implements OnInit {
  RoomForm: FormGroup;
  loading = false;
  submitted = false;
  radioCheck = null;
  rate$: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  curPage = 1;
  perPage = 10;
  status$ = true;
  cancelButton = false;
  rate_type = null;
  RoomType$: any;
  floors$: any;
  rooms$: any;
  for_temple = 1;
  ctax: any;
  cstatus = 0;
  tax$: any;
  applytax = false;
  stateConfig = {

    displayKey: 'name', // if objects array passed which key to be displayed defaults to description
    search: false, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Rate type ', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No Rate type found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search State', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  type$: any;
  taxtype: boolean = false;
  perc: boolean;
  typename: any;
  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private FloorService: FloorService,
    private route: ActivatedRoute,
    private router: Router) { }



  ngOnInit(): void {

    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
    this.RoomForm = this.formBuilder.group({
      index: null,
      _id: null,
      room_number: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(nonWhitespaceRegExp)]],
      room_type: ['', [Validators.required]],
      floor_name: ['', [Validators.required]],
      percentage: ['', [Validators.required,]],
      tax: ['', [Validators.required]],
      type: [''],
      status: ['', [Validators.required]],
    })

    this.FloorService.getRate().subscribe(data => {
      //console.log(data);
      this.rate$ = data.data.rateTypes;
      // this.dtTrigger.next();
    });

    this.FloorService.getTaxType().subscribe(data => {
      //console.log(data);
      this.type$ = data.data.taxTypes;
      // this.dtTrigger.next();


    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: [9],

      }],
      // order: [[ 2, 'asc' ]]
    };


    this.FloorService.getRoomType().subscribe(data => {
      //console.log(data);
      this.RoomType$ = data.data.roomTypes;



    });
    this.FloorService.getActiveFloor().subscribe(data => {
      //console.log(data);
      this.floors$ = data.data.floors;



    });

    this.FloorService.getTax().subscribe(data => {
      //console.log(data);
      this.tax$ = data.data.Tax;


    });

    this.FloorService.getRooms().subscribe(data => {
      //console.log(data);
      this.rooms$ = data.data.rooms;
      this.dtTrigger.next();


    });
    $("#drop").hide();
  }

  get f() { return this.RoomForm.controls; }


  changetax(event) {
    if (event.target.value) {
      this.perc = true
      this.taxtype = true;
      this.RoomForm.controls["type"].setValidators([Validators.required]);
      this.RoomForm.controls["type"].updateValueAndValidity();
    }
  }

  tax(event) {
    if (event.target.value == 1 && this.perc) {
      this.taxtype = true;
      this.RoomForm.controls["type"].setValidators([Validators.required]);
      this.RoomForm.controls["type"].updateValueAndValidity();
    } else {
      this.taxtype = false;
      this.RoomForm.controls["type"].clearValidators();
      this.RoomForm.controls["type"].updateValueAndValidity();
    }
  }

  onSubmit(form, formWrap) {



    this.submitted = true

    let id = form.getRawValue()._id;
    let index = form.getRawValue().index;
    const that = this;
    //console.log(this.RoomForm)

    if (this.RoomForm.invalid) {
      this.toastr.error('Please Enter All Fields');
      return false;
    }


    if (id != null) {
      alertify.confirm('Update Room type', 'Are you sure want to update this record', function () {
        // document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
        that.loading = true;

        that.FloorService.updateRooms(
          id,
          that.f.room_number.value,
          that.f.room_type.value,
          that.f.floor_name.value,
          that.f.percentage.value,
          that.f.tax.value,
          that.f.status.value,
          that.f.type.value,
        )
          .subscribe(
            data => {

              if (data.status.code == 0) {

                that.toastr.success('Room updated successfully');

                that.submitted = false;
                document.body.classList.remove('jw-modal-open');



                that.loading = false;
                // location.reload();
                that.cancelButton = false;
                document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                that.RoomForm.setValue({
                  index: null,
                  _id: null,
                  room_number: '',
                  room_type: '',
                  floor_name: '',
                  percentage: '',
                  tax: '',
                  status: '',
                  type: ''

                })

                formWrap.reset();
                $('.first').removeClass('is-checked');
                $('.second').removeClass('is-checked');
                $('.taxyes').removeClass(' is-checked');
                $('.taxno').removeClass('is-checked');
                $("#drop").hide();
                $("#support_table5").dataTable().fnDestroy();
                that.FloorService.getRooms().subscribe(data => {
                  //console.log(data);
                  that.rooms$ = data.data.rooms;
                  that.dtTrigger.next();


                });

              } else {
                // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                // document.body.classList.remove('jw-modal-open');
                that.loading = false;
                that.toastr.error(data.status.message)

              }
            },
            error => {
              that.toastr.error(error)

              document.body.classList.remove('jw-modal-open');
              that.loading = false;
            });
      }, function () { //alertify.error('Delete action cancelled')
        that.toastr.error('Create action cancelled')
      });

    } else {
      alertify.confirm('Create Room', 'Are you sure want to create this record', function () {
        // document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
        that.loading = true;

        that.FloorService.saveRooms(
          that.f.room_number.value,
          that.f.room_type.value,
          that.f.floor_name.value,
          that.f.percentage.value,
          that.f.tax.value,
          that.f.status.value,
          that.f.type.value

        )
          .subscribe(
            data => {

              if (data.status.code == 0) {

                that.toastr.success('Room added successfully');

                that.submitted = false;
                document.body.classList.remove('jw-modal-open');



                that.loading = false;
                // location.reload();

                that.RoomForm.setValue({
                  index: null,
                  _id: null,
                  room_number: '',
                  room_type: '',
                  floor_name: '',
                  percentage: '',
                  tax: '',
                  status: '',
                  type: ''

                })

                formWrap.reset();
                $('.first').removeClass('is-checked');
                $('.second').removeClass('is-checked');
                $('.taxyes').removeClass(' is-checked');
                $('.taxno').removeClass('is-checked');
                $("#drop").hide();
                $("#support_table5").dataTable().fnDestroy();
                that.FloorService.getRooms().subscribe(data => {
                  //console.log(data);
                  that.rooms$ = data.data.rooms;
                  that.dtTrigger.next();


                });


              } else {
                // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                // document.body.classList.remove('jw-modal-open');
                that.loading = false;
                that.toastr.error(data.status.message)

              }
            },
            error => {
              that.toastr.error(error)

              document.body.classList.remove('jw-modal-open');
              that.loading = false;
            });
      }, function () { //alertify.error('Delete action cancelled')
        that.toastr.error('Create action cancelled')
      });


    }
  }

  userEdit(event, user, i) {
    window.scroll(0, 0);
    //console.log(user.isActive)
    $('.first').removeClass('is-checked');
    $('.second').removeClass('is-checked');
    $('.taxyes').removeClass(' is-checked');
    $('.taxno').removeClass('is-checked');

    $('.mdl-textfield').addClass('is-dirty');

    this.cstatus = user.isActive;

    this.status$ = true
    this.cancelButton = true;
    if (user.taxtype) {
      this.taxtype = true;
    } else {
      this.taxtype = false;
    }
    //this.table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
    this.RoomForm.patchValue({
      index: i,
      _id: user._id,
      room_number: user.room_number,
      room_type: user.room_type,
      floor_name: user.floor_name,
      percentage: user.percentage,
      tax: user.tax,
      status: user.isActive,
      type: user.taxtype
    })
    if (user.isActive == 1) {
      $('.first').addClass('is-upgraded is-checked');
    } else if (user.isActive == 0) {
      $('.second').addClass('is-upgraded is-checked');
    }
    if (user.tax == 1) {
      $('.taxyes').addClass('is-upgraded is-checked');
      this.RoomForm.controls['percentage'].enable();
      $("#drop").show();
    } else if (user.tax == 0) {
      $('.taxno').addClass('is-upgraded is-checked');
      this.RoomForm.controls['percentage'].disable();
      $("#drop").hide();
    }

    // this.imageExist =user.image;
    // this.imageExist1=user.cover;
  }
  taxtypename(id) {
    this.type$.forEach(data => {
      if (data._id == id) {
        this.typename = data.name;
        console.log(this.typename)

      }

    })
    return this.typename
  }

  userDelete(event, user, i) {
    var that = this;
    alertify.confirm('Delete Rooms', 'Are you sure want to delete this record', function () {
      that.FloorService.DeleteRooms(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success('Deleted Successfully')
              // alertify.success('Deleted Successfully')
              setTimeout(function () {
                $("#support_table5").dataTable().fnDestroy();
                that.FloorService.getRooms().subscribe(data => {
                  //console.log(data);
                  that.rooms$ = data.data.rooms;
                  that.dtTrigger.next();


                });
              }, 1000);
              //that.users$.splice(i, 1);
            } else {
              that.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            that.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
          });
    }
      , function () { //alertify.error('Delete action cancelled')
        // that.toastr.error('Delete action cancelled')

      });

  }

  cancelSubmit(event) {
    $('.first').removeClass('is-checked');
    $('.second').removeClass('is-checked');
    $('.taxyes').removeClass(' is-checked');
    $('.taxno').removeClass('is-checked');
    $('.mdl-textfield').removeClass('is-focused');
    $('.mdl-textfield').removeClass('is-dirty');
    $("#drop").hide();
    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.RoomForm.setValue({
      index: null,
      _id: null,
      room_number: '',
      room_type: '',
      floor_name: '',
      percentage: '',
      tax: '',
      status: '',
      type: ''
    })



    this.submitted = false;
  }
  ngAfterViewInit() {
    $(document).on('focus', 'input,select,textarea', function (e) {
      var cur = $(this);
      $('input,select,textarea').parent().removeClass('is-focused');
      cur.parent()[0].classList.remove('is-focused');

      // cur.parent()[0].removeClass('is-focused')

      cur.parent()[0].classList.add('is-focused')
    })

    //  first mdl-radio mdl-js-radio is-upgraded is-checked


    $(function () {
      $(document).on('change', '#checkbox1', function () {
        if (this.checked && $('#checkbox2')[0].checked) {
          $('#checkbox2').trigger('click');
        }
      });
      $(document).on('change', '#checkbox2', function () {
        if (this.checked && $('#checkbox1')[0].checked) {
          $('#checkbox1').trigger('click');
        }
      });
    });
    var that = this;
    $("#inline_content input[name='tax']").click(function () {
      if ($('input:radio[name=tax]:checked').val() == "1") {
        that.RoomForm.controls['percentage'].enable();
        $("#drop").show();
      }
      else if ($('input:radio[name=tax]:checked').val() == "0") {
        that.RoomForm.controls['percentage'].setValue('');
        that.RoomForm.controls['percentage'].disable();
        that.applytax = false;
        $("#drop").hide();
      }
    });



  }

  check(e) {
    //console.error(e);
  }
}
