  <div class="page-footer">
        <div class="page-footer-inner"> 
            Copyright &copy;  {{year}} Coral Comforts. All rights reserved,Designed and developed by
            <a href="https://www.chipsyservices.com" target="_blank">Chipsy Services</a>
        </div>
        <div class="scroll-to-top">
            <i class="icon-arrow-up"></i>
        </div>
  </div>
    

  <!-- <div class="col-sm-6">
    <div class="copyright_right flt">
        <div class="copyright_right_head flt">Copyright ©<script type="text/javascript">
            document.write(new Date().getFullYear());
            </script> Abharan. All rights reserved</div>
        <div class="copyright_right_cont flt">
            Designed and developed by <a href="https://www.chipsyservices.com" target="_blank">Chipsy Services</a>
        </div>
    </div>
</div> -->