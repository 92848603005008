import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class InvestigatorsService {
    constructor(private http: HttpClient) { }

    save(name, c1, c2, designation, departmentId, sub_inv) {
        var formData = {};
        formData['name'] = name;
        formData['departmentId'] = departmentId;
        // formData['username'] = username;
        formData['designation'] = designation;
        formData['c1'] = c1;
        formData['c2'] = c2;
        // formData['password'] = password;
        var invs = [];
        if (sub_inv.length) {
            sub_inv.forEach(x => {
                invs.push(x.id);
            })
        }
        formData['sub_investigatore'] = invs;
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/investigatore`, formData).pipe(map(user => {
            return user;
        }));

    }

    update(id, name, c1, c2, designation, departmentId, sub_inv) {

        var formData = {};
        formData['name'] = name;
        formData['departmentId'] = departmentId;
        // formData['username'] = username;
        formData['designation'] = designation;
        formData['c1'] = c1;
        formData['c2'] = c2;
        // formData['password'] = password; 

        var invs = [];
        if (sub_inv.length) {
            sub_inv.forEach(x => {
                invs.push(x.id);
            })
        }
        formData['sub_investigatore'] = invs;
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/investigatore/` + id, formData).pipe(map(user => {
            return user;
        }));

    }
    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/investigatore/delete/` + id).pipe(map(user => {
            return user;
        }));

    }
    getPackages(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/investigatore/get`).pipe(map(user => user));

    }
}