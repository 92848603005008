import { Component, OnInit } from '@angular/core';
//import { environment } from '../../../environments/environment';
//import { HelperService, AuthenticationService, NotifyService, utilService } from '../../_services/';
//import { Router, ActivatedRoute } from '@angular/router';
//import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';

declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'site-footer',
  templateUrl: './site-footer.component.html',
})
export class SiteFooterComponent implements OnInit {
year:any;
 constructor() { }
 
  ngOnInit(): void {
    this.year=new Date().getFullYear()
  }
  

}
