<site-header></site-header>
<div class="page-container">
    <div class="page-content-wrapper">
        <div class="page-content">
            <div class="page-bar">
                <div class="page-title-breadcrumb">
                    <div class="card-head">
                        <div class="page-title">Invoice</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="card  card-box">
                        <div class="card-head">
                            <header>Invoice Details</header>
                            <!--  <div class="tools">
                                <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                                <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                                <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                            </div> -->
                        </div>
                        <div class="card-body ">
                            <div class="search" style="margin-bottom: 10px;">
                                <input type="text" class="form-control seachinput" placeholder="Search"
                                    (keyup)="handlePageseachChange($event)" />
                            </div>
                            <div class="table-wrap">

                                <div class="table-responsive">
                                    <table cellspacing="0" id="table"
                                        class="table table-hover dataTable table-striped w-full">
                                        <thead style="background-color: #167CE4; color: white;">
                                            <tr>
                                                <th>Sl No</th>
                                                <th>Name</th>
                                                <th>Invoice No</th>
                                                <th>Check In</th>
                                                <th>Invoice Date</th>
                                                <th>Phone</th>
                                                <!-- <th>Room No</th>
                                                <th>Room Type</th> -->
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="invoice$ && invoice$.length >0 ">
                                            <tr *ngFor="let user of invoice$ | paginate : {
                                                itemsPerPage: pageSize,
                                                currentPage: page,
                                                totalItems: count
                                              };
                                        let i = index
                                      " [class.active]="i == currentIndex"
                                            > <!--//(click)="setActiveTutorial(tutorial, i)"-->
                                                <td>{{ pageSize * (page - 1) + i + 1 }}</td>


                                                <td *ngIf="user.name;else name">
                                                    {{user.name ? user.name : '-'}}</td>
                                                <ng-template #name>
                                                    <td>-</td>
                                                </ng-template>
                                                <td *ngIf="user.invoice_no;else invoice_no">
                                                    {{user.invoice_no ? user.invoice_no : '-'}}</td>
                                                <ng-template #invoice_no>
                                                    <td>-</td>
                                                </ng-template>
                                                <td *ngIf="user.checkin_date;else date">{{user.checkin_date }}</td>
                                                <ng-template #date>
                                                    <td>-</td>
                                                </ng-template>
                                                <td *ngIf="user.bill_date;else cdate">{{user.bill_date }}</td>
                                                <ng-template #cdate>
                                                    <td>-</td>
                                                </ng-template>
                                                <td *ngIf="user.phone;else phone">{{user.phone ? user.phone : '-'}}</td>
                                                <ng-template #phone>
                                                    <td>-</td>
                                                </ng-template>

                                                <!-- <ng-container *ngFor="let rm of user.rooms">
                                                <td *ngIf="rm.room_number;else room">
                                                        
                                                  {{rm.room_number ? rm.room_number : '-'}}</td>
                                                   <ng-template #room>
                                                <td>-</td>
                                            </ng-template>
                                                    </ng-container> -->
                                                <!-- <ng-container *ngFor="let rc of user.room_types">
                                                <td *ngIf="rc.room_type;else roomtype">{{rc.room_type ? rc.room_type : '-'}}</td> 
                                                  <ng-template #roomtype>
                                                <td>-</td>
                                            </ng-template>
                                                 </ng-container> -->
                                                <td *ngIf="user.name;else view"><a (click)="openPDF(user)">
                                                        <li class="fa fa-eye">
                                                    </a> &nbsp; 
                                                      <a (click)="delete1(user)" data-toggle="modal" data-target="#myModal"><li class="fa fa-trash"> </li> </a>
                                                    </td>

                                            </tr>


                                            <!-- 
                                          Items per Page:
                                          <select (change)="handlePageSizeChange($event)">
                                            <option *ngFor="let size of pageSizes" [ngValue]="size">
                                              {{ size }}
                                            </option>
                                          </select> -->

                                        </tbody>
                                        
                                    </table>
                                    <div class="no_data" *ngIf="invoice$ && invoice$.length== 0">
                                        No Data
                                    </div>
                                    <div *ngIf="invoice$ && invoice$.length > 0">
                                        <p>Showing {{pagingCounter}} to {{page*10}} of {{count}} entries</p>
                                    </div>

                                    <pagination-controls *ngIf="invoice$ && invoice$.length > 0" previousLabel="Prev"
                                        nextLabel="Next" responsive="true"
                                        (pageChange)="handlePageChange($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="page-container"></div>
<div class="text-center">
    <button type="button"
        class=" mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-primary text-center print"
        printSectionId="invoice" *ngIf="invoicebutton" id="invoicebtn" ngxPrint
        styleSheetFile="assets/css/style.css">Print Invoice</button>
</div>

<div id="invoice"></div>

<site-footer></site-footer>

<div class="modal" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Please Enter Password </h4>
          <button type="button" class="close" data-dismiss="modal" #closepasswordmodel>&times;</button>
        </div>
        
        <!-- Modal body -->
        
        <div class="form-group modal-body">
            <label for="exampleInputPassword1">Password</label>
            <input type="password" [(ngModel)]="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
          </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button (click)="deleteInvoice()" type="button" class="btn btn-primary" >Submit</button>
        </div>
        
      </div>
    </div>
  </div>