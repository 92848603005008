import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class HomeService {
    constructor(private http: HttpClient) { }
    getCountry(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/dropboss/getCountries`).pipe(map(user => user));
    }

    getState(id): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/dropboss/getStates?countryId=` +id).pipe(map(user => user));
    }

  searchFilter(product: any, client: any) {

      //console.log(product)
      var products = product

      return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/dashboard`, { product_id: product, client_id: client }).pipe(map(user => {
        return user;
          }));

    }
    getClients() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/client`).pipe(map(user => user));
    }
    
    getProducts() {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product`).pipe(map(user => user));
  }
 

  storeTempleImages(
    id:any,
    image1:any,
    image2:any,
    image3:any,
    image4:any,
    cover_set:any,
  ){
    const formData: FormData = new FormData();
    if(image1){
      formData.append('image1', image1);
    }else{
      formData.append('image1_delete', '1');
    }

    if(image2){
      formData.append('image2', image2);
    }else{
      formData.append('image2_delete', '1');
    }
   
    if(image3){
      formData.append('image3', image3);
    }else{
      formData.append('image3_delete', '1');
    }
   
    if(image4){
      formData.append('image4', image4);
    
    }else{
      formData.append('image4_delete', '1');
    }
   

   
   
    formData.append('cover_set', cover_set);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/temple/post-image/`+id, formData).pipe(map(user => {
                return user;
            }));
  }


  storeTempleImages1(
    id:any,
    image1:any,
    image2:any,
    image3:any,
    image4:any,
    image5:any,
    image6:any,
    image7:any,
    image8:any,
    image9:any,
    image10:any,
    cover_set:any,
    


  ){
    const formData: FormData = new FormData();
    if(image1){
      formData.append('image1', image1);
    }else{
      formData.append('image1_delete', '1');
    }

    if(image2){
      formData.append('image2', image2);
    }else{
      formData.append('image2_delete', '1');
    }
   
    if(image3){
      formData.append('image3', image3);
    }else{
      formData.append('image3_delete', '1');
    }
   
    if(image4){
      formData.append('image4', image4);
    
    }else{
      formData.append('image4_delete', '1');
    }
    if(image5){
      formData.append('image5', image5);
    
    }else{
      formData.append('image5_delete', '1');
    }
   
    if(image6){
      formData.append('image6', image6);
    
    }else{
      formData.append('image6_delete', '1');
    }
   
    if(image7){
      formData.append('image7', image7);
    
    }else{
      formData.append('image7_delete', '1');
    }
   
    if(image8){
      formData.append('image8', image8);
    
    }else{
      formData.append('image8_delete', '1');
    }
   
    if(image9){
      formData.append('image9', image9);
    
    }else{
      formData.append('image9_delete', '1');
    }
   
    if(image10){
      formData.append('image10', image10);
    
    }else{
      formData.append('image10_delete', '1');
    }
   
   
   
    formData.append('cover_set', cover_set);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/temple/post-image/`+id, formData).pipe(map(user => {
                return user;
            }));
  }

  save(
    name: any,
    other_category: any,
    presiding_deity:any,
    deity_category:any,
    otherdname:any,
    // nivedham:any,
    address: any,
    stateId: any,
    city: any,
    pin:any,
    lat: any,
    long: any,
    temple_age:any,
    architectureId: any,
    about:any,
   timings: any,
    coverImage,
    options,
    desireId,
    language,
    status: any,
    auspicious_days,
    auspicious_nakshatra,
    auspicious_tithi,
    about_missing,
  ) {
  //console.log(desireId);
 
var formData = {
'name':name,
'other_category': other_category,
'presiding_name':presiding_deity,
'presidingId':deity_category,
'other_deities':otherdname,
// 'nivedham':nivedham,
'address':address,
'stateId':stateId,
'city':city,
'pin':pin,
'lat':lat,
'long':long,
'age':temple_age,
'architectureId':architectureId,
'timings': timings,


'about':about,
 //'cover_image':coverImage,
'activities':options,
'desireId':desireId,
'lang_address':[{
'lang':language,
'value':address,
},{
'lang':language,
'value':address,
}]

,
'lang_name':{
'lang':language,
'value':name,
},
'lang_presiding_name':{
'lang':language,
'value':presiding_deity,
},

'lang_about':{
'lang':language,
'value':about,
},
'lang_other_deities':{
'lang':language,
'value':otherdname,
},
'status': status,
'auspicious_days':auspicious_days,
'auspicious_nakshatra':auspicious_nakshatra,
'auspicious_tithi':auspicious_tithi,
'about_missing':about_missing,







}


     

 /*     formData.append('nivedan', nivedan);
      formData.append('about',about);*/
     

      return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/temple/create
`, formData).pipe(map(user => {
          return user;
      }));

  }



    update(id:any,
      name: any,
      other_category: any,
      presiding_deity:any,
      deity_category:any,
      otherdname:any,
      // nivedham:any,
      address: any,
      stateId: any,
      city: any,
      pin:any,
      lat: any,
      long: any,
      temple_age:any,
      architectureId: any,
      about:any,
      timings: any,
      coverImage,
      // image1,
      // image2,
      // image3,
      desireId,
      options,
      auspicious_days,
      auspicious_nakshatra,
      auspicious_tithi,
      about_missing,
      status,
     /* nivedan: any,
      about: any,*/
    ) {
      //console.log(desireId);
var formData = {
  'name':name,
  'other_category': other_category,
  'presiding_name':presiding_deity,
  'presidingId':deity_category,

  'other_deities':otherdname,
  // 'nivedham':nivedham,
  'address':address,
  'stateId':stateId,
  'city':city,
  'pin':pin,
  'lat':lat,
  'long':long,
  'age':temple_age,
  'architectureId':architectureId,
   'timings': timings,
 
  
  'about':about,
  //'cover_image':coverImage,
  // 'image1':image1,
  // 'image2':image2,
   
'activities':options,
'desireId':desireId,
'auspicious_days':auspicious_days,
'auspicious_nakshatra':auspicious_nakshatra,
'auspicious_tithi':auspicious_tithi,
'about_missing':about_missing,
'status': status,







}


       

       

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/temple/` + id, formData).pipe(map(user => {
          return user;
        }));

    }
  
    getTemple(page,search,desireId,age_low,age_high,google_novalidated): Observable <any> {
      if(!search){
         search ='';
      }
       if(!desireId){
         desireId ='';
      }
      if(!age_low){
        age_low = '';
      }
      if(!age_high){
        age_high = '';
      }
     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/temple?page=` + page + '&search='+search+'&desireId='+desireId +'&age_low=' +age_low+ '&age_high=' +age_high + '&google_novalidated=' + google_novalidated).pipe(map(user => user));
   }

    /*getTemple2(page,search,desireId,age_low,age_high,google_novalidated): Observable <any> {
      if(!search){
         search ='';
      }
       if(!desireId){
         desireId ='';
      }
      if(!age_low){
        age_low = '';
      }
      if(!age_high){
        age_high = '';
      }
     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/temple?page=` + page + '&search='+search+'&desireId='+desireId +'&age_low=' +age_low+ '&age_high=' +age_high + '&google_novalidated=' + google_novalidated).pipe(map(user => user));
   }*/

   getTemple1(page,search,desireId,age_low,age_high): Observable <any> {
    if(!search){
       search ='';
    }
     if(!desireId){
       desireId ='';
    }
    if(!age_low){
      age_low = '';
    }
    if(!age_high){
      age_high = '';
    }
   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/temple?page=` + page +'&status=1'+ '&search='+search+'&desireId='+desireId +'&age_low=' +age_low+ '&age_high=' +age_high).pipe(map(user => user));
 }


    getUsers(page,search): Observable <any> {
      if(!search){
        search ='';
     }
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/users?page=`+ page + '&search='+search).pipe(map(user => user));
    }
    getUsers1(page,search): Observable <any> {
      if(!search){
        search ='';
     }
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/users?page=`+ page + '&status=1'+ '&search='+search).pipe(map(user => user));
    }

    getTempleDetails(id: any): Observable<any> {
   

      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/temple/` + id).pipe(map(data => {
          return data;
      }))
    
    
    }
    getUserDetails(id: any): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/temple/` + id).pipe(map(user => {
          return user;
      }))
    
    
    }

    templeStatus(id: any, status: any) {

      //  //console.log(id)
        var formData = {
          'status': status
        }
  
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/temple/status/` + id, formData).pipe(map(user => {
          return user;
            }));
 
      }
     

      userStatus(id: any, status: any) {

        //  //console.log(id)
          var formData = {
            'status': status
          }
    
          return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/user-status/` + id, formData).pipe(map(user => {
            return user;
              }));
          
        }

        RemoveUser(id: any,templeid:any) {

          //  //console.log(id)
            var formData = {
             'userId':templeid
            }
      
            return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/remove-user/` + id, formData).pipe(map(user => {
              return user;
                }));
            
          }

          RemovesStory(id: any) {

            
        
              return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/delete-story/` + id).pipe(map(user => {
                return user;
                  }));
              
            }
    
  



    delete(id: string) {
       

            return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/temple/` + id).pipe(map(user => {
              return user;
        }));

    }
    delete_order(id:string){
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/order/delete/`+id).pipe(map(user => user));
    }

    getReportStory(): Observable <any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/report-story`).pipe(map(user => user)); 
    }

    getReportUser(): Observable <any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/report-users`).pipe(map(user => user)); 
    }

    getDashboard(): Observable <any>{
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/dashboard`).pipe(map(user => user)); 
    }
    getPresiding(): Observable <any>{
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/dropdowns`).pipe(map(user => user)); 
    }

    addNewCity(id:any,city){
      const formData = {	
     
        'city':city,	
     
        }
      return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/add-city/`+id, formData).pipe(map(user => {
        return user;
    }));
    }

    getCities(id): Observable <any>{
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/get-cities?stateId=`+id).pipe(map(user => user)); 
    }
    getOptions(): Observable <any>{
      return this.http.get<any> (`${environment.apiUrl}${environment.apiPrefix}/web/dropdowns`).pipe(map(user => user)); 
    }
    updateLanguage(	
      id:any,	
      name: any,	
      presiding_deity:any,	
     	
      otherdname:any,	
      // nivedham:any,	
      address: any,	
     	
      about:any,	
      selected_language:any,	
     	
     	
    ){	
      const formData = {	
      data:{  	
      'name':name,	
  'presiding_name':presiding_deity,	
  'other_deities':otherdname,	
  	
  'address':address,	
  	
  'about':about,	
  	
      },	
      'lang':selected_language,	
 	
      }	
      return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/temple/lang/`+id, formData).pipe(map(user => {	
                  return user;	
              }));	
    }	
    editLanguage(id,language): Observable <any>{	
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/temple/lang/`+id+`?lang=`+language).pipe(map(user => user)); 	
    }	
    getLanguage(): Observable <any>{	
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/language`).pipe(map(user => user)); 	
    }

    sendNumber(mobile_no: any,country_code: any){
      var formData = {
        'mobile_no' : mobile_no,
        'country_code' : country_code,
      }
         return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/invite-user`, formData).pipe(map(user => {
                return user;
            }));
      
    }

    getAccess(): Observable <any>{
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/request-temple-access`).pipe(map(user => user));       
   } 

   getDashboardData(startDate,endDate){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-graphData?startDate=`+ startDate + '&endDate=' + endDate).pipe(map(user => user));
 }

}
