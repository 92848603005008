import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
      
        if(localStorage.getItem(`${environment.appName}`+'_user')){
         let token = localStorage.getItem(`${environment.appName}`+'_user');
          if (token) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${token}`
                }
            });
        }

        }else {  
          let token = localStorage.getItem(`${environment.appName}`+'vendor_user');
          
            if (token) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${token}`
                }
            });
           }

        }
       
       
        return next.handle(request);
    }
}