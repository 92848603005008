import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, RoomService } from '../_services';
import { FloorService } from '../_services/floor.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DatePipe, formatDate, getLocaleDateTimeFormat } from '@angular/common';
import { Subject } from 'rxjs';
import { DataTablesModule } from 'angular-datatables';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { interval } from 'rxjs';

declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit, AfterViewInit {
  reservationForm: FormGroup;
  loading = false;
  submitted = false;
  rsrdate: any;
  rmcat: any;
  roomcat: any;
  $reservation: any;
  reservationId: any;
  roomId: any;
  rm_id: any;
  rmno_data: any;
  roomDetails: any;
  $rooms: any;
  roomNo: any;
  countyselectedItems: any;
  month: any;
  day: any;
  ardateselected: any;
  currentdate: any;
  latest_date: any;
  roomnumber: any;
  mindate: any;
  roomprice: any;
  userdetails$: any;
  price: any;
  id: any;
  reservation$: any;
  room_number: any;
  rm_type: any;
  room_type: any;
  advance: any;
  resdate: any;
  rmnumber: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  addroom = true;
  uniqueid: any;
  isReadonly = false;
  RoomType$: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pagingCounter = 0;
  title = '';

  initOptionRows() {
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
    return this.formBuilder.group({
      roomtype: ['', [Validators.required]],
      roomnumber: ['', [Validators.required]],
      total: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      rooms: [],
      roomprice: []
    });

  }
  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private router: Router,
    private roomSer: RoomService,
    private FloorService: FloorService) { }

  ngOnInit() {
    this.currentdate = new Date();
    this.latest_date = this.datepipe.transform(this.currentdate, 'yyyy-MM-dd');
    this.retrieveTutorials();
    this.FloorService.getRoomType().subscribe(data => {
      this.RoomType$ = data.data.roomTypes;
    });
    this.roomSer.getRoomsALL().subscribe(data => {
      this.roomDetails = data.data.rooms;
    })
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
    this.reservationForm = this.formBuilder.group({
      reservationdate: [this.latest_date, [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(/^[0-9]+$/), Validators.maxLength(10), Validators.minLength(10)]],
      name: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z \-\']+')]],
      gender: ['',],
      ardate: ['', [Validators.required]],
      artime: ['', [Validators.required]],
      drdate: ['', [Validators.required]],
      drtime: ['', [Validators.required]],
      address: ['', [Validators.required, Validators.maxLength(500)]],
      city: ['', [Validators.pattern('^[a-zA-Z \-\']+')]],
      state: ['', [Validators.pattern('^[a-zA-Z \-\']+')]],
      country: ['', [Validators.pattern('^[a-zA-Z \-\']+')]],
      male: ['', [Validators.pattern(/^[0-9]+$/)]],
      female: ['', [Validators.pattern(/^[0-9]+$/)]],
      children: ['', [Validators.pattern(/^[0-9]+$/)]],
      remark: [''],
      options: this.formBuilder.array([this.initOptionRows()]),
      rmctid: ['']
    });
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.rsrdate = params['reserve_date'];
      this.rmno_data = params['room_no'];
   
      if (this.id) {
        this.addroom=false;
        this.roomSer.getSingleReservation(this.id).subscribe(data => {

          this.reservation$ = data.data.rooms[0];
          this.room_number = this.reservation$ && this.reservation$.rooms && this.reservation$.rooms && this.reservation$.rooms[0]._id ? this.reservation$.rooms[0]._id : '';
          this.room_type = this.reservation$ && this.reservation$.rooms_category && this.reservation$.rooms_category && this.reservation$.rooms_category[0]._id ? this.reservation$.rooms_category[0]._id : '';
          this.reservationForm.get('options')['controls'][0].controls.roomtype.setValue(this.room_type);
          this.reservationForm.get('options')['controls'][0].controls.roomnumber.setValue(this.room_number);
          this.reservationForm.get('options')['controls'][0].controls.total.setValue(this.reservation$.advance);
          this.reservationForm.get('options')['controls'][0].controls.roomprice.setValue(this.reservation$ && this.reservation$.rooms_category && this.reservation$.rooms_category && this.reservation$.rooms_category[0].price ? this.reservation$.rooms_category[0].price : '');
         
          this.reservationForm.patchValue({
            index: 1,

            _id: this.reservation$._id,
            reservationdate: this.reservation$.reservation_date,
            phone: this.reservation$.phone,
            name: this.reservation$.name,
            gender: this.reservation$.gender,
            ardate: this.reservation$.arrival_date,
            artime: this.reservation$.arrival_time,
            drdate: this.reservation$.departure_date,
            drtime: this.reservation$.departure_time,
            address: this.reservation$.address,
            city: this.reservation$.city,
            state: this.reservation$.state,
            country: this.reservation$.country,
            male: this.reservation$.male,
            female: this.reservation$.female,
            children: this.reservation$.children,
            remark: this.reservation$.remark,

          })


          this.uniqueid=this.reservation$.uniqueId;




        })
      }

      if(this.rsrdate && this.rmno_data){
        var cdate=this.formatDate(this.rsrdate);
        this.reservationForm.patchValue({
          ardate : cdate
      })
      this.reservationForm.get('options')['controls'][0].controls.roomnumber.setValue(this.rmno_data);
      }
        
   

    })


  }

  handlePageChange(event) {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageseachChange(event) {
    this.title = event.target.value;
    this.page=1
    this.retrieveTutorials();
  }


  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  getRequestParams(searchTitle, page, pageSize) {
    // tslint:disable-next-line:prefer-const
    let params = {};

    params[`search`] = '';
    if (searchTitle) {
      params[`search`] = searchTitle;
    }
    if (page) {
      params[`page`] = page;
    }
    if (pageSize) {
      params[`size`] = pageSize;
    }
    return params;
  }

  retrieveTutorials() {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    this.roomSer.getReservation(params).subscribe(data => {
      this.$reservation = data.data.rooms.docs;
      this.count = data.data.rooms.totalDocs;
      this.pagingCounter = data.data.rooms.pagingCounter;


    })

  }



  setSelectedTime1(e) {

  }

  setSelectedTime(e) {

  }

  valuechange(e) {
    this.roomSer.getUserDetails(e.target.value).subscribe(data => {
      this.userdetails$ = data.data;
      this.reservationForm.patchValue({
        name: this.userdetails$ && this.userdetails$[0] && this.userdetails$[0].name ? this.userdetails$[0].name : '',
        gender: this.userdetails$ && this.userdetails$[0] && this.userdetails$[0].gender ? this.userdetails$[0].gender : '',
        address: this.userdetails$ && this.userdetails$[0] && this.userdetails$[0].address ? this.userdetails$[0].address : '',
        city: this.userdetails$ && this.userdetails$[0] && this.userdetails$[0].city ? this.userdetails$[0].city : '',
        state: this.userdetails$ && this.userdetails$[0] && this.userdetails$[0].state ? this.userdetails$[0].state : '',
        country: this.userdetails$ && this.userdetails$[0] && this.userdetails$[0].country ? this.userdetails$[0].country : '',
      });
    })

  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }



  roomCat(e, i, form) {
      this.roomSer.getroomtype(e.target.value).subscribe(data => {
        this.reservationForm.get('options')['controls'][i].controls.roomprice.setValue(data.data.roomTypes.price)

      })
  
  }

  get f() { return this.reservationForm.controls; }
  onSubmit(form, formWrap) {

    let index = form.getRawValue().index;
    console.log(this.reservationForm.controls)
    this.submitted = true;
    if (this.reservationForm.invalid) {
      var that = this
      that.toastr.error('Please enter all fields')
      return;
    }

    var dt1 = new Date(this.f.ardate.value + " " + this.convertTime12to24(this.f.artime.value));
    var dt2 = new Date(this.f.drdate.value + " " + this.convertTime12to24(this.f.drtime.value));
    if (dt1.getTime() === dt2.getTime()) {

      this.toastr.error("Invalid Time !!!Please enter valid Departure time.it must greater than checkin time")
      return false;
    }

    if (dt1.getTime() > dt2.getTime()) {

      this.toastr.error("Invalid Time !!!Please enter valid Departure  time.it must greater than checkin time")
      return false;

    }
    if (this.f.male.value == 0 && this.f.female.value == 0 && this.f.children.value == 0) {
      var that = this
      that.toastr.error('All male,female and child count should not be zero')
      return;
    }
    if (this.f.ardate.value > this.f.drdate.value) {
      this.toastr.error('Arrival date should not be greater than Departure date')
      return;
    }

    var optionarray = []
    this.reservationForm.value.options.map(val => {

      optionarray.push(val)

      console.log(optionarray)
    })
    var room = ''


    console.log(optionarray.find(res => res.roomnumber))
    var arr = [];
    arr = optionarray.map(item => item.roomnumber)
      .filter((value, index, self) => self.indexOf(value) !== index)
    if (arr.length) {
      this.toastr.error("Please check room number,same room number multiple time selected")
      return false;
    }

    if (this.id != null) {

      Swal.fire({
        title: 'Are you sure want to update this room?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          var that = this;

          that.roomSer.updatereservation(
            that.id,
            that.rmno_data,
            that.f.reservationdate.value,
            that.f.phone.value,
            that.f.name.value,
            that.f.gender.value,
            that.f.ardate.value,
            that.f.artime.value,
            that.f.drdate.value,
            that.f.drtime.value,
            that.f.address.value,
            that.f.city.value,
            that.f.state.value,
            that.f.country.value,
            that.f.male.value,
            that.f.female.value,
            that.f.children.value,
            that.f.remark.value,
            that.f.options.value,
            10,
            that.uniqueid,


          )
            .subscribe(
              data => {
                if (data.status.code == 0) {
                  that.submitted = false;
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.toastr.success(data.status.message);

                  setTimeout(function () {
                    that.router.navigateByUrl("/front-desk");
                  }, 1000);
                } else {
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.toastr.error(data.status.message)


                }

              },



              error => {

                that.toastr.error(error)
                document.body.classList.remove('jw-modal-open');
                that.loading = false;
              });
        } else if (result.isDenied) {
          Swal.fire(
            'Cancelled!',
          )
        }
      });
    }
    else {

      Swal.fire({
        title: 'Are you sure want to reserve this room?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          var that = this;

          that.roomSer.reservation(
            that.rmno_data,
            that.f.reservationdate.value,
            that.f.phone.value,
            that.f.name.value,
            that.f.gender.value,
            that.f.ardate.value,
            that.f.artime.value,
            that.f.drdate.value,
            that.f.drtime.value,
            that.f.address.value,
            that.f.city.value,
            that.f.state.value,
            that.f.country.value,
            that.f.male.value,
            that.f.female.value,
            that.f.children.value,
            that.f.remark.value,
            that.f.options.value,
            10
          )
            .subscribe(
              data => {
                if (data.status.code == 0) {
                  that.submitted = false;
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.toastr.success(data.status.message);

                  setTimeout(function () {
                    //             $("#support_table5").dataTable().fnDestroy();
                    //             that.roomSer.getReservation().subscribe(data=>{
                    //      that.$reservation = data.data.rooms;
                    //        that.dtTrigger.next();

                    //  })
                    that.router.navigateByUrl("/front-desk");
                  }, 1000);
                } else {
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  /* if (data.status.code == 1) {
                    that.toastr.error('ghfhf')
                   }*/
                  that.toastr.error(data.status.message)
                }

              },



              error => {
                that.toastr.error(error)
                document.body.classList.remove('jw-modal-open');
                that.loading = false;
              });
        } else if (result.isDenied) {
          Swal.fire(
            'Cancelled!',
          )
        }
      });
    }


  }

  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }
  add_activity() {

    const control = <FormArray>this.reservationForm.controls.options;
    control.push(this.initOptionRows());
    console.log(this.reservationForm.controls.options['controls'])
    var i = 0;
    $('.mdl-textfield').addClass('is-dirty');
  }

  remove(i) {
    console.log(i)
    const arrayControl = <FormArray>this.reservationForm.controls.options;
    arrayControl.removeAt(i);
  }


  editReservation(e, res) {
    window.scroll(0, 0);
    e.stopPropagation();
    this.router.navigateByUrl("/reservation?id=" + res._id);
  }





  cancel(e) {
    this.router.navigateByUrl("/front-desk");
  }

  deleteReservation(event, res) {
    this.id = res._id;
    console.log(res._id)
    event.stopPropagation();
    var that = this;
    that.loading = true;
    Swal.fire({
      title: 'Are you sure want to delete this reservation?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {

        that.roomSer.delete(res._id)
          .subscribe(
            data => {
              if (data.status.code == 0) {
                that.submitted = false;
                document.body.classList.remove('jw-modal-open');
                that.loading = false;
                that.toastr.success(data.status.message);
                that.retrieveTutorials();

              } else {
                document.body.classList.remove('jw-modal-open');
                that.loading = false;
                that.toastr.error(data.status.message)
              }

            },



            error => {

              that.toastr.error(error)
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
            });
      } else if (result.isDenied) {
        Swal.fire(
          'Cancelled!',
        )
      }
    });


  }


  datechange(e) {

    this.ardateselected = this.formatDate(e.target.value);
    console.log(this.ardateselected)
    document.getElementById("drdate").setAttribute("min", this.ardateselected);
    //  console.log(this.ardateselected)
  }



  ngAfterViewInit() {
    $(document).on('focus', 'input,select,textarea', function (e) {
      var cur = $(this);
      $('input,select,textarea').parent().removeClass('is-focused');
      cur.parent()[0].classList.remove('is-focused');

      // cur.parent()[0].removeClass('is-focused')

      cur.parent()[0].classList.add('is-focused')
    })
    // alert(this.rsrdate)
    var that = this;

 


    let today = new Date().toISOString().slice(0, 10)
    var cdate = that.formatDate(that.rsrdate);

    if (!that.rsrdate) {

      that.mindate = document.getElementById("ardate").setAttribute("min", today);
    }
    else {
      that.mindate = document.getElementById("ardate").setAttribute("min", today);
    }

    document.getElementById("drdate").setAttribute("min", cdate);
  }



}
