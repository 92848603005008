<site-header></site-header>


<div class="page-container">


    <div class="page-content-wrapper">
        <div class="page-content">
            <div class="page-bar">
                <div class="page-title-breadcrumb">
                    <div class=" pull-left">
                        <div class="page-title">Add Room Details</div>
                    </div>
                    <ol class="breadcrumb page-breadcrumb pull-right">
                        <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="/">Home</a>&nbsp;<i
                                class="fa fa-angle-right"></i>
                        </li>
                        <li>&nbsp;<a class="parent-item">Settings</a>&nbsp;<i class="fa fa-angle-right"></i>
                        </li>
                        <li class="active">Add Room Details</li>
                    </ol>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="card  card-box">
                        <div class="card-head">
                            <header>Add Room Details</header>
                            <div class="tools">
                                <!-- <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                            <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                            <a class="t-close btn-color fa fa-times" href="javascript:;"></a> -->
                            </div>
                        </div>
                        <form class="flt " role="form" [formGroup]="RoomForm" #myForm="ngForm"
                            (ngSubmit)="onSubmit(RoomForm, myForm)">
                            <div class="card-body row">
                                <div class="col-lg-6 p-t-20">
                                    <div
                                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                        <input class="mdl-textfield__input" type="text" id="txtRoomType"
                                            autocomplete="off" formControlName="room_number">
                                        <label class="mdl-textfield__label">Room Number<span
                                                style="color:red">*</span></label>
                                    </div>
                                    <div class="invalid">
                                        <div *ngIf="(f.room_number.touched || submitted) && f.room_number.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.room_number.errors?.required">Room number is required</div>
                                        </div>
                                        <div *ngIf="(f.room_number.touched || submitted) && f.room_number.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.room_number.errors?.maxlength">Maximum 20 characters are
                                                allowed
                                            </div>
                                        </div>
                                        <div *ngIf="(f.room_number.touched || submitted) && f.room_number.errors?.pattern"
                                            class="invalid-feedback">
                                            <div *ngIf="f.room_number.errors?.pattern">Empty space is not allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 p-t-20">
                                    <div
                                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                        <label class="mdl-textfield__label" for="thing">Room Type<span
                                                style="color:red">*</span></label>
                                        <select class="mdl-textfield__input" id="thing" name="thing"
                                            formControlName="room_type">

                                            <option *ngFor="let user of RoomType$;let i=index" value="{{user._id}}">
                                                {{user.room_type}}</option>

                                        </select>
                                    </div>
                                    <div class="invalid">
                                        <div *ngIf="(f.room_type.touched || submitted) && f.room_type.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.room_type.errors?.required">Room type is required</div>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-6 p-t-20">
                                    <div
                                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                        <label class="mdl-textfield__label" for="thing1">Floor Name<span
                                                style="color:red">*</span></label>
                                        <select class="mdl-textfield__input" id="thing1" name="thing1"
                                            formControlName="floor_name">

                                            <option *ngFor="let user of floors$;let i=index" value="{{user._id}}">
                                                {{user.name}}</option>

                                        </select>
                                    </div>
                                    <div class="invalid">
                                        <div *ngIf="(f.floor_name.touched || submitted) && f.floor_name.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.floor_name.errors?.required">Floor name is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 p-t-20">

                                    <div>
                                        <span class="status_label">Status<span style="color:red">*</span></span>
                                    </div>
                                    <div class="radio_button">
                                        <!-- <label  class="first mdl-radio mdl-js-radio" for="option1"> -->
                                        <input type="radio" id="option1" name="status" class="mdl-radio__button"
                                            value="1" [checked]="cstatus==1" formControlName="status">
                                        <span class="mdl-radio__label active_cls">Active</span>
                                        <!-- </label> -->

                                        <!-- <label class="second  mdl-js-ripple-effect"
                                            for="option2"> -->
                                        <input type="radio" id="option2" value="0" [checked]="cstatus==0" name="status"
                                            formControlName="status" class="mdl-radio__button">
                                        <span class="mdl-radio__label">Inactive</span>
                                        <!-- </label> -->

                                    </div>
                                    <div>
                                        <div *ngIf="(f.status.touched || submitted) && f.status.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.status.errors?.required">Status is required</div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="card-body row">
                                <!-- <div class="col-lg-6 p-t-20">
                        
                            <table>
                                <tr>
                                    <div>
                                    <span class="status_label">Apply Tax</span>
                                </div>
                                    
                                </tr>
                                <tr>
                                    <td>
                                        <label class="mdl-checkbox mdl-js-checkbox"(change)="check($event)" for="checkbox1">
                                            <input type="checkbox" id="checkbox1"  [value]="'yes'" formControlName="tax" class="mdl-checkbox__input">
                                            <span class="mdl-checkbox__label yes_cls">yes</span>
                                        </label>
                                    </td>
                                    <td>
                                        <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" 
                                            for="checkbox2">
                                            <input type="checkbox" id="checkbox2" [value]="'no'" formControlName="tax" class="mdl-checkbox__input">
                                            <span class="mdl-checkbox__label">No</span>
                                        </label>
                                    </td>
                                    
                                </tr>
                            </table>
                        
                    </div> -->

                                <div class="col-lg-3 p-t-20">

                                    <div>
                                        <span class="status_label">Apply Tax<span style="color:red">*</span></span>
                                    </div>
                                    <div class="radio_button" id="inline_content">
                                        <!-- <label  class="taxyes mdl-radio mdl-js-radio" for="option11"> -->
                                        <input type="radio" id="option11" name="tax" class="mdl-radio__button" value="1"
                                            formControlName="tax" (change)="tax($event)">
                                        <span class="mdl-radio__label active_cls">Yes</span>
                                        <!-- </label> -->

                                        <!-- <label class="taxno mdl-radio mdl-js-radio mdl-js-ripple-effect"
                                    for="option22"> -->
                                        <input type="radio" id="option22" value="0" name="tax" formControlName="tax"
                                            class="mdl-radio__button" (change)="tax($event)">
                                        <span class="mdl-radio__label">No</span>
                                        <!-- </label> -->

                                    </div>
                                    <div>
                                        <div *ngIf="(f.tax.touched || submitted) && f.tax.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.tax.errors?.required">Tax is required</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-3 p-t-20" id="drop">


                                    <div
                                        class="drop mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                        <label class="mdl-textfield__label" for="thing1">Percentage<span
                                                style="color:red">*</span></label>
                                        <select class="mdl-textfield__input" id="thing1" name="thing1"
                                            formControlName="percentage" (change)="changetax($event)">

                                            <option *ngFor="let user of tax$;let i=index" value="{{user._id}}">
                                                {{user.name}}</option>

                                        </select>
                                    </div>
                                    <div class="invalid">
                                        <div *ngIf="(f.percentage.touched || submitted) && f.percentage.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.percentage.errors?.required">Percentage is required</div>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-3 p-t-20" id="drop" *ngIf="taxtype">
                                    <div
                                        class="drop mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                        <label class="mdl-textfield__label" for="thing1">Tax Type<span
                                                style="color:red">*</span></label>
                                        <select class="mdl-textfield__input" id="thing1" name="thing1"
                                            formControlName="type">

                                            <option *ngFor="let user of type$;let i=index" value="{{user._id}}">
                                                {{user.name}}</option>

                                        </select>
                                    </div>
                                    <div class="invalid">
                                        <div *ngIf="(f.type.touched || submitted) && f.type.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.type.errors?.required">Tax Type is required</div>
                                        </div>
                                    </div>

                                </div>


                            </div>


                            <div class="col-lg-12 p-t-20 text-center submit_cls">
                                <button type="submit"
                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-info">
                                    <div *ngIf="cancelButton;then update else add">here is ignored</div>
                                    <ng-template #add>Submit</ng-template>
                                    <ng-template #update>Update</ng-template>
                                </button>


                                <button type="button" *ngIf="cancelButton" (click)="cancelSubmit($event)"
                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <!-- <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="card  card-box">
                    <div class="card-head">
                        <header>All Room Type</header>
                        <div class="tools">
                            <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                            <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                            <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                        </div>
                    </div>
                    <div class="card-body ">
                        <div class="table-wrap">
                            <div class="table-responsive tblHeightSet small-slimscroll-style">
                                <table class="table display product-overview mb-30" id="support_table5">
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Room Number</th>
                                            <th>Room Type</th>
                                            <th>Floor Name</th>
                                            <th>Price</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>01000</td>
                                            <td>Non Ac</td>
                                            <td>Non Ac</td>
                                            <td>500</td>
                                            <td>
                                                <a href="#" class="btn btn-tbl-edit btn-xs">
                                                    <i class="fa fa-pencil"></i>
                                                </a>
                                                <button class="btn btn-tbl-delete btn-xs">
                                                    <i class="fa fa-trash-o "></i>
                                                </button>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>2</td>
                                            <td>01001</td>
                                            <td>Super Delux</td>
                                            <td>Super Delux</td>
                                            <td>500</td>
                                            <td>
                                                <a href="#" class="btn btn-tbl-edit btn-xs">
                                                    <i class="fa fa-pencil"></i>
                                                </a>
                                                <button class="btn btn-tbl-delete btn-xs">
                                                    <i class="fa fa-trash-o "></i>
                                                </button>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>3</td>
                                            <td>01002</td>
                                            <td>Non Ac</td>
                                            <td>Non Ac</td>
                                            <td>500</td>
                                            <td>
                                                <a href="#" class="btn btn-tbl-edit btn-xs">
                                                    <i class="fa fa-pencil"></i>
                                                </a>
                                                <button class="btn btn-tbl-delete btn-xs">
                                                    <i class="fa fa-trash-o "></i>
                                                </button>
                                            </td>
                                        </tr>

                                        

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->



            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="card  card-box">
                        <div class="card-head">
                            <header>All Rooms</header>
                            <div class="tools">
                                <!-- <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a> -->
                                <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                                <!-- <a class="t-close btn-color fa fa-times" href="javascript:;"></a> -->
                            </div>
                        </div>
                        <div class="card-body tb-wrapper ">
                            <div class="table-wrap">
                                <div class="table-responsive tblHeightSet ">
                                    <table class="table display product-overview mb-30" id="support_table5" datatable
                                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                        <thead>
                                            <tr>
                                                <th>Sl No</th>
                                                <th>Room Number</th>
                                                <th>Room Type</th>
                                                <th>Floor Name</th>
                                                <th>Price</th>
                                                <th>Apply Tax</th>
                                                <th>Tax Name</th>
                                                <th>Percentage</th>
                                                <th>Tax Type</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let user of rooms$; let i = index">
                                                <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                                <td *ngIf="user.room_number;else  room_number"> {{ user.room_number}}
                                                </td>
                                                <ng-template #room_number>
                                                    <td>-</td>
                                                </ng-template>
                                                <td *ngIf="user.rooms?.length > 0;else  rooms">
                                                    {{user.rooms[0].room_type}}
                                                </td>
                                                <ng-template #rooms>
                                                    <td>-</td>
                                                </ng-template>
                                                <td *ngIf="user.floors?.length > 0;else  rooms">
                                                    {{user.floors[0].name}}
                                                </td>
                                                <ng-template #rooms>
                                                    <td>-</td>
                                                </ng-template>
                                                <td *ngIf="user.rooms?.length > 0;else  price">
                                                    {{user.rooms[0].price}}
                                                </td>
                                                <ng-template #price>
                                                    <td>-</td>
                                                </ng-template>
                                                <td *ngIf="user.tax;else  tax">
                                                    <span *ngIf="user.tax == 1"> Yes</span>
                                                    <span *ngIf="user.tax == 0"> No</span>
                                                </td>
                                                <ng-template #tax>
                                                    <td>-</td>
                                                </ng-template>
                                                <td *ngIf="user.tax == 1 &&user.taxs.length > 0 ;else  taxs">
                                                    {{user.taxs[0].name}}
                                                </td>
                                                <ng-template #taxs>
                                                    <td>-</td>
                                                </ng-template>
                                                <td *ngIf="user.tax == 1&&user.taxs.length > 0;else  percentage">
                                                    {{user.taxs[0].percentage}}
                                                </td>
                                                <ng-template #percentage>
                                                    <td>-</td>
                                                </ng-template>
                                                <td *ngIf="user.tax == 1&&user.taxs.length > 0;else  taxtype">
                                                    {{taxtypename(user.taxtype)}}
                                                </td>
                                                <ng-template #taxtype>
                                                    <td>-</td>
                                                </ng-template>

                                                <td *ngIf="user.isActive;else  isActive">
                                                    <span *ngIf="user.isActive == 1"> Active</span>
                                                    <span *ngIf="user.isActive == 0"> Inactive</span>
                                                </td>
                                                <ng-template #isActive>
                                                    <td>-</td>
                                                </ng-template>
                                                <td>
                                                    <a (click)="userEdit($event, user, i)" href="javascript:void(0);"
                                                        class="btn btn-tbl-edit btn-xs">
                                                        <i class="fa fa-pencil"></i>
                                                    </a>
                                                    <button class="btn btn-tbl-delete btn-xs"
                                                        (click)="userDelete($event, user, i)">

                                                        <i class="fa fa-trash-o "></i>
                                                    </button>
                                                </td>
                                            </tr>



                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>







<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>