import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
  FormArray,
  FormControl,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService, ModalService, RoomService } from "../_services";
import { FloorService } from "../_services/floor.service";
import { ImageCroppedEvent } from "ngx-image-cropper";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { valHooks } from "jquery";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import { Subject, Observable } from "rxjs";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { interval } from "rxjs";
import { environment } from "../../environments/environment";

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: "app-checkin",
  templateUrl: "./checkin.component.html",
  styleUrls: ["./checkin.component.css"],
})
export class CheckinComponent implements OnInit, AfterViewInit {
  checkinForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  mobile_no = "";
  rsrdate: any;
  rmcat: any;
  roomcat: any;
  $reservation: any;
  reservationId: any;
  roomId: any;
  rm_id: any;
  image: any;
  fileToUpload1: Blob;
  fileToUpload2: Blob;
  rmno_data: any;
  imageChangedEvent: any = "";
  roomnumber: any;
  image1: any;
  image2: any;
  image3: any;
  croppedImage: any = "";
  myFile: Blob;
  $rooms: any;
  roomDetails: any;
  userdetails$: any;
  roomprice: any;

  media_domain = `${environment.media_domain}`;
  id: any;
  price: any;
  checkindetails: any;
  reservation$: any;
  room_number: any;
  rm_type: any;
  room_type: any;
  advance: any;
  resdate: any;
  rmnumber: any;
  uniqueid: any;
  addrooms = true;
  idimage: any;
  idimage3: any;
  photoimage: any;
  isReadonly = false;
  arrival_date: any;
  arrival_time: any;
  RoomType$: any;
  departure_date: any;
  departure_time: any;

  Fileimage1: any;
  Fileimage2: any;
  Fileimage3: any;

  Filejunkimage1: any;
  Filejunkimage2: any;
  Filejunkimage3: any;

  junkimage1: any;
  junkimage2: any;
  junkimage3: any;

  captureimage1 = false;
  uploadimage1 = false;
  captureimage2 = false;
  uploadimage2 = false;
  captureimage3 = false;
  uploadimage3 = false;

  public showWebcam = true;
  public allowCameraSwitch = true;
  public allowCameraSwitch1 = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public videoOptions1: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;
  public webcamImage1: WebcamImage = null;
  public webcamImagephoto: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  private trigger1: Subject<void> = new Subject<void>();
  private trigger3: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();
  initOptionRows() {
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
    return this.formBuilder.group({
      roomtype: ["", [Validators.required]],
      roomnumber: ["", [Validators.required]],
      total: ["", [Validators.required]],
      discountamt: [""],

      rooms: [],
      roomprice: [],
    });
  }
  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private roomSer: RoomService,
    private FloorService: FloorService
  ) {}

  ngOnInit() {
    this.FloorService.getRoomType().subscribe((data) => {
      this.RoomType$ = data.data.roomTypes;
    });
    this.roomSer.getRoomsALL().subscribe((data) => {
      this.roomDetails = data.data.rooms;
    });

    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
    let today = new Date().toISOString().slice(0, 10);
    this.checkinForm = this.formBuilder.group({
      cdate: [today, [Validators.required]],
      checkoutdate: ["", [Validators.required]],
      phone: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12),
        ],
      ],
      name: [
        "",
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(nonWhitespaceRegExp),
        ],
      ],
      doctype: [""],
      docid: [
        "",
        [Validators.maxLength(50), Validators.pattern(nonWhitespaceRegExp)],
      ],
      gstid: [""],
      male: [""],
      female: [""],
      children: [""],
      chekintime: ["", [Validators.required]],
      checkouttime: ["", [Validators.required]],
      address: ["", [Validators.required, Validators.maxLength(500)]],
      city: ["", [Validators.pattern(nonWhitespaceRegExp)]],
      state: ["", [Validators.pattern(nonWhitespaceRegExp)]],
      country: ["", [Validators.pattern(nonWhitespaceRegExp)]],
      remark: [""],
      payment: [""],
      payid: [
        "",
        [Validators.maxLength(50), Validators.pattern(nonWhitespaceRegExp)],
      ],
      options: this.formBuilder.array([this.initOptionRows()]),
    });

    this.route.queryParams.subscribe((params) => {
      this.id = params["id"];
      this.rmno_data = params["room_no"];
      this.rsrdate = params["checkin_date"];
      if (this.rsrdate && this.rmno_data) {
        var cdate = this.formatDate(this.rsrdate);
        this.checkinForm.patchValue({
          cdate: cdate,
        });
        this.checkinForm
          .get("options")
          ["controls"][0].controls.roomnumber.setValue(this.rmno_data);
      }

      if (this.id) {
        this.roomSer.getSingleReservation(this.id).subscribe((data) => {
          this.checkindetails = data.data.rooms;
          (this.uniqueid =
            this.checkindetails && this.checkindetails[0].uniqueId
              ? this.checkindetails[0].uniqueId
              : ""),
            this.checkinForm.patchValue({
              checkoutdate:
                this.checkindetails && this.checkindetails[0].departure_date
                  ? this.checkindetails[0].departure_date
                  : "",
              phone:
                this.checkindetails && this.checkindetails[0].phone
                  ? this.checkindetails[0].phone
                  : "",
              name:
                this.checkindetails && this.checkindetails[0].name
                  ? this.checkindetails[0].name
                  : "",
              male:
                this.checkindetails && this.checkindetails[0].male
                  ? this.checkindetails[0].male
                  : "",
              female:
                this.checkindetails && this.checkindetails[0].female
                  ? this.checkindetails[0].female
                  : "",
              children:
                this.checkindetails && this.checkindetails[0].children
                  ? this.checkindetails[0].children
                  : "",
              chekintime:
                this.checkindetails && this.checkindetails[0].arrival_time
                  ? this.checkindetails[0].arrival_time
                  : "",
              checkouttime:
                this.checkindetails && this.checkindetails[0].departure_time
                  ? this.checkindetails[0].departure_time
                  : "",
              address:
                this.checkindetails && this.checkindetails[0].address
                  ? this.checkindetails[0].address
                  : "",
              city:
                this.checkindetails && this.checkindetails[0].city
                  ? this.checkindetails[0].city
                  : "",
              state:
                this.checkindetails && this.checkindetails[0].state
                  ? this.checkindetails[0].state
                  : "",
              country:
                this.checkindetails && this.checkindetails[0].country
                  ? this.checkindetails[0].country
                  : "",
              remark:
                this.checkindetails && this.checkindetails[0].remark
                  ? this.checkindetails[0].remark
                  : "",
            });

          this.arrival_date = this.checkindetails[0].arrival_date;
          this.arrival_time = this.checkindetails[0].arrival_time;
          this.departure_date = this.checkindetails[0].departure_date;
          this.departure_time = this.checkindetails[0].departure_time;

          this.room_number =
            this.checkindetails &&
            this.checkindetails[0].rooms &&
            this.checkindetails[0].rooms &&
            this.checkindetails[0].rooms[0]._id
              ? this.checkindetails[0].rooms[0]._id
              : "";
          this.room_type =
            this.checkindetails &&
            this.checkindetails[0].rooms_category &&
            this.checkindetails[0].rooms_category &&
            this.checkindetails[0].rooms_category[0]._id
              ? this.checkindetails[0].rooms_category[0]._id
              : "";
          this.checkinForm
            .get("options")
            ["controls"][0].controls.roomtype.setValue(this.room_type);
          this.checkinForm
            .get("options")
            ["controls"][0].controls.roomnumber.setValue(this.room_number);
          this.checkinForm
            .get("options")
            ["controls"][0].controls.total.setValue(
              this.checkindetails[0].advance
            );
          this.checkinForm
            .get("options")
            ["controls"][0].controls.roomprice.setValue(
              this.checkindetails &&
                this.checkindetails[0].rooms_category &&
                this.checkindetails[0].rooms_category &&
                this.checkindetails[0].rooms_category[0].price
                ? this.checkindetails[0].rooms_category[0].price
                : ""
            );

          this.roomSer
            .getUserDetails(this.checkindetails[0].phone)
            .subscribe((data) => {
              this.userdetails$ = data.data;
              this.image1 =
                this.userdetails$ &&
                this.userdetails$[0] &&
                this.userdetails$[0].photo_image
                  ? this.userdetails$[0].photo_image
                  : "";
              this.image2 =
                this.userdetails$ &&
                this.userdetails$[0] &&
                this.userdetails$[0].id_image
                  ? this.userdetails$[0].id_image
                  : "";
              this.image3 =
                this.userdetails$ &&
                this.userdetails$[0] &&
                this.userdetails$[0].idimage3
                  ? this.userdetails$[0].idimage3
                  : "";
              this.photoimage = this.image1
                ? this.media_domain +this.image1
                : "";
              this.idimage = this.image2
                ? this.media_domain +this.image2
                : "";
              this.idimage3 = this.image3
                ? this.media_domain + this.image3
                : "";

              this.Fileimage1 = this.image1;
              this.Fileimage2 = this.image2;
              this.Fileimage3 = this.image3;
            });
        });
      }
    });

    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );
  }
  public triggerSnapshot(): void {
    this.trigger.next();
    $("#exampleModalCenter2").modal("hide");
  }
  public triggerSnapshot3(): void {
    this.trigger3.next();
    $("#exampleModalCenter3").modal("hide");
  }
  public triggerSnapshot1(): void {
    this.trigger1.next();
    $("#exampleModalCenter1").modal("hide");
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }
  public handleInitError1(error: WebcamInitError): void {
    this.errors.push(error);
  }
  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }
  public handleImagep(webcamImagephoto: WebcamImage): void {
    console.info("received webcam image", webcamImagephoto);
    this.webcamImagephoto = webcamImagephoto;
    // //console.log(this.webcamImage.imageAsDataUrl)
    const arr = this.webcamImagephoto.imageAsDataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File([u8arr], "name.jpeg", { type: "image/jpeg" });
    //console.log(file);

    this.photoimage = this.webcamImagephoto.imageAsDataUrl;
    this.Fileimage1 = file;
    //console.log(this.photoimage)
    //console.log(this.webcamImagephoto)
  }
  public handleImage(webcamImage: WebcamImage): void {
    console.info("received webcam image", webcamImage);
    this.webcamImage = webcamImage;
    // //console.log(this.webcamImage.imageAsDataUrl)
    const arr = this.webcamImage.imageAsDataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File([u8arr], "name.jpeg", { type: "image/jpeg" });
    //console.log(file);
    this.idimage = this.webcamImage.imageAsDataUrl;
    this.Fileimage2 = file;
    //console.log(this.idimage)
  }
  public handleImage1(webcamImage1: WebcamImage): void {
    console.info("received webcam image", webcamImage1);
    this.webcamImage1 = webcamImage1;
    // //console.log(this.webcamImage.imageAsDataUrl)
    const arr = this.webcamImage1.imageAsDataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File([u8arr], "name.jpeg", { type: "image/jpeg" });
    //console.log(file);
    this.Fileimage3 = file;
    this.idimage3 = this.webcamImage1.imageAsDataUrl;

    //console.log(this.idimage3)
  }

  public cameraWasSwitched(deviceId: string): void {
    //console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }
  public cameraWasSwitched1(deviceId: string): void {
    //console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  public get triggerObservable1(): Observable<void> {
    return this.trigger1.asObservable();
  }
  public get triggerObservable3(): Observable<void> {
    return this.trigger3.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  public get nextWebcamObservable1(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public get nextWebcamObservable3(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  uploadCropImage1(e) {
    // this.loading = true;
    $("#exampleModalCenter1").modal("hide");
    // //console.error(this.myFile)
    var rand = Math.random().toString(36).substring(7);
    this.image1 = null;
    this.image1 = this.croppedImage;
    //console.log(this.image1)
    // var file=new File([this.myFile],rand+'.png')
    var file = this.myFile;
    var that = this;
    //console.log(file);

    that.fileToUpload1 = file;
    $("#fileinput1").val("");
  }

  setSelectedTime1(e) {}

  setSelectedTime(e) {}
  uploadCropImage2(e) {
    // this.loading = true;
    $("#exampleModalCenter2").modal("hide");
    // //console.error(this.myFile)
    var rand = Math.random().toString(36).substring(7);
    this.image2 = null;
    this.image2 = this.croppedImage;
    //console.log(this.image1)
    // var file=new File([this.myFile],rand+'.png')
    var file = this.myFile;
    var that = this;
    //console.log(file);

    that.fileToUpload2 = file;
    $("#fileinput2").val("");
  }

  roomCat(e, i, form) {
    var date = this.f.cdate.value;
    this.roomSer.getroomCategory(e.target.value, date).subscribe((data) => {
      this.roomprice = data.category.price;
      var array = [];
      data.data.map((val) => {
        array.push(val);
      });
      this.checkinForm
        .get("options")
        ["controls"][i].controls.roomprice.setValue(data.category.price);
    });
  }

  add_activity() {
    const control = <FormArray>this.checkinForm.controls.options;
    control.push(this.initOptionRows());
  }

  remove(i) {
    const arrayControl = <FormArray>this.checkinForm.controls.options;
    arrayControl.removeAt(i);
  }

  rumNum(e) {
    this.roomSer.getroomData(e.target.value).subscribe((data) => {
      this.$rooms = data.data;
      //console.log(this.$rooms)
    });
  }

  fileChangeEvent1(event: any): void {
    //console.log(event.target.files[0].size);
    if (event.target.files[0].size > 2000000) {
      this.toastr.error("Maxmium 2MB image is allowed");
      return;
    }
    this.imageChangedEvent = event;

    $("#exampleModalCenter1").modal("show");
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    //console.log(event)
    // this.myFile = this.dataURItoBlob(this.croppedImage);
    this.myFile = this.croppedImage;

    //console.log(this.myFile);
  }

  fileChangeEvent2(event: any): void {
    //console.log(event.target.files[0].size);
    if (event.target.files[0].size > 2000000) {
      this.toastr.error("Maxmium 2MB image is allowed");
      return;
    }
    this.imageChangedEvent = event;

    $("#exampleModalCenter2").modal("show");
  }

  fileChangeEvent3(event: any): void {
    //console.log(event.target.files[0].size);
    if (event.target.files[0].size > 2000000) {
      this.toastr.error("Maxmium 2MB image is allowed");
      return;
    }
    this.imageChangedEvent = event;

    $("#exampleModalCenter3").modal("show");
  }

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: "image/jpg",
    });
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  closeModal1(e) {
    $("#exampleModalCenter1").modal("hide");
    $('[type="file"]').val("");
  }

  closeModal2(e) {
    $("#exampleModalCenter2").modal("hide");
    $('[type="file"]').val("");
  }
  closeModal3(e) {
    $("#exampleModalCenter3").modal("hide");
    $('[type="file"]').val("");
  }

  cancel() {
    this.router.navigateByUrl("/front-desk");
  }
  valuechange(e) {
    this.roomSer.getUserDetails(e.target.value).subscribe((data) => {
      //console.log(data)

      this.userdetails$ = data.data;
      //console.log(this.userdetails$)

      this.checkinForm.patchValue({
        name:
          this.userdetails$ && this.userdetails$[0] && this.userdetails$[0].name
            ? this.userdetails$[0].name
            : "",
        doctype:
          this.userdetails$ &&
          this.userdetails$[0] &&
          this.userdetails$[0].document_type
            ? this.userdetails$[0].document_type
            : "",
        docid:
          this.userdetails$ &&
          this.userdetails$[0] &&
          this.userdetails$[0].document_id
            ? this.userdetails$[0].document_id
            : "",
        gstid:
          this.userdetails$ &&
          this.userdetails$[0] &&
          this.userdetails$[0].gstid
            ? this.userdetails$[0].gstid
            : "",

        address:
          this.userdetails$ &&
          this.userdetails$[0] &&
          this.userdetails$[0].address
            ? this.userdetails$[0].address
            : "",
        city:
          this.userdetails$ && this.userdetails$[0] && this.userdetails$[0].city
            ? this.userdetails$[0].city
            : "",
        state:
          this.userdetails$ &&
          this.userdetails$[0] &&
          this.userdetails$[0].state
            ? this.userdetails$[0].state
            : "",
        country:
          this.userdetails$ &&
          this.userdetails$[0] &&
          this.userdetails$[0].country
            ? this.userdetails$[0].country
            : "",

        // options:
      });

      this.image1 =
        this.userdetails$ &&
        this.userdetails$[0] &&
        this.userdetails$[0].photo_image
          ? this.userdetails$[0].photo_image
          : "";
      this.image2 =
        this.userdetails$ &&
        this.userdetails$[0] &&
        this.userdetails$[0].id_image
          ? this.userdetails$[0].id_image
          : "";
      this.image3 =
        this.userdetails$ &&
        this.userdetails$[0] &&
        this.userdetails$[0].idimage3
          ? this.userdetails$[0].idimage3
          : "";

      if (!this.photoimage || !this.Fileimage1) {
        this.photoimage = this.image1
          ? this.media_domain+ this.image1
          : "";
        this.Fileimage1 = this.image1;
      }

      if (!this.idimage || !this.Fileimage2) {
        this.idimage = this.image2
          ? this.media_domain + this.image2
          : "";
        this.Fileimage2 = this.image2;
      }

      if (!this.idimage3 || !this.Fileimage3) {
        this.idimage3 = this.image3
          ? this.media_domain  + this.image3
          : "";
        this.Fileimage3 = this.image3;
      }
    });
  }

  get f() {
    return this.checkinForm.controls;
  }

  onSubmit() {
    var isValid = true;
    this.submitted = true;
    var dt1 = new Date(
      this.f.cdate.value + " " + this.convertTime12to24(this.f.chekintime.value)
    );
    var dt2 = new Date(
      this.f.checkoutdate.value +
        " " +
        this.convertTime12to24(this.f.checkouttime.value)
    );
    if (this.checkinForm.invalid) {
      this.toastr.error("Please enter all fields");
      return false;
    }
    if (dt1.getTime() === dt2.getTime()) {
      this.toastr.error(
        "Invalid Time !!!Please enter valid checkout time.it must greater than checkin time"
      );
      return false;
    }

    if (dt1.getTime() > dt2.getTime()) {
      this.toastr.error(
        "Invalid Time !!!Please enter valid checkout time.it must greater than checkin time"
      );
      return false;
    }
    if (
      this.f.male.value == 0 &&
      this.f.female.value == 0 &&
      this.f.children.value == 0
    ) {
      var that = this;
      that.toastr.error(
        "Please Enter male/female/children count shouldnt be zero"
      );
      return false;
    }

    var optionarray = [];
    this.checkinForm.value.options.map((val) => {
      optionarray.push(val);
    });
    var arr = [];
    arr = optionarray
      .map((item) => item.roomnumber)
      .filter((value, index, self) => self.indexOf(value) !== index);
    if (arr.length) {
      this.toastr.error(
        "Same room number is added multiple times.Please check again!"
      );
      return false;
    }
    if (this.uniqueid) {
      Swal.fire({
        title: "Are you sure want to submit reservation to checkin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          var that = this;

          that.roomSer
            .reservationcheckin(
              that.f.cdate.value,
              that.f.phone.value,
              that.f.name.value,
              that.f.doctype.value,
              that.f.docid.value,
              that.f.male.value,
              that.f.female.value,
              that.f.children.value,

              that.f.address.value,
              that.f.city.value,
              that.f.state.value,
              that.f.country.value,

              that.f.remark.value,
              that.f.payment.value,
              that.f.payid.value,
              that.f.options.value,
              that.Fileimage1,
              that.Fileimage2,
              3,
              that.f.checkoutdate.value,
              that.uniqueid,
              that.arrival_date,
              that.arrival_time,
              that.departure_date,
              that.departure_time,
              that.f.chekintime.value,
              that.f.checkouttime.value,
              that.Fileimage3
            )
            .subscribe(
              (data) => {
                if (data.status.code == 0) {
                  that.submitted = false;
                  document.body.classList.remove("jw-modal-open");
                  that.loading = false;
                  that.toastr.success(data.status.message);
                  setTimeout(function () {
                    that.router.navigateByUrl("/front-desk");
                  }, 1000);
                } else {
                  document.body.classList.remove("jw-modal-open");
                  that.loading = false;
                  that.toastr.error(data.status.message);
                }
              },
              (error) => {
                that.toastr.error(error);
                document.body.classList.remove("jw-modal-open");
                that.loading = false;
              }
            );
        } else if (result.isDenied) {
          Swal.fire("Cancelled!");
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure want to submit checkin details?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          var that = this;

          that.roomSer
            .checkin(
              that.f.cdate.value,
              that.f.phone.value,
              that.f.name.value,
              that.f.doctype.value,
              that.f.docid.value,
              that.f.male.value,
              that.f.female.value,
              that.f.children.value,

              that.f.address.value,
              that.f.city.value,
              that.f.state.value,
              that.f.country.value,

              that.f.remark.value,
              that.f.payment.value,
              that.f.payid.value,
              that.f.options.value,
              that.Fileimage1,
              that.Fileimage2,
              3,
              that.f.checkoutdate.value,
              that.f.chekintime.value,
              that.f.checkouttime.value,
              that.f.gstid.value,
              that.Fileimage3
            )
            .subscribe(
              (data) => {
                if (data.status.code == 0) {
                  that.submitted = false;
                  document.body.classList.remove("jw-modal-open");
                  that.loading = false;
                  that.toastr.success(data.status.message);
                  setTimeout(function () {
                    that.router.navigateByUrl("/front-desk");
                  }, 1000);
                } else {
                  document.body.classList.remove("jw-modal-open");
                  that.loading = false;
                  that.toastr.error(data.status.message);
                }
              },
              (error) => {
                that.toastr.error(error);
                document.body.classList.remove("jw-modal-open");
                that.loading = false;
              }
            );
        } else if (result.isDenied) {
          Swal.fire("Cancelled!");
        }
      });
    }
  }
  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };

  diff_hours(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  }
  onChange(e) {
    // alert(e.target.value)
  }
  onChange1(e) {
    if (e.target.value == "cash") {
      $("#cash").hide();
      this.checkinForm.controls["payid"].disable();
    } else {
      $("#cash").show();
      this.checkinForm.controls["payid"].enable();
    }
  }
  uploadImage1() {
    // $('#fileinput1').trigger('click');
    $("#exampleModalCenter1").modal("show");
    this.captureimage1 = false;
    this.uploadimage1 = false;
  }

  uploadImage2() {
    // $('#fileinput2').trigger('click');
    $("#exampleModalCenter2").modal("show");
    this.captureimage2 = false;
    this.uploadimage2 = false;
  }

  uploadImage3() {
    // $('#fileinput2').trigger('click');
    $("#exampleModalCenter3").modal("show");
    this.captureimage3 = false;
    this.uploadimage3 = false;
  }
  handleFileInput1(event: any, files: FileList) {
    var that = this;
    var reader = new FileReader();

    let allowedExtension = ["image/jpeg", "image/jpg", "image/png"];

    let type = files.item(0).type;

    if (allowedExtension.indexOf(type) > -1) {
    } else {
      $('[type="file"]').val("");
      that.toastr.error(
        "Invalid Image formate,please upload valid image file"
      );
      return false;
    }



    reader.onload = function (e) {
      var img = new Image();
      img.onload = function () {
        that.junkimage1 = img.src;
        that.Filejunkimage1 = files.item(0);
      };
      img.src = reader.result as string;

      that.Filejunkimage1 = files.item(0);
    };

    reader.readAsDataURL(files.item(0));
    that.Filejunkimage1 = files.item(0);
  }

  handleFileInput2(event: any, files: FileList) {
    var that = this;
    var reader = new FileReader();
    let allowedExtension = ["image/jpeg", "image/jpg", "image/png"];

    let type = files.item(0).type;

    if (allowedExtension.indexOf(type) > -1) {
    } else {
      $('[type="file"]').val("");
      that.toastr.error(
        "Invalid Image formate,please upload valid image file"
      );
      return false;
    }


    reader.onload = function (e) {
      var img = new Image();
      img.onload = function () {
        //console.log(img.width)

        that.junkimage2 = img.src;
        that.Filejunkimage2 = files.item(0);
      };
      img.src = reader.result as string;

      that.Filejunkimage2 = files.item(0);
    };

    reader.readAsDataURL(files.item(0));
    that.Filejunkimage2 = files.item(0);
    //console.log(that.fileToUpload2)
  }

  handleFileInput3(event: any, files: FileList) {
    var that = this;
    var reader = new FileReader();
    let allowedExtension = ["image/jpeg", "image/jpg", "image/png"];

    let type = files.item(0).type;

    if (allowedExtension.indexOf(type) > -1) {
    } else {
      $('[type="file"]').val("");
      that.toastr.error(
        "Invalid Image formate,please upload valid image file"
      );
      return false;
    }


    reader.onload = function (e) {
      var img = new Image();
      img.onload = function () {
        that.junkimage3 = img.src;
        that.Filejunkimage3 = files.item(0);
      };
      img.src = reader.result as string;

      that.Filejunkimage3 = files.item(0);
    };

    reader.readAsDataURL(files.item(0));
    that.Filejunkimage3 = files.item(0);
  }
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  ngAfterViewInit() {
    // var d = new Date("Fri Jan 31 2014 00:00:00 GMT-0800 (Pacific Standard Time)");
    // var str = $.datepicker.formatDate('yy-mm-dd', d);
    // alert(str);

    $(document).on("focus", "input,select,textarea", function (e) {
      var cur = $(this);
      $("input,select,textarea").parent().removeClass("is-focused");
      cur.parent()[0].classList.remove("is-focused");

      // cur.parent()[0].removeClass('is-focused')

      cur.parent()[0].classList.add("is-focused");
    });
    var that = this;
    if (!that.rsrdate) {
      let today = new Date().toISOString().slice(0, 10);
      document.getElementById("checkoutdate").setAttribute("min", today);
    } else {
      var cdate = that.formatDate(that.rsrdate);
      document.getElementById("checkoutdate").setAttribute("min", cdate);
    }
  }
  vewBtn1(e) {
    e.stopPropagation();
    $("#image1show").modal("show");
    return false;
  }
  vewBtn2(e) {
    e.stopPropagation();
    $("#image2show").modal("show");
    return false;
  }
  vewBtn3(e) {
    e.stopPropagation();
    $("#image3show").modal("show");
    return false;
  }
  closeModal11(e) {
    e.stopPropagation();
    $("#image1show").modal("hide");
  }
  closeModal22(e) {
    e.stopPropagation();
    $("#image2show").modal("hide");
  }
  closeModal33(e) {
    e.stopPropagation();
    $("#image3show").modal("hide");
  }

  uploadcaptureimage1(e) {
    if (e == 1) {
      this.captureimage1 = true;
      this.uploadimage1 = false;
    } else {
      this.captureimage1 = false;
      this.uploadimage1 = true;
    }
  }
  uploadcaptureimage2(e) {
    if (e == 1) {
      this.captureimage2 = true;
      this.uploadimage2 = false;
    } else {
      this.captureimage2 = false;
      this.uploadimage2 = true;
    }
  }
  uploadcaptureimage3(e) {
    if (e == 1) {
      this.captureimage3 = true;
      this.uploadimage3 = false;
    } else {
      this.captureimage3 = false;
      this.uploadimage3 = true;
    }
  }

  uploadfinalimage1() {
    $("#exampleModalCenter1").modal("hide");
    $('[type="file"]').val("");
    this.photoimage = this.junkimage1;
    this.Fileimage1 = this.Filejunkimage1;
  }

  uploadfinalimage2() {
    $("#exampleModalCenter2").modal("hide");
    $('[type="file"]').val("");
    this.idimage = this.junkimage2;
    this.Fileimage2 = this.Filejunkimage2;
  }

  uploadfinalimage3() {
    $("#exampleModalCenter3").modal("hide");
    $('[type="file"]').val("");
    this.idimage3 = this.junkimage3;
    this.Fileimage3 = this.Filejunkimage3;
  }
}
