import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class GroundAdminService {
      constructor(private http: HttpClient) { }

      sendotp(country_code: string, mobile_no: string, email: string) {
            return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/ground-vendor/send-otp`, { country_code: country_code, mobile_no: mobile_no, email: email, verify_flag: 0 }).pipe(map(data => {
                  return data;
            }));
      }

      submitOTP(country_code: string, mobile_no: string, otp: string) {
            return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/ground-vendor/verify-otp`, { country_code: country_code, mobile_no: mobile_no, otp: otp, verify_flag: 1 }).pipe(map(data => {
                  return data;
            }));
      }

      register(verify_code: string, country_code: string, mobile_no: string, email: string, name: string, company_name: string, address: string, password: string) {
            return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/ground-vendor/register`, {
                  verify_code: verify_code,
                  country_code: country_code,
                  mobile_no: mobile_no,
                  email: email,
                  name: name,
                  company_name: company_name,
                  address: address,
                  password: password
            }).pipe(map(data => {

                  if (data.status.code == 0) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem(`${environment.appName}` + 'vendor_user', data.data.session.access_token);
                        localStorage.setItem(`${environment.appName}` + '_adminName', data.data.user.name);
                        localStorage.setItem(`${environment.appName}` + '_adminId', data.data.user._id);
                        localStorage.setItem(`${environment.appName}` + '_role', data.data.user.roleId);
                        localStorage.setItem(`${environment.appName}` + '_status', data.data.user.status);
                  }

                  return data;
            }));


      }

      login(country_code: string, mobile_no: string, password: string) {

            return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/ground-vendor/login`, { country_code: country_code, mobile_no: mobile_no, password: password }).pipe(map(data => {
                  // login successful if there's a jwt token in the response
                  if (data.status.code == 0) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem(`${environment.appName}` + 'vendor_user', data.data.session.access_token);
                        localStorage.setItem(`${environment.appName}` + '_adminName', data.data.user.name);
                        localStorage.setItem(`${environment.appName}` + '_adminId', data.data.user._id);
                        localStorage.setItem(`${environment.appName}` + '_role', data.data.user.roleId);
                        localStorage.setItem(`${environment.appName}` + '_status', data.data.user.status);
                  }

                  return data;
            }));

      }

      logout() {
            // remove user from local storage to log user out
            localStorage.removeItem(`${environment.appName}` + '_user');
            localStorage.removeItem(`${environment.appName}` + 'vendor_user');
            localStorage.removeItem(`${environment.appName}` + '_adminId');
            localStorage.removeItem(`${environment.appName}` + '_adminName');
            localStorage.removeItem(`${environment.appName}` + '_role');
            localStorage.removeItem(`${environment.appName}` + '_status');
      }
}