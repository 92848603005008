import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray,FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {FloorService} from '../_services/floor.service';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import * as alertify from 'alertify.js';//import


declare var jquery: any;
declare var $: any;

declare var checked:any;
declare var alertify:any;

@Component({
  selector: 'app-floor-master',
  templateUrl: './floor-master.component.html',
  styleUrls: ['./floor-master.component.css'],
})
export class FloorMasterComponent implements OnInit {
  
  FloorForm: FormGroup;
	loading = false;
  submitted = false;
  radioCheck=null;
  floors$:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  curPage = 1;
  perPage = 10;
  status$ =true;
  cancelButton = false;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private FloorService:FloorService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
  	const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
  	this.FloorForm = this.formBuilder.group({
      index: null,
      _id: null,
  		name: ['',[Validators.required,Validators.maxLength(50),Validators.pattern(nonWhitespaceRegExp)]],
  		status:['',[Validators.required]]
    })
    
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: true,
        orderable: false,
        targets: [3],
        
      }],
      // order: [[ 2, 'asc' ]]
    };
    
    this.FloorService.getFloor().subscribe(data => {
      //console.log(data);
      this.floors$=data.data.floors;
      this.dtTrigger.next();
     
     
  });
  new Promise(resolve => {
    this.loadScript();
    this.loadStyle();

  });

  
  }

//   loadStyle1(src)
//   {
//   let link = document.createElement('link');
//   link.href = src;
//   link.rel = 'stylesheet';
//   link.type = 'text/css';
// let head = document.getElementsByTagName('head')[0];
//   let links = head.getElementsByTagName('link');
//   let style = head.getElementsByTagName('style')[0];
//   head.insertBefore(link, style);
//   }

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
  
      var dynamicScripts = [
     
    
       


     
       'assets/plugins/popper/popper.min.js',
       'assets/plugins/jquery-blockui/jquery.blockui.min.js',
       'assets/plugins/jquery-slimscroll/jquery.slimscroll.min.js',
    
       'assets/plugins/bootstrap/js/bootstrap.min.js',
       'assets/plugins/sparkline/jquery.sparkline.min.js',
       'assets/js/pages/sparkline/sparkline-data.js',
  
       'assets/js/app.js',
       'assets/js/layout.js',
       'assets/js/theme-color.js',
      
      // 'assets/plugins/material/material.min.js', 
      
       'assets/js/pages/ui/animations.js',
       'assets/js/pages/material_select/getmdl-select.js',
       'assets/plugins/flatpicker/flatpickr.min.js',
       'assets/js/pages/datetime/datetime-data.js',
       
       'assets/plugins/morris/morris.min.js',
       'assets/plugins/morris/raphael-min.js',
       'assets/js/pages/chart/morris/morris_home_data.js',
      
       'assets/plugins/material/material.min.js',
       'assets/js/pages/extra_pages/login.js',




      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
      }

    }


  }

  public loadStyle() {
    var isFound = false;
    var scripts = document.getElementsByTagName("link")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('href') != null && scripts[i].getAttribute('href').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
   
      var dynamicScripts = [
     
    
       

        "assets/plugins/material/material.min.css"
     




      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('link');
        node.href = dynamicScripts[i];
        node.type = 'text/css';
      
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }


  }
  loadCSS(url) {
    // Create link
    let link = document.createElement('link');
    link.href = url;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    
    let head = document.getElementsByTagName('head')[0];
    let links = head.getElementsByTagName('link');
    let style = head.getElementsByTagName('style')[0];
    
    // Check if the same style sheet has been loaded already.
    let isLoaded = false;  
    for (var i = 0; i < links.length; i++) {
      var node = links[i];
      if (node.href.indexOf(link.href) > -1) {
        isLoaded = true;
      }
    }
    if (isLoaded) return;
    head.insertBefore(link, style);
  }
  handleRadio(val,e){
    //console.log(e)
    this.radioCheck=val;
  }

  get f() { return this.FloorForm.controls; }
  onSubmit(form, formWrap) {
    
    this.submitted = true

  	let id = form.getRawValue()._id;
    let index = form.getRawValue().index;
    const that = this;
    //console.log(this.FloorForm)
  
    if(this.FloorForm.invalid ){
      this.toastr.error('Please Enter All Fields');
      return false;
    }   

    // if(this.radioCheck == null ){
    //   this.toastr.error('Please Select Status Active/Deactive');
    //   return false;
    // }   

    if (id != null) {
      alertify.confirm('Update Floor','Are you sure want to update this record', function () {     
        // document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
        that.loading = true;
    
         that.FloorService.updateFloor(
             id,
            that.f.name.value,
            that.f.status.value
            
           
            )
          .subscribe(
            data => {
    
              if (data.status.code == 0) {
               
                that.toastr.success('Floor updated successfully');
              
                that.submitted = false;
                document.body.classList.remove('jw-modal-open');
          
    
              
                that.loading = false;
                // location.reload();
             
                $('.first').removeClass('is-checked');
                $('.second').removeClass('is-checked');
                $('.taxyes').removeClass(' is-checked');
                $('.taxno').removeClass('is-checked');
                $('.mdl-textfield').removeClass('is-focused');
                $('.mdl-textfield').removeClass('is-dirty');
                that.cancelButton = false;
                document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                that.FloorForm.setValue({
                  index: null,
                  _id: null,
                  name:'',
                  status:'',
                })
                that.FloorForm.controls['status'].reset();
                that.radioCheck=null;
                // $('[name="status"]').prop("checked",false);
           
                $("#support_table5").dataTable().fnDestroy();
                    that.FloorService.getFloor().subscribe(data => {
                      //console.log(data);
                      that.floors$=data.data.floors;
                      that.dtTrigger.next();
                    
                    
                  });
               
                formWrap.reset();
           
              } else {
                // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                // document.body.classList.remove('jw-modal-open');
                that.loading = false;
               that.toastr.error(data.status.message)
               
              }
            },
            error => {
              that.toastr.error(error)
              
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
            });
          }, function () { //alertify.error('Delete action cancelled')
          that.toastr.error('Create action cancelled')
        });
      
    } else {
 
 	 alertify.confirm('Create Floor','Are you sure want to create this record', function () {     
    // document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
    that.loading = true;

     that.FloorService.saveFloor(
        that.f.name.value,
        that.f.status.value
      	
       
        )
      .subscribe(
        data => {

          if (data.status.code == 0) {
           
            that.toastr.success('Floor added successfully');
          
            that.submitted = false;
            document.body.classList.remove('jw-modal-open');
      

          
            that.loading = false;
            // location.reload();
            $('.first').removeClass('is-checked');
            $('.second').removeClass('is-checked');
            that.FloorForm.setValue({
              index: null,
              _id: null,
              name:'',
              status:'',
            })
            that.FloorForm.controls['status'].reset();
            that.radioCheck=null;
            // $('[name="status"]').prop("checked",false);
       
            $("#support_table5").dataTable().fnDestroy();
                that.FloorService.getFloor().subscribe(data => {
                  //console.log(data);
                  that.floors$=data.data.floors;
                  that.dtTrigger.next();
                
                
              });
           
            formWrap.reset();
       
          } else {
            // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
            // document.body.classList.remove('jw-modal-open');
            that.loading = false;
           that.toastr.error(data.status.message)
           
          }
        },
        error => {
          that.toastr.error(error)
          
          document.body.classList.remove('jw-modal-open');
          that.loading = false;
        });
      }, function () { //alertify.error('Delete action cancelled')
      that.toastr.error('Create action cancelled')
    });
  }
  }
  home()
  {
    window.location.assign("/add-room-type");
  }
  cancelSubmit(event) {
    $('.first').removeClass('is-checked');
    $('.second').removeClass('is-checked');
    $('.taxyes').removeClass(' is-checked');
    $('.taxno').removeClass('is-checked');
    $('.mdl-textfield').removeClass('is-focused');
    $('.mdl-textfield').removeClass('is-dirty');
    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.FloorForm.setValue({
      index: null,
      _id: null,
      name:'',
      status:'',
    })

    this.submitted = false;
  }

  userEdit(event, user, i) {
    window.scroll(0, 0);
    //console.log(user.status)

    $('.first').removeClass('is-checked');
    $('.second').removeClass('is-checked');
    $('.taxyes').removeClass(' is-checked');
    $('.taxno').removeClass('is-checked');
    $("input:text:visible:first").focus();
    $('.mdl-textfield').addClass('is-focused is-dirty');
 
    this.status$= true
    this.cancelButton = true;
    //this.table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
    this.FloorForm.setValue({
      index: i,
      _id: user._id,
      name: user.name,
     
     status: user.status,

    })
    if(user.status == 1){
      $('.first').addClass('is-upgraded is-checked');
  }else if(user.status == 0){
    $('.second').addClass('is-upgraded is-checked');
  }
   
   
    // this.imageExist =user.image;
    // this.imageExist1=user.cover;
  }

  userDelete(event, user, i) {
    var that = this;
    alertify.confirm('Delete Floor','Are you sure want to delete this record', function () {
      that.FloorService.DeleteFloor(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success('Deleted Successfully')
              // alertify.success('Deleted Successfully')
              setTimeout(function () {
                $("#support_table5").dataTable().fnDestroy();
                that.FloorService.getFloor().subscribe(data => {
                  //console.log(data);
                  that.floors$=data.data.floors;
                  that.dtTrigger.next();
                
                
              });
              }, 1000);
              //that.users$.splice(i, 1);
            } else {
              that.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            that.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
          });
    }
      , function () { //alertify.error('Delete action cancelled')
       // that.toastr.error('Delete action cancelled')

      });

  }
  ngAfterViewInit(){
    $(document).on('focus','input,select,textarea',function(e){ 
      var cur = $(this);
      $('input,select,textarea').parent().removeClass('is-focused');
      cur.parent()[0].classList.remove('is-focused');
      
      // cur.parent()[0].removeClass('is-focused')
      
        cur.parent()[0].classList.add('is-focused')
       })
      }                                     
}
