
<site-header></site-header>
<div class="page-container">

</div>
<div class="page-content-wrapper">
<div class="page-content">

<div class="row">

    <div class="col-sm-12">
        <div class="page-bar">
    <div class="page-title-breadcrumb">
        <div class="card-head">
                <div class="page-title">Laundry Services</div>			
        </div>
    </div>
</div>
<form [formGroup]="checkinForm" role="form" (ngSubmit)="onSubmit(checkinForm, myForm)"  #myForm="ngForm">
        <div class="card-box">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-2 p-t-10" >
                        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                             <label class="mdl-textfield__label">Name</label>
                               <h6>{{name}}</h6> 
                        </div>
                        </div>
                        <div class="col-lg-2 p-t-10" >
                            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                 <label class="mdl-textfield__label">Phone</label>
                                   <h6>{{phone1}}</h6> 
                            </div>
                            </div>
                            <div class="col-lg-2 p-t-10" >
                                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                     <label class="mdl-textfield__label">Room Type</label>
                                       <h6>{{room_type}}</h6> 
                                </div>
                                </div>
                                <div class="col-lg-2 p-t-10" >
                                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                         <label class="mdl-textfield__label">Room Number</label>
                                           <h6>{{room_number}}</h6> 
                                    </div>
                                    </div>

                </div>
             

               <div  >
              
                
              <div class="row rrf">
               



           

                <div class="col-lg-2 p-t-10 ">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                        <label class="mdl-textfield__label" for="thing">Item name </label>
                        <select class="mdl-textfield__input" id="thing" name="thing" formControlName="laundry_name"  (change)="foodselect($event)">
                         <option value="0" disabled>Please Select Item</option>
                       
                          <option *ngFor="let user of item$;let i=index" value="{{user._id}}">{{user.name}}</option>
                         
                        </select>
            </div>
            <div class="invalid">
                <div *ngIf="(f.laundry_name.touched || submitted) && f.laundry_name.errors?.required"
                class="invalid-feedback">
                <div *ngIf="f.laundry_name.errors?.required">Item name is required</div>
            </div>
            </div>
                    
                    </div>



                <div class="col-lg-2 p-t-10">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <input class="mdl-textfield__input" type="text"  id="txtRoomNo" formControlName="quantity" (change)="countAmount($event)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                        <label class="mdl-textfield__label">Quantity</label>
                    </div>
                    <div class="invalid">
                        <div *ngIf="(f.quantity.touched || submitted) && f.quantity.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.quantity.errors?.required">Quantity required</div>
                    </div>
                    </div>
                    
                    </div>
                    
                                        <div class="col-lg-2 p-t-10">
                                            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="text"  id="txtRoomNo" formControlName="rate" readonly>
                                                <label class="mdl-textfield__label">Rate</label>
                                            </div>
                                        <div class="invalid">
                                            <div *ngIf="(f.rate.touched || submitted) && f.rate.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.rate.errors?.required">rate is required</div>
                                        </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 p-t-10">
                                        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                            <input class="mdl-textfield__input" type="text" id="txtRoomNo" formControlName="amount" readonly>
                                            <label class="mdl-textfield__label">Amount</label>
                    
                                        </div>
                                        <div class="invalid">
                                            <div *ngIf="(f.amount.touched || submitted) && f.amount.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.amount.errors?.required">Amount is required</div>
                                        </div>
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-2 p-t-10">
                                        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                            <input class="mdl-textfield__input" type="date" id="rdate"  name="delivery_date" formControlName="delivery_date" [ngClass]="{ 'is-invalid': (f.delivery_date.touched || submitted) && f.delivery_date.errors }" placeholder="Arival Date" autocomplete="off" >
                                            <label class="mdl-textfield__label">Delivery Date</label>
                                         </div>
                                            <div class="invalid">
                                             <div *ngIf="(f.delivery_date.touched || submitted) && f.delivery_date.errors?.required"
                                                       class="invalid-feedback">
                                                       <div *ngIf="f.delivery_date.errors?.required">Delivery date is required</div>
                                                   </div></div>
                                        </div>
                                        <div class="col-lg-2 p-t-10">
                                        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                            <!-- <input class="mdl-textfield__input" type="time" id="txtRoomNo" name="delivery_time" formControlName="delivery_time" [ngClass]="{ 'is-invalid': (f.delivery_time.touched || submitted) && f.delivery_time.errors }" placeholder="Arival Time" autocomplete="off"> -->
                        
                                             <input class="mdl-textfield__input" id="txtRoomNo" [ngxTimepicker]="picker1" formControlName="delivery_time" name="delivery_time"
                                                      [ngClass]="{ 'is-invalid': (f.delivery_time.touched || submitted) && f.delivery_time.errors }"
                                                    >
                                                   <ngx-material-timepicker #picker1 (timeChanged)="setSelectedTime1($event)">
                                                       <!-- [ngClass]="{'is-invalid':(f.start_time.touched || submitted) && f.start_time.errors?.required}" -->
                                                   </ngx-material-timepicker>
                                            <label class="mdl-textfield__label">Delivery Time</label>
                                         </div>
                                            <div class="invalid">
                                             <div *ngIf="(f.delivery_time.touched || submitted) && f.delivery_time.errors?.required"
                                                       class="invalid-feedback">
                                                       <div *ngIf="f.delivery_time.errors?.required">Delivery time is required</div>
                                                   </div></div>
                                        </div>    
               

            <!-- </div> --> <!-- col 8 close -->

            </div>  <!-- row close -->

            
           </div>  <!-- main class end -->

           <div class="row">
           
                <div class="col-lg-12 p-t-20 text-left">
                    <div class="btnsr">	
                    <button type="submit"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-primary">SUBMIT</button>
                    <button type="button" (click)="cancel()"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">CANCEL</button>
                </div>
            </div>
        </div>

              

              </div> <!-- card body close -->
           </div> 
           </form> <!-- card box end -->
        </div>  <!-- sm 12 -->
    </div>  <!-- row -->


<!-- table -->
 <div class="page-bar">
    <div class="page-title-breadcrumb">
        <div class="card-head">
                            
        </div>
    </div>
</div>






         <div class="tab">

              <div class="row">
    <div class="col-sm-12">
        <div class="card-box">
            <!-- <div class="card-head">
                <header>Normal table</header>
            </div> -->
            <div class="card-body ">
                <div class="card-body tb-wrapper ">
                    <div class="table-wrap">
                        <div class="table-responsive tblHeightSet ">
                <table class="table display product-overview mb-30" id="support_table5" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
                    <thead style="background-color: #167CE4; color: white;">
                        <tr>
                            <th>Sl No</th>
                            <th>Item Name</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                             <th>Total Amount</th>
                             <!-- <th>Transaction Date&Time</th> -->
                             <th>Delivery Date&Time</th>
                            <th >Action</th>
                            
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let user of laundryservice$;let i=index" >
                            <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                            <td *ngIf="user && user.laundry_name && user.laundry_name.name;else  name">{{user && user.laundry_name && user.laundry_name.name}}
                        </td>
                        <ng-template #name>
                            <td>-</td>
                        </ng-template>
                            <td *ngIf="user && user.quantity;else  quantity">
                                {{user.quantity}} </td>
                            <ng-template #quantity>
                                <td>-</td>
                            </ng-template>
                            <td *ngIf="user.laundry_name.price;else price">{{user && user.laundry_name && user.laundry_name.price}}
                            </td>
                            <ng-template #price>
                                <td>-</td>
                            </ng-template>
                            <td *ngIf="user.amount;else amount">{{user.amount}}</td>
                            <ng-template #amount>
                                <td>-</td>
                            </ng-template>
                            <!-- <td *ngIf="user.createdAt;else amount1">{{user.createdAt | date:'dd-MMM-yyyy h:mm a'}}</td>
                            <ng-template #amount1>
                                <td>-</td>
                            </ng-template> -->
                            <td *ngIf="user.delivery_date;else amount2">{{user.delivery_date | date:'dd-MMM-yyyy'}} - {{user.delivery_time}}</td>
                            <ng-template #amount2>
                                <td>-</td>
                            </ng-template>
                          
                            <td>                  <button class="btn btn-tbl-delete btn-xs" (click)="deleteExtraService($event, user, i)" >

                                <i class="fa fa-trash-o "></i>
                            </button>
                                </td>
                        </tr>
                    
                    </tbody>
                    <!-- <tbody *ngIf="laundryservice$.">
                      <span>  <h3 style="text-align: center;
    padding-top: 15px;
    padding-left: 485px;">No Extra service Found!</h3></span>
                    </tbody> -->
                </table>
             </div>

            <!-- BUTTONS -->
<!-- 
            <div class="row ml-2" >	
                <div class="col-lg-12 p-t-20 text-right">
                    <button type="button"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-primary">SUBMIT</button>
                    
                    

                    <button type="button"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">CANCEL</button>
                </div>
            </div> -->
            
            <!--  BTN END -->

                    </div>
                </div>
                </div>
            </div>
        </div>

    </div>

  

   </div>






</div> <!-- containers -->
</div>
