import { Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { AlertService, ModalService, RoomService } from '../_services';
import {HomeService} from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { DatePipe, formatDate, getLocaleDateTimeFormat } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2'; 
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-occupied-rooms-report',
  templateUrl: './occupied-rooms-report.component.html',
  styleUrls: ['./occupied-rooms-report.component.css']
})
export class OccupiedRoomsReportComponent implements OnInit {
  currentdate: any;
roomDate: any;
roomDetails: any;
latest_date: any;
checkindate: any;
room : any;
rmdate: any;
changedate: any;
room_unique_id:any;
reservationRoom$:any;
checkinRoom$:any;
roomDetails$:any;
reservename:any;
reservephone:any;
reservestatus:any;
checkinId:any;
makedate = false;
checkin = false;
submitted=false;
checkinData = [];
reservationId = [];
reserve_checkin=false;
chekinusername:any;
checkinphone:any;
checkindetails$:any;
checkinstatus:any;
checkinsid=[];
checkin_count:any;
reservation_count:any;
block_count:any;
maintanance_count:any;
checkindata=[];
  constructor(
    private roomSer: RoomService,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
        
      this.latest_date= params['date'];

    })
 

this.roomSer.getDashboardchk(this.latest_date).subscribe(data => {

  this.checkin_count=data.data.checkin_count;
  this.checkindata=data.data.checkindata;
  


});

  }
 


}
