import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SettingsService {
    constructor(private http: HttpClient) { }

    save(username: string, is_reset: string, password: string, new_pass: string,email:string) {
        const formData: FormData = new FormData();

        formData.append('name', username);
        formData.append('password', password);
        formData.append('username', email);
        
        if (new_pass) {
             //console.log(is_reset)
            formData.append('new_password', new_pass);
        }


        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/oauth/update-password`, formData).pipe(map(user => {
            return user;
        }));

    }
    get_user_details(){
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/get-user`).pipe(map(user => {
            return user;
        })); 
    }

    update_shipping_details(id:any,rate:any,amount:any,status:any,isFlat:any){
        const formData: FormData = new FormData();

        formData.append('rate', rate);
        formData.append('amount', amount);
        formData.append('charge_type', isFlat ? isFlat : 0);
        formData.append('isActive_ship', status);
       

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/shipping/`+id, formData).pipe(map(user => {
            return user;
        })); 
    }

    add_shipping_details(rate:any,amount:any,status:any,isFlat:any){
        const formData: FormData = new FormData();

        formData.append('rate', rate);
        formData.append('amount', amount);
        formData.append('charge_type', isFlat ? isFlat : 0);
        formData.append('isActive_ship', status);
       

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/shipping`, formData).pipe(map(user => {
            return user;
        })); 
    }
    get_shipping_details(){
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/shipping`).pipe(map(user => {
            return user;
        })); 
    }

    get_business_details(){
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/business`).pipe(map(user => {
            return user;
        })); 
    }
    update_whatsapp(id:string,number:any){
        const formData: FormData = new FormData();

        formData.append('number', number);
        
       

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/business/whatsapp_update/`+id, formData).pipe(map(user => {
            return user;
        }));  
    }
    save_business_details(
       
        business_name:any,
        business_description:any,
        business_subdomain:any,
        biz_page_language:any,
        country_code:any,
        address:any,
        country:any,
        fileToUpload:File,
        options:any,
        recieved_mobile:any){
        const formData: FormData = new FormData();

        formData.append('business_name', business_name);
        formData.append('business_description', business_description);
        formData.append('business_subdomain', business_subdomain);
        formData.append('biz_page_language', biz_page_language);
        formData.append('country_code', country_code);
        formData.append('address', address);
        formData.append('country', country);
        formData.append('business_logo',fileToUpload,fileToUpload.name);
        formData.append('options', JSON.stringify(options));
          formData.append('recieved_mobile',  recieved_mobile);
       
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/business/store`, formData).pipe(map(user => {
            return user;
        })); 
    }
    
    update_business_details(
        id:any,
        business_name:any,
        business_description:any,
        business_subdomain:any,
        biz_page_language:any,
        country_code:any,
        address:any,
        country:any,
        fileToUpload:File,
        options:any,
        recieved_mobile:any){
        const formData: FormData = new FormData();

        formData.append('business_name', business_name);
        formData.append('business_description', business_description);
        formData.append('business_subdomain', business_subdomain);
        formData.append('biz_page_language', biz_page_language);
        formData.append('country_code', country_code);
        formData.append('address', address);
        formData.append('country', country);
          formData.append('recieved_mobile',  recieved_mobile);
        if(fileToUpload){
            formData.append('business_logo',fileToUpload,fileToUpload.name);
        }
       //console.log(fileToUpload) 
        formData.append('options', JSON.stringify(options));
       

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/business/store`, formData).pipe(map(user => {
            return user;
        })); 
    }
    delete_number(id:string){
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/business/delete_number/`+id, ).pipe(map(user => {
            return user;
        }));  
    }

    update_status(id:string,status:any){
        const formData: FormData = new FormData();
        formData.append('status', status);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/business/update_status/`+id, formData).pipe(map(user => {
            return user;
        }));  
    }
    update_bank_details(
       
        name:any,
        bank_name:any,
        account_number:any,
        ifsc_code:any,
       ){
        const formData: FormData = new FormData();

        formData.append('name', name);
        formData.append('bank_name', bank_name);
        formData.append('account_number', account_number);
        formData.append('ifsc_code', ifsc_code);
        
       
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/business/bank_store`, formData).pipe(map(user => {
            return user;
        })); 
    }
    get_bank_details(){
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/business/bank_details`).pipe(map(user => {
            return user;
        })); 
    }

    get_shop_details(){
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/business`).pipe(map(user => {
            return user;
        }));  
    }

    get_shop_numbers(){
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/business/shop_numbers`).pipe(map(user => {
            return user;
        }));  
    }

    update_shop_details(
        id:any,
        business_name:any,
        business_description:any,
        status:any,
        order_recieved_number:any,
        currency:any,
        ){
        const formData: FormData = new FormData();

        formData.append('business_name', business_name);
        formData.append('business_description', business_description);
        formData.append('status', status);
        if(order_recieved_number){
            formData.append('order_recieved_number', order_recieved_number);
        }
        if(currency){
            formData.append('currency', currency.code);
            formData.append('currency_val', currency.currency);
        }
       
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/business/shop_update`, formData).pipe(map(user => {
            return user;
        })); 
    }

    deleteInvoice(id:any ,password:any,status:any )
    {
        var formData={
            "password":password,
            "status":status
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/delete-invoice/`+id, formData).pipe(map(user => {
            return user;
        })); 
    }
}