import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray,FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {FloorService} from '../_services/floor.service';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';


declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var checked:any;

@Component({
  selector: 'app-add-food-master',
  templateUrl: './add-food-master.component.html',
  styleUrls: ['./add-food-master.component.css']
})
export class AddFoodMasterComponent implements OnInit {
  FoodForm: FormGroup;
	loading = false;
  submitted = false;
  radioCheck=null;
  floors$:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  curPage = 1;
  perPage = 10;
  status$ =true;
  cancelButton = false;
  rate$:any;

  perc: boolean;
  taxtype: boolean;
  tax$: any;
  applytax: boolean;
  type$: any;

  constructor(  private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private FloorService:FloorService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
  	this.FoodForm = this.formBuilder.group({
      index: null,
      _id: null,
  		name: ['',[Validators.required,Validators.maxLength(100),Validators.pattern(nonWhitespaceRegExp)]],
      price: ['',[Validators.required,Validators.maxLength(200)]],
      tax:['',[Validators.required]],
      type:[''],
      percentage:['']
    })
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: [3],
        
      }],
      // order: [[ 2, 'asc' ]]
    };
    this.FloorService.getFood().subscribe(data => {
      //console.log(data);
      this.rate$=data.data.Foods;
      this.dtTrigger.next();
     
     
  });
  this.FloorService.getTax().subscribe(data => {
    //console.log(data);
    this.tax$=data.data.Tax;
  
   
  });

  this.FloorService.getTaxType().subscribe(data => {
    //console.log(data);
    this.type$=data.data.taxTypes;
    // this.dtTrigger.next();
   
   
});
    
  }

  get f() { return this.FoodForm.controls; }

  tax(event)
  {

    if(event.target.value==1)
    {
      this.applytax=true;
      this.FoodForm.controls["percentage"].setValidators([Validators.required]);
      this.FoodForm.controls["percentage"].updateValueAndValidity();
    }else{
      this.applytax=false;
      this.FoodForm.controls["percentage"].clearValidators();
      this.FoodForm.controls["percentage"].updateValueAndValidity();
    }
    console.log(this.applytax)
    if(event.target.value==1&&this.perc)
    {
      this.taxtype=true;
      this.FoodForm.controls["type"].setValidators([Validators.required]);
      this.FoodForm.controls["type"].updateValueAndValidity();
    }else{
      this.taxtype=false;
      this.FoodForm.controls["type"].clearValidators();
      this.FoodForm.controls["type"].updateValueAndValidity();
    }
  }

  changetax(event)
  {
    if(event.target.value)
    {
      this.perc=true
      this.taxtype=true;
      this.FoodForm.controls["type"].setValidators([Validators.required]);
      this.FoodForm.controls["type"].updateValueAndValidity();
    }
  }


 

  onSubmit(form, formWrap) {
    
    this.submitted = true

  	let id = form.getRawValue()._id;
    let index = form.getRawValue().index;
    const that = this;
    //console.log(this.FoodForm)
  
    if(this.FoodForm.invalid ){
      this.toastr.error('Please Enter All Fields');
      return false;
    }   

    // if(this.radioCheck == null ){
    //   this.toastr.error('Please Select Status Active/Deactive');
    //   return false;
    // }   

    if (id != null) {
      alertify.confirm('Update Food','Are you sure want to update this record', function () {     
        // document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
        that.loading = true;
    
         that.FloorService.UpdateFood(
             id,
             that.f.name.value,
             that.f.price.value,
             that.f.tax.value,
            that.f.percentage.value,
            that.f.type.value
           
            )
          .subscribe(
            data => {
    
              if (data.status.code == 0) {
              
                that.toastr.success('Food updated successfully');
                window.scroll(0, 0);
                that.submitted = false;
                document.body.classList.remove('jw-modal-open');
          
    
              
                that.loading = false;
                // location.reload();
             
                $('.first').removeClass('is-checked');
                $('.second').removeClass('is-checked');
                $('.Foodyes').removeClass(' is-checked');
                $('.Foodno').removeClass('is-checked');
                $('.mdl-textfield').removeClass('is-focused');
                $('.mdl-textfield').removeClass('is-dirty');
                that.cancelButton = false;
                document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                that.FoodForm.setValue({
                  index: null,
                  _id: null,
                  name:'',
                  price:'',
                  tax:'',
                  percentage:'',
                  type:''
             
                })
              
                // $('[name="status"]').prop("checked",false);
           
                $("#support_table5").dataTable().fnDestroy();
                that.FloorService.getFood().subscribe(data => {
                  //console.log(data);
                  that.rate$=data.data.Foods;
                  that.dtTrigger.next();
                 
                 
              });
               
                formWrap.reset();
           
              } else {
                // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                // document.body.classList.remove('jw-modal-open');
                that.loading = false;
               that.toastr.error(data.status.message)
               
              }
            },
            error => {
              that.toastr.error(error)
              
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
            });
          }, function () { //alertify.error('Delete action cancelled')
          that.toastr.error('Update action cancelled')
        });
      
    } else {
 
      alertify.confirm('Create Food','Are you sure want to create this record', function () {     
        // document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
        that.loading = true;
    
         that.FloorService.saveFood(
            that.f.name.value,
            that.f.price.value,
            that.f.tax.value,
            that.f.percentage.value,
            that.f.type.value
            
           
            )
          .subscribe(
            data => {
    
              if (data.status.code == 0) {
               
                that.toastr.success('Food added successfully');
                window.scroll(0, 0);
                that.submitted = false;
                document.body.classList.remove('jw-modal-open');
          
    
              
                that.loading = false;
                // location.reload();
             
                that.FoodForm.setValue({
                  index: null,
                  _id: null,
                  name:'',
                  price:'',
                  tax:'',
                  percentage:'',
                  type:''
             
                })
               
                $("#support_table5").dataTable().fnDestroy();
                that.FloorService.getFood().subscribe(data => {
                  //console.log(data);
                  that.rate$=data.data.Foods;
                  that.dtTrigger.next();
                 
                 
              });
               
                formWrap.reset();
           
              } else {
                // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                // document.body.classList.remove('jw-modal-open');
                that.loading = false;
               that.toastr.error(data.status.message)
               
              }
            },
            error => {
              that.toastr.error(error)
              
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
            });
          }, function () { //alertify.error('Delete action cancelled')
          that.toastr.error('Create action cancelled')
        });
  }
  }

  cancel(){
    location.href="/";
  }

  userEdit(event, user, i) {
    window.scroll(0, 0);
    
    $('.mdl-textfield').addClass(' is-dirty');
  
    
  
    this.status$= true
    this.cancelButton = true;
    if(user.taxtype)
    {
      this.taxtype=true;
    }else{
      this.taxtype=false;
    }

    if(user.tax=='1')
    {
      this.applytax=true;
    }else{
      this.applytax=false;
    }
    //this.table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
    this.FoodForm.patchValue({
      index: i,
      _id: user._id,
      name:user.name,
      price:user.price,
      percentage:user.percentage,
      tax:user.tax,
      type:user.taxtype
    })
   
   
    // this.imageExist =user.image;
    // this.imageExist1=user.cover;
  }
  
  userDelete(event, user, i) {
    var that = this;
    alertify.confirm('Delete Food','Are you sure want to delete this record', function () {
      that.FloorService.DeleteFood(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success('Deleted Successfully')
              // alertify.success('Deleted Successfully')
              setTimeout(function () {
                $("#support_table5").dataTable().fnDestroy();
                that.FloorService.getFood().subscribe(data => {
                  //console.log(data);
                  that.rate$=data.data.Foods;
                  that.dtTrigger.next();
                 
                 
              });
              }, 1000);
              //that.users$.splice(i, 1);
            } else {
              that.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            that.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
          });
    }
      , function () { //alertify.error('Delete action cancelled')
       // that.toastr.error('Delete action cancelled')
  
      });
  
  }
  
  cancelSubmit(event) {
    $('.mdl-textfield').removeClass('is-focused');
    $('.mdl-textfield').removeClass('is-dirty');
    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.FoodForm.setValue({
      index: null,
      _id: null,
      name:'',
      price:'',
      tax:'',
      percentage:'',
      type:''
    })
  
    this.submitted = false;
  }
  ngAfterViewInit(){
    $(document).on('focus','input,select,textarea',function(e){ 
      var cur = $(this);
      $('input,select,textarea').parent().removeClass('is-focused');
      cur.parent()[0].classList.remove('is-focused');
      
      // cur.parent()[0].removeClass('is-focused')
      
        cur.parent()[0].classList.add('is-focused')
       })
      }

}
