 <site-header></site-header>
  <div class="main_container flt">
    
        <div class="signup_container flt">
            <div class="signup_main flt">
                <div class="login_logo flt">
                    <img src="assets/images/favicon.png" class="img-responsive" alt="image">
                </div>
                <div class="login_text flt">hotel-shivaprasad</div>
                <div class="login_desc flt">Let's create your account</div>
                <div class="login_form flt">
                     <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" >
                    <div class="col-sm-6">
                        <div class="form-group flt">
                            <input type="text" name="name" placeholder="Enter your name" formControlName="name" [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }">
                       
                          <div *ngIf="(f.name.touched || submitted) && f.name.errors" class="invalid-error">
                        <div *ngIf="f.name.errors.required" style="color: #d69d99;" >Name is required</div>
                    </div>
                    <div *ngIf="(f.name.touched || submitted) && f.name.errors?.maxlength" class="invalid-error">
                        <div *ngIf="f.name.errors?.maxlength" style="color: #d69d99">Maximum 50 characters are allowed</div>
                    </div>
                    </div>
                     </div>


                     <div class="col-sm-6">
                         <div class="form-group flt">
                    <input type="text" formControlName="email" placeholder="Your Email" [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors }" > 
                  
                    <div *ngIf="(f.email.touched || submitted) && f.email.errors" class="invalid-error">
                        <div *ngIf="f.email.errors.required" style="color: #d69d99">Email is required</div>
                    </div>
                    <div *ngIf="(f.email.touched || submitted) && f.email.errors" class="invalid-error">
                        <div *ngIf="f.email.errors.email" style="color: #d69d99">Invalid Email</div>
                    </div>
                      </div>
                </div>


                    <div class="col-sm-6">
                        <div class="form-group flt">
                        <input type="password" formControlName="password" placeholder="Password" [ngClass]="{ 'is-invalid': (f.password.touched || submitted) && f.password.errors }">
                        
                 
                    <div *ngIf="(f.password.touched || submitted) && f.password.errors" class="invalid-error">
                        <div *ngIf="f.password.errors.required" style="color: #d69d99">Password is required</div>
                    </div> 
                     <div *ngIf="(f.password.touched || submitted) && f.password.errors?.maxlength" class="invalid-error">
                        <div *ngIf="f.password.errors?.maxlength" style="color: #d69d99">Maximum 8 characters are allowed</div>
                    </div>
                    </div>
                       </div>


                    <div class="col-sm-6">
                        <div class="form-group flt">
                        <input type="password" formControlName="cpassword" placeholder="Confirm Password" [ngClass]="{ 'is-invalid': (f.cpassword.touched || submitted) && f.cpassword.errors }">
                        
                   
                    <div *ngIf="(f.cpassword.touched || submitted) && f.cpassword.errors" class="invalid-error">
                        <div *ngIf="f.cpassword.errors.required" style="color: #d69d99">Confirm Password is required</div>
                    </div> 

                     <div *ngIf="(f.cpassword.touched || submitted) && f.cpassword.errors?.maxlength" class="invalid-error">
                        <div *ngIf="f.cpassword.errors?.maxlength" style="color: #d69d99">Maximum 8 characters are allowed</div>
                    </div>
                    </div>
                     </div>

                    <div class="col-sm-6">
                        <div class="form-group flt">
                            <input type="tel" class="input-text text-field inp" name="phone" id="phone" required="true" placeholder="Enter your phone number" formControlName="phone" [ngClass]="{ 'is-invalid': (f.phone.touched || submitted) && f.phone.errors }">
                            <span class="elem-msg"></span>
                            <input type="hidden" name="phoneNumber" id="phoneNumber" formControlName="phoneNumber">
                      
                         <div *ngIf="(f.phone.touched || submitted) && f.phone.errors" class="invalid-error">
                        <div *ngIf="f.phone.errors.required" style="color: #d69d99">Phone number is required</div>
                    </div>

                  <div *ngIf="(f.phone.touched || submitted) && f.phone.errors?.maxlength" class="invalid-error">
                        <div *ngIf="f.phone.errors?.maxlength" style="color: #d69d99">Maximum 15 characters are allowed</div>
                    </div> 

                     <div *ngIf="(f.phone.touched || submitted) && f.phone.errors?.pattern" class="invalid-error">
                        <div *ngIf="f.phone.errors?.pattern" style="color: #d69d99">Enter only digits</div>
                    </div> 

                      </div>
                    </div>


                    <div class="col-sm-6">
                        <div class="form-group flt">
                            <input type="text" name="company" placeholder="Company" formControlName="company" [ngClass]="{ 'is-invalid': (f.company.touched || submitted) && f.company.errors }">
                     
                             <div *ngIf="(f.company.touched || submitted) && f.company.errors" class="invalid-error">
                        <div *ngIf="f.company.errors.required" style="color: #d69d99">Company is required</div>
                    </div>

                  <div *ngIf="(f.company.touched || submitted) && f.company.errors?.maxlength" class="invalid-error">
                        <div *ngIf="f.company.errors?.maxlength" style="color: #d69d99">Maximum 200 characters are allowed</div>
                    </div> 
                    </div>
                       </div>


                    <div class="col-sm-6">
                        <div class="form-group flt">
                             <input type="text" name="address1" placeholder="Your home/office address1" formControlName="address1" [ngClass]="{ 'is-invalid': (f.address1.touched || submitted) && f.address1.errors }">
                      
                         <div *ngIf="(f.address1.touched || submitted) && f.address1.errors" class="invalid-error">
                        <div *ngIf="f.address1.errors.required" style="color: #d69d99">Address is required</div>
                    </div>

                  <div *ngIf="(f.address1.touched || submitted) && f.address1.errors?.maxlength" class="invalid-error">
                        <div *ngIf="f.address1.errors?.maxlength" style="color: #d69d99">Maximum 500 characters are allowed</div>
                    </div> 
                    </div>
                      </div>

                    <div class="col-sm-6">
                        <div class="form-group flt">
                             <input type="text" name="address2" placeholder="Your home/office address2" formControlName="address2" [ngClass]="{ 'is-invalid': (f.address2.touched || submitted) && f.address2.errors }">
                   
                         <div *ngIf="(f.address2.touched || submitted) && f.address2.errors" class="invalid-error">
                        <div *ngIf="f.address2.errors.required" style="color: #d69d99">Address is required</div>
                    </div>

                  <div *ngIf="(f.address2.touched || submitted) && f.address2.errors?.maxlength" class="invalid-error">
                        <div *ngIf="f.address2.errors?.maxlength" style="color: #d69d99">Maximum 500 characters are allowed</div>
                    </div> 
                    </div>
                        </div>

                     <div class="col-sm-4">
                        <div class="form-group flt">
                            <select name="country" formControlName="country" [ngClass]="{ 'is-invalid': (f.country.touched || submitted) && f.country.errors }" placeholder="Country"  (change)="handleCountry($event.target.value)">
                                <option value="">Country</option>
                                <option *ngFor="let val of $country" value="{{val._id}}">{{val.country}}</option>
                            </select>

                               <!--   <select formControlName="state" (change)="handleState($event.target.value)">
                                            <option [selected] value="" disabled> Select</option>
                                            <option *ngFor="let val of states" value="{{val._id}}">{{val.state}}
                                            </option>
                                        </select> -->
                            
                       
                         <div *ngIf="(f.country.touched || submitted) && f.country.errors" class="invalid-error">
                        <div *ngIf="f.country.errors.required" style="color: #d69d99">Country is required</div>
                    </div>
                     </div>
                    </div>

                        <div class="col-sm-4">
                        <div class="form-group flt">
                            <select name="state" formControlName="state" [ngClass]="{ 'is-invalid': (f.state.touched || submitted) && f.state.errors }" placeholder="State">
                                 <option [selected] value="" disabled> State</option>
                                <option *ngFor="let state of $states" value="{{state}}">
                                  {{state}} 
                            </option>
                            </select>
                          <!--   <option [selected] value="" disabled> State</option>
                                            <option *ngFor="let val of $state" selected="{{val==selected_city}}" value="{{val}}">{{val}}</option>
                            </select> -->
                       
                         <div *ngIf="(f.state.touched || submitted) && f.state.errors" class="invalid-error">
                        <div *ngIf="f.state.errors.required" style="color: #d69d99">State is required</div>
                    </div>
                    </div>
                     </div>

                     <div class="col-sm-4">
                        <div class="form-group flt">
                            <input type="text" name="city" formControlName="city" [ngClass]="{ 'is-invalid': (f.city.touched || submitted) && f.city.errors }" placeholder="City">
                               
                            
                       
                         <div *ngIf="(f.city.touched || submitted) && f.city.errors" class="invalid-error">
                        <div *ngIf="f.city.errors.required" style="color: #d69d99">City is required</div>
                    </div>
                    </div>
                     </div>

                    <div class="col-sm-12">
                        <div class="form-group flt">
                               <input type="text" name="refrral" placeholder="Referral Code (optional)" formControlName="refrral" [ngClass]="{ 'is-invalid': (f.refrral.touched || submitted) && f.refrral.errors }">
                            
                        </div>

                           <!--  <div *ngIf="(f.refrral.touched || submitted) && f.refrral.errors.maxlength" class="invalid-error">
                        <div *ngIf="f.refrral.errors.maxlength" style="color: #d69d99">Maximum 100 characters are allowed</div>
                    </div>  -->
                    </div>
                       <div class="login_btn flt">
                    <button type="submit" data-dismiss="modal">SUBMIT</button>
                </div>
                </form>
                </div>
             
                <div class="login_forgot flt">
                    I have an account? <a href="login">Log In</a>
                </div>
            </div>
        </div>
    </div>

    <style>
    .is-invalid{
    border: 1px solid red;
}
.invalid-error {
    /* display: none; */
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: red !important;
}

.invalid-error div {
    float: left;
    margin-bottom: 1%;
}

</style>