import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray,FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {FloorService} from '../_services/floor.service';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var checked:any;
@Component({
  selector: 'app-add-item-master',
  templateUrl: './add-item-master.component.html',
  styleUrls: ['./add-item-master.component.css']
})
export class AddItemMasterComponent implements OnInit {
  ItemForm: FormGroup;
	loading = false;
  submitted = false;
  radioCheck=null;
  floors$:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  curPage = 1;
  perPage = 10;
  status$ =true;
  cancelButton = false;
  rate$:any;
  constructor(    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private FloorService:FloorService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
  	this.ItemForm = this.formBuilder.group({
      index: null,
      _id: null,
  		name: ['',[Validators.required,Validators.maxLength(20),Validators.pattern(nonWhitespaceRegExp)]],
      item_type:['',[Validators.required]],
     price: ['',[Validators.required,Validators.maxLength(20),Validators.pattern(nonWhitespaceRegExp)]],
    })
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: [3],
        
      }],
      // order: [[ 2, 'asc' ]]
    };
    this.FloorService.getItem().subscribe(data => {
      //console.log(data);
      this.rate$=data.data.Items;
      this.dtTrigger.next();
     
     
  });
    
  }

  get f() { return this.ItemForm.controls; }
 


  onSubmit(form, formWrap) {
    
    this.submitted = true

  	let id = form.getRawValue()._id;
    let index = form.getRawValue().index;
    const that = this;
    //console.log(this.ItemForm)
  
    if(this.ItemForm.invalid ){
      this.toastr.error('Please Enter All Fields');
      return false;
    }    

    // if(this.radioCheck == null ){
    //   this.toastr.error('Please Select Status Active/Deactive');
    //   return false;
    // }   
    if (id != null) {
      alertify.confirm('Update Item','Are you sure want to update this record', function () {     
        // document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
        that.loading = true;
    
         that.FloorService.updateItem(
             id,
             that.f.name.value,
            that.f.item_type.value,
            that.f.price.value
            
           
            )
          .subscribe(
            data => {
    
              if (data.status.code == 0) {
                window.scroll(0, 0);
                that.toastr.success('Item updated successfully');
                that.cancelButton = false;
                that.submitted = false;
                document.body.classList.remove('jw-modal-open');
                document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable')
                $('.mdl-textfield').removeClass('is-focused');
                $('.mdl-textfield').removeClass('is-dirty');
              
                that.loading = false;
                that.ItemForm.setValue({
                  index: null,
                  _id: null,
                  name:'',
                  item_type:'',
                  price:''
                })
               
                formWrap.reset();
                setTimeout(function () {
                  $("#support_table5").dataTable().fnDestroy();
                  that.FloorService.getItem().subscribe(data => {
                    //console.log(data);
                    that.rate$=data.data.Items;
                    that.dtTrigger.next();
                   
                   
                });
                }, 1000);
           
              } else {
                // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                // document.body.classList.remove('jw-modal-open');
                that.loading = false;
               that.toastr.error(data.status.message)
               
              }
            },
            error => {
              that.toastr.error(error)
              
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
            });
          }, function () { //alertify.error('Delete action cancelled')
          
        });
      
    } else {
 
      alertify.confirm('Create Item','Are you sure want to create this record', function () {     
        // document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
        that.loading = true;
    
         that.FloorService.saveItem(
            that.f.name.value,
            that.f.item_type.value,
            that.f.price.value,
            
           
            )
          .subscribe(
            data => {
    
              if (data.status.code == 0) {
                window.scroll(0, 0);
                that.toastr.success('Item added successfully');
              
                that.submitted = false;
                document.body.classList.remove('jw-modal-open');
          
    
              
                that.loading = false;
                // location.reload();
             
                that.ItemForm.setValue({
                  index: null,
                  _id: null,
                  name:'',
                  item_type:'',
                  price:''
                })
               
                formWrap.reset();
                setTimeout(function () {
                  $("#support_table5").dataTable().fnDestroy();
                  that.FloorService.getItem().subscribe(data => {
                    //console.log(data);
                    that.rate$=data.data.Items;
                    that.dtTrigger.next();
                   
                   
                });
                }, 1000);
           
              } else {
                // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                // document.body.classList.remove('jw-modal-open');
                that.loading = false;
               that.toastr.error(data.status.message)
               
              }
            },
            error => {
              that.toastr.error(error)
              
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
            });
          }, function () { //alertify.error('Delete action cancelled')
          that.toastr.error('Create action cancelled')
        });
  }
  
  }
  cancel(){
    this.router.navigateByUrl("/");
  }
  userEdit(event, user, i) {
    window.scroll(0, 0);
  

    $('.mdl-textfield').addClass(' is-dirty');
  
    
  
    this.status$= true
    this.cancelButton = true;
    //this.table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
    this.ItemForm.patchValue({
      index: i,
      _id: user._id,
      name:user.name,
      item_type:user.item_type,
      price:user.price
    })
   
   
    // this.imageExist =user.image;
    // this.imageExist1=user.cover;
  }
  // public loadScript() {
  //   var isFound = false;
  //   var scripts = document.getElementsByTagName("script")
  //   for (var i = 0; i < scripts.length; ++i) {
  //     if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
  //       isFound = true;
  //     }
  //   }

  //   if (!isFound) {
  
  //     var dynamicScripts = [
     
    
       


     
  //      'assets/plugins/popper/popper.min.js',
  //      'assets/plugins/jquery-blockui/jquery.blockui.min.js',
  //      'assets/plugins/jquery-slimscroll/jquery.slimscroll.min.js',
    
  //      'assets/plugins/bootstrap/js/bootstrap.min.js',
  //      'assets/plugins/sparkline/jquery.sparkline.min.js',
  //      'assets/js/pages/sparkline/sparkline-data.js',
  
  //      'assets/js/app.js',
  //      'assets/js/layout.js',
  //      'assets/js/theme-color.js',
      
  //     // 'assets/plugins/material/material.min.js', 
      
  //      'assets/js/pages/ui/animations.js',
  //      'assets/js/pages/material_select/getmdl-select.js',
  //      'assets/plugins/flatpicker/flatpickr.min.js',
  //      'assets/js/pages/datetime/datetime-data.js',
       
  //      'assets/plugins/morris/morris.min.js',
  //      'assets/plugins/morris/raphael-min.js',
  //      'assets/js/pages/chart/morris/morris_home_data.js',
      
  //      'assets/plugins/material/material.min.js',
  //      'assets/js/pages/extra_pages/login.js',




  //     ];

  //     for (var i = 0; i < dynamicScripts.length; i++) {
  //       let node = document.createElement('script');
  //       node.src = dynamicScripts[i];
  //       node.type = 'text/javascript';
  //       node.async = false;
  //       node.charset = 'utf-8';
  //       document.getElementsByTagName('body')[0].appendChild(node);
  //     }

  //   }


  // }
  userDelete(event, user, i) {
    var that = this;
    alertify.confirm('Delete Item','Are you sure want to delete this record', function () {
      that.FloorService.DeleteItem(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success('Deleted Successfully')
              // alertify.success('Deleted Successfully')
              setTimeout(function () {
                $("#support_table5").dataTable().fnDestroy();
                that.FloorService.getItem().subscribe(data => {
                  //console.log(data);
                  that.rate$=data.data.Items;
                  that.dtTrigger.next();
                 
                 
              });
              }, 1000);
              //that.users$.splice(i, 1);
            } else {
              that.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            that.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
          });
    }
      , function () { //alertify.error('Delete action cancelled')
       // that.toastr.error('Delete action cancelled')
  
      });
  
  }
  
  cancelSubmit(event) {
    $('.mdl-textfield').removeClass('is-focused');
    $('.mdl-textfield').removeClass('is-dirty');
    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.ItemForm.setValue({
      index: null,
      _id: null,
      name:'',
      item_type:'',
      price:''
    })
  
    this.submitted = false;
  }
  ngAfterViewInit(){
    $(document).on('focus','input,select,textarea',function(e){ 
      var cur = $(this);
      $('input,select,textarea').parent().removeClass('is-focused');
      cur.parent()[0].classList.remove('is-focused');
      
      // cur.parent()[0].removeClass('is-focused')
      
        cur.parent()[0].classList.add('is-focused')
       })
      }
}
