<site-header></site-header>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate>
</ng-template>

<div class="page-container">

	<div class="page-content-wrapper">
		<div class="page-content">
			<div class="page-bar">
				<div class="page-title-breadcrumb">
					<div class=" pull-left">
						<div class="page-title">Add Floor Details</div>
					</div>
					<ol class="breadcrumb page-breadcrumb pull-right">
						<li><i class="fa fa-home"></i>&nbsp;<a class="parent-item"
								(click)="home()">Home</a>&nbsp;<i class="fa fa-angle-right"></i>
						</li>
						<li>&nbsp;<a class="parent-item"
						>Settings</a>&nbsp;<i class="fa fa-angle-right"></i>
						</li>
						<li class="active">Add Floor Details</li>
					</ol>
				</div>
			</div>
		
			<div class="row">
				<div class="col-md-12 col-sm-12">
					<div class="card  card-box">
						<div class="card-head">
							<header>Add Floor Details</header>
							<div class="tools">
								<!-- <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
								<a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
								<a class="t-close btn-color fa fa-times" href="javascript:;"></a> -->
							</div>
						</div>
						<form class="flt " role="form" [formGroup]="FloorForm" #myForm="ngForm"
						(ngSubmit)="onSubmit(FloorForm, myForm)">
						<div class="card-body row">
								<div class="col-lg-6 p-t-20">
									<div
										class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width is-dirty ">
										<input class="mdl-textfield__input" type="text" id="txtFloorName" autocomplete="off" formControlName="name">
										<label class="mdl-textfield__label">Floor Name<span style="color:red">*</span></label>
									</div>
									<div class="invalid">
										<div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
										class="invalid-feedback">
										<div *ngIf="f.name.errors?.required">Floor Name is required</div>
									</div>
									<div *ngIf="(f.name.touched || submitted) && f.name.errors?.maxlength"
										class="invalid-feedback">
										<div *ngIf="f.name.errors?.maxlength">Maximum 50 characters are allowed
										</div>
									</div>
									<div *ngIf="(f.name.touched || submitted) && f.name.errors?.pattern"
										class="invalid-feedback">
										<div *ngIf="f.name.errors?.pattern">Empty space is not allowed
										</div>
									</div>
									</div>
								</div>
							</div>	
						
								<div class="card-body ">
							
									<div>
										<span class="status_label">Status<span style="color:red">*</span></span>
									</div>
									
									<!-- <div class="radio_button">
											<label  for="option1">
												<input type="radio" id="option1"  formControlName="status" value="1" name="status"
													class="mdl-radio__button"   >
												<span class="mdl-radio__label ">Active</span>
											</label>
									
											<label 
												for="option2">
												<input type="radio" id="option2" name="status" value="0" formControlName="status"
													class="mdl-radio__button" >
												<span class="mdl-radio__label" >Deactive</span>
											</label>
										</div> -->
										<div class="radio_button">
											<!-- <label  class="first mdl-radio mdl-js-radio" for="option1"> -->
												<input type="radio" id="option1" name="status"
													class="mdl-radio__button" value="1"   formControlName="status">
												<span class="mdl-radio__label active_cls">Active</span>
											<!-- </label> -->
<!-- 									
											<label class="second mdl-radio mdl-js-radio mdl-js-ripple-effect"
												for="option2"> -->
												<input type="radio" id="option2" value="0"  name="status" formControlName="status"
													class="mdl-radio__button">
												<span class="mdl-radio__label">Inactive</span>
											<!-- </label> -->
									
							</div>
							<div>
								<div *ngIf="(f.status.touched || submitted) && f.status.errors?.required"
								class="invalid-feedback">
								<div *ngIf="f.status.errors?.required">Status is required</div>
							</div>
							</div>
										<!-- <label class="mdl-radio mdl-js-radio mdl-js-ripple-effect" for="heating-1" >
											<input formControlName="heating" id="heating-1" type="radio" name="heating" (change)="onFilterCheckChange($event,'heating')" class="mdl-radio__button"  [value]=1 checked>
											<span class="mdl-radio__label">Si</span>
										  </label>
										  <label class="mdl-radio mdl-js-radio mdl-js-ripple-effect" for="heating-2" >
											<input formControlName="heating" id="heating-2"  type="radio" name="heating" (change)="onFilterCheckChange($event,'heating')" class="mdl-radio__button"  [value]=0 >
											<span class="mdl-radio__label">No</span>
										  </label> -->
									
									</div>	
							<div class="col-lg-12 p-t-20 text-center submit_cls">
									<button type="submit" 
										class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-info">
										<div *ngIf="cancelButton;then update else add">here is ignored</div>
										<ng-template #add>Submit</ng-template>
										<ng-template #update>Update</ng-template>
									</button>

										
									<button type="button" *ngIf="cancelButton" (click)="cancelSubmit($event)"
										class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">Cancel</button>
								</div>
								</form>
					</div>
				</div>
			</div>


			<div class="row">
				<div class="col-md-12 col-sm-12">
					<div class="card  card-box">
						<div class="card-head">
							<header>All Floor Type</header>
							<div class="tools">
								<a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
								<a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
								<!-- <a class="t-close btn-color fa fa-times" href="javascript:;"></a> -->
							</div>
						</div>
						<div class="card-body tb-wrapper ">
							<div class="table-wrap">
								<div class="table-responsive tblHeightSet ">
									<table class="table display product-overview mb-30" id="support_table5" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
										<thead>
											<tr>
												<th>Sl No</th>
												<th>Floor Type</th>
												<th>Status</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let user of floors$; let i = index">
												<td>{{ perPage * (curPage - 1) + i + 1 }}</td>
												<td *ngIf="user.name;else  name" > {{ user.name}}</td>
												<ng-template #name>
													<td>-</td>
												</ng-template>
												<td *ngIf="user.status;else  status">
												 <span *ngIf="user.status == 1"> Active</span> 
												 <span *ngIf="user.status == 0"> Inactive</span> 
												</td>
												<ng-template #status>
													<td>-</td>
												</ng-template>
												<td>
													<a (click)="userEdit($event, user, i)" href="javascript:void(0);" class="btn btn-tbl-edit btn-xs">
														<i class="fa fa-pencil"></i>
													</a>
													<button class="btn btn-tbl-delete btn-xs" (click)="userDelete($event, user, i)" >

														<i class="fa fa-trash-o "></i>
													</button>
												</td>
											</tr>

											

										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>

</div>






<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>
