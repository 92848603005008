<site-header></site-header>
<div class="page-container">
 
<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class=" pull-left">
                    <div class="page-title">Add Room Type Details</div>
                </div>
                <ol class="breadcrumb page-breadcrumb pull-right">
                    <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item"
                            href="/">Home</a>&nbsp;<i class="fa fa-angle-right"></i>
                    </li>
                    <li>&nbsp;<a class="parent-item"
                        >Settings</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                   
                    <li class="active">Add Room Type Details</li>
                </ol>
            </div>
        </div>
    
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="card  card-box">
                    <div class="card-head">
                        <header>Add Room Type Details</header>
                        <div class="tools">
                            <!-- <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                            <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                            <a class="t-close btn-color fa fa-times" href="javascript:;"></a> -->
                        </div>
                    </div>
                    <form class="flt " role="form" [formGroup]="RoomForm" #myForm="ngForm"
                    (ngSubmit)="onSubmit(RoomForm, myForm)">
                    <div class="card-body row">
                            <div class="col-lg-6 p-t-20">
                                <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input" type="text" id="txtRoomType" autocomplete="off" formControlName="room_type">
                                    <label class="mdl-textfield__label">Room Type<span style="color:red">*</span></label>
                                </div>
                                <div class="invalid">
                                    <div *ngIf="(f.room_type.touched || submitted) && f.room_type.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f.room_type.errors?.required">Room type is required</div>
                                </div>
                                <div *ngIf="(f.room_type.touched || submitted) && f.room_type.errors?.maxlength"
                                class="invalid-feedback">
                                <div *ngIf="f.room_type.errors?.maxlength">Maximum 50 characters are allowed
                                </div>
                            </div>
                            <div *ngIf="(f.room_type.touched || submitted) && f.room_type.errors?.pattern"
                                class="invalid-feedback">
                                <div *ngIf="f.room_type.errors?.pattern">Empty space is not allowed
                                </div>
                            </div>
                                </div>
                            </div>
                            <div class="col-lg-6 p-t-20">
                                <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                    <input class="mdl-textfield__input"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" id="txtPrice" autocomplete="off" formControlName="price">
                                    <label class="mdl-textfield__label">Price<span style="color:red">*</span></label>
                                </div>
                                <div class="invalid">
                                    <div *ngIf="(f.price.touched || submitted) && f.price.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f.price.errors?.required">Price is required</div>
                                </div>
                                <div *ngIf="(f.price.touched || submitted) && f.price.errors?.maxlength"
                                class="invalid-feedback">
                                <div *ngIf="f.price.errors?.maxlength">Maximum 30 characters are allowed
                                </div>
                            </div>
                            <div *ngIf="(f.price.touched || submitted) && f.price.errors?.pattern"
                                class="invalid-feedback">
                                <div *ngIf="f.price.errors?.pattern">Empty space is not allowed
                                </div>
                            </div>
                                </div>
                            </div>
                            
                                <div class="col-lg-6 p-t-20">
                                <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                    <!-- <input class="mdl-textfield__input" type="text" id="sample2" 
                                        readonly  formControlName="rate_type"  >
                                        <input value="" type="hidden" name="sample2" value=""  />
                                    <label for="sample2" class="pull-right margin-0">
                                        <i class="mdl-icon-toggle__label material-icons">keyboard_arrow_down</i>
                                    </label>
                                    <label for="sample2" class="mdl-textfield__label">Rate Type</label>
                                    <ul data-mdl-for="sample2" class="mdl-menu mdl-menu--bottom-left mdl-js-menu" >
                                        <li class="mdl-menu__item " *ngFor="let user of rate$; let i = index"  data-val="{{user.name}}">{{user.name}}</li>
                                        
                                    </ul> -->
                                    <!-- <select name="cars"   id="cars" formControlName="rate_type">
                                        <option selected value="" disabled>Rate type</option>
                                        <option value="{{user.name}}" *ngFor="let user of rate$; let i = index">{{user.name}}</option>
                                       
                                      </select> -->
                                      <label class="mdl-textfield__label" for="thing1">Rate type<span style="color:red">*</span></label>
                                      <select class="mdl-textfield__input" id="thing1" name="thing1" formControlName="rate_type" >
                                         
                                        <option *ngFor="let user of rate$; let i = index" value="{{user.name}}">{{user.name}}</option>
                                       
                                      </select>
                                      
                                </div>
                                <div class="invalid">
                                    <div *ngIf="(f.rate_type.touched || submitted) && f.rate_type.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f.rate_type.errors?.required">Rate type is required</div>
                                </div>
                                </div>
                                <!-- <ngx-select-dropdown [config]="stateConfig" class="mdl-textfield mdl-js-textfiel"
                                [options]="rate$" [multiple]="false" formControlName="rate_type">
                            </ngx-select-dropdown> -->
                          
                            </div>
                           
                        </div>	
                        <div class="col-lg-12 p-t-20 text-center submit_cls">
                            <button type="submit" 
                                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-info">
                                <div *ngIf="cancelButton;then update else add">here is ignored</div>
                                <ng-template #add>Submit</ng-template>
                                <ng-template #update>Update</ng-template>
                            </button>

                                
                            <button type="button" *ngIf="cancelButton" (click)="cancelSubmit($event)"
                                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">Cancel</button>
                        </div>
                            </form>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="card  card-box">
                    <div class="card-head">
                        <header>All Room Type</header>
                        <div class="tools">
                            <!-- <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a> -->
                            <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                            <!-- <a class="t-close btn-color fa fa-times" href="javascript:;"></a> -->
                        </div>
                    </div>
                    <div class="card-body tb-wrapper ">
                        <div class="table-wrap">
                            <div class="table-responsive tblHeightSet ">
                                <table class="table display product-overview mb-30" id="support_table5" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Room Type</th>
                                            <th>Rate Type</th>
                                            <th>Price</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let user of RoomType$; let i = index">
                                            <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                            <td *ngIf="user.room_type;else  name" > {{ user.room_type}}</td>
                                            <ng-template #name>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user.rate_type;else  rate">
                                                {{user.rate_type}}
                                              </td>
                                              <ng-template #rate>
                                                  <td>-</td>
                                              </ng-template>
                                            <td *ngIf="user.price;else  price">
                                              {{user.price}}
                                            </td>
                                            <ng-template #price>
                                                <td>-</td>
                                            </ng-template>
                                            <td>
                                                <a (click)="userEdit($event, user, i)" href="javascript:void(0);" class="btn btn-tbl-edit btn-xs">
                                                    <i class="fa fa-pencil"></i>
                                                </a>
                                                <button class="btn btn-tbl-delete btn-xs" (click)="userDelete($event, user, i)" >

                                                    <i class="fa fa-trash-o "></i>
                                                </button>
                                            </td>
                                        </tr>

                                        

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>

</div>

<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>